import {createContext} from 'react';

import {TFilesAndFoldersState} from '@/hooks/useFilesAndFoldersState';
import {TUsePermissionsState} from '@/hooks/usePermissionsState';
import {UserAuth} from '@/types/UserAuth';

export type TUserContextProvider = {
    children: React.ReactNode
}

export type TUserContext = {
    userAuth: UserAuth
    setUserAuth: React.Dispatch<React.SetStateAction<UserAuth>>
    filesAndFoldersState: TFilesAndFoldersState
    permissionsState: TUsePermissionsState
}

export const UserContext = createContext<TUserContext | null>(null);
