import {Button, Dropdown, Label, Modal, TDropdownValue, TModalProps, useModalRef} from '@aktek/f4kit';
import cn from 'classnames';
import {useEffect, useState} from 'react';

import {strings} from '@/localization/i18n';

export type TTagConditionModalProps = {
    isCondition?: boolean;
    onConfirm?: (condition, extra?)=>void;
    options: Record<string, string>;
    groupOptions?: Record<string, string>;
    selectedOptions?: TDropdownValue[];
    selectedGroupOptions?: TDropdownValue[];
    modalProps?: Partial<TModalProps>;
    className?: string;
    title: string;
    openOnMount?: boolean;
}

export default function TagConditionModal(props: TTagConditionModalProps) {
    const {className, isCondition, options, selectedOptions, groupOptions, selectedGroupOptions, openOnMount, onConfirm, title, modalProps={size: 'xs'}} = props;
    const [value, setValue] = useState<TDropdownValue | TDropdownValue[]>(selectedOptions);
    const [groups, setGroups] = useState<TDropdownValue | TDropdownValue[]>(selectedGroupOptions);

    useEffect(() => {
        // Filter selectedOptions to only include those that exist in options
        const validSelectedOptions = selectedOptions?.filter((option) =>
            Object.keys(options).includes(option as string),
        ) || [];
        setValue(validSelectedOptions);

        if (openOnMount) {
            modalRef.current.open();
        }
    }, [selectedOptions, options, openOnMount]);

    const modalRef = useModalRef();

    useEffect(()=> {
        if (openOnMount) {
            modalRef.current.open();
        }
    }, []);

    const onConfirmCallback = () => {
        // Combine current value with the selectedOptions to pass to onConfirm
        const combinedValue = [
            ...new Set([...value, ...(selectedOptions || [])]),
        ];

        onConfirm?.(combinedValue, groups);
        modalRef.current.close();
    };

    return <Modal
        ref={modalRef}
        hasXIcon={false}
        headerClassName="p-0"
        className={cn('overflow-hidden !rounded-3xl max-w-[31.25rem] p-4 ', className)}
        title={null}
        size={modalProps.size}
        onChange={modalProps.onChange}
    >

        <div className="w-full flex flex-col  min-h-36 gap-5">

            <Label
                size="xl"
                text={title}
                className="mb-2 capitalize"
                fontWeight="semibold"
            />
            <Dropdown
                value={value}
                label={isCondition ? strings('tags') : strings('users')}
                onChange={(value) => setValue(value)}
                isMulti
                options={options}
            />
            {groupOptions
            && <Dropdown
                value={groups}
                label={strings('groups')}
                onChange={(value) => setGroups(value)}
                isMulti
                options={groupOptions}
            />}

            <div className="flex gap-4 w-full">
                <Button
                    label={strings('cancel')}
                    variant="white"
                    className="w-1/2"
                    onClick={()=>{
                        modalRef.current?.close();
                    }}
                />
                <Button
                    label={strings('confirm')}
                    className="w-1/2"
                    onClick={onConfirmCallback}
                />
            </div>

        </div>

    </Modal>;
}
