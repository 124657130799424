
export const CloudFrontKeys = {
    CloudFrontPolicyKey: 'CloudFront-Key-Pair-Id',
    CloudFrontKeyPairIdKey: 'CloudFront-Policy',
    CloudFrontSignatureKey: 'CloudFront-Signature',
};

export enum LocalStorageKeys {
    THEME = 'Theme',
    FONT = 'Font',
    TOKEN = 'Token',
    TAB_SCHEMA = 'tabsSchema',
    TABS_PROPS = 'tabsProps',
    COMPANY_LOGO = 'companyLogo',
    GROUP_BACKGROUND = 'groupBackground',
    LANGUAGE = 'language',
    CURRENT_USER = 'currentUser',
    PRIMARY_COMPANY = 'primaryCompany',
    CURRENT_COMPANY = 'currentCompany',
    COMPANY_SETTINGS = 'companySettings',
    USER_COMPANIES = 'userCompanies',
    USER_PERMISSION = 'userPermission',
    PERMISSION_SECTION = 'permissionSection',
    FOLDERS_DATA = 'folders_data',
    SHORTCUTS = 'shortcuts',
    REPOSITORIES = 'repositories',
    DRAWER = 'drawer',
}
