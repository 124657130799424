const container = 'flex pb-6 flex-col px-4 gap-6 bg-neutral-50 w-full h-full overflow-y-auto overflow-x-hidden';

const header = 'flex flex-row py-5 bg-neutral-50 justify-between sticky elevation-2 !shadow-none top-0 w-full';
const mapWrapper = 'flex flex-wrap gap-6 ml-0.5 w-full items-start';
const card = 'flex items-center p-4 w-[334px] cursor-pointer';
const label = 'cursor-pointer truncate !text-neutral-900';
const loader = 'absolute inset-0 bg-neutral-200 !shadow-none opacity-50 elevation-5 flex items-center justify-center';

export const getRecentlyClasses = () => ({
    container,
    header,
    mapWrapper,
    card,
    label,
    loader,
});
