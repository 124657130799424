
import {Tag} from '@aktek/f4kit';

const ReferenceButton = ({value}) => {
    if (!value) return null;

    return <Tag label={value || ''}
        size="sm"
        // backgroundColor={'green'}
        variant="primary"
        labelProps={{text: value, color: 'white'}}
    />;
};

export default ReferenceButton;
