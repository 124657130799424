import {useEffect, useState} from 'react';

export default function DevBox() {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle function for toggling `isOpen`
    function toggle() {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    }

    useEffect(() => {
        // Event listener for keyboard shortcuts
        function handleKeyDown(event) {
            if (event.ctrlKey && event.key === 'Enter') {
                toggle();
            }
        }

        // Add event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="App">
            {/* Conditional rendering based on isOpen */}
            {isOpen && (
                <div className="fixed top-0 w-full bg-error-100 p-2 flex gap-4 z-50">

                    {localStorage.devMode !== 'true' && <div className="bg-warning-300 rounded-sm p-2 cursor-pointer"
                        onClick={() => {
                            localStorage.clear();
                            localStorage.setItem('devMode', true);
                            window.location.reload();
                        }}>
                        Click to switch to Dev Mode
                    </div>}

                    {localStorage.devMode === 'true' && <div className="bg-success-500 rounded-small p-2 cursor-pointer"
                        onClick={() => {
                            localStorage.clear();
                            localStorage.setItem('devMode', false);
                            window.location.reload();
                        }}>
                        Click to leave Dev Mode </div>}
                </div>
            )}
        </div>
    );
}
