
export function SearchFunction(array, searchText) {
    function searchRecursive(items) {
        return items.reduce((acc, item) => {
            const isMatch = item.label.toLowerCase().includes(searchText.toLowerCase());
            let newItem = null;

            if (isMatch) {
                newItem = {...item};
            }

            if (item.children) {
                const matchedChildren = searchRecursive(item.children);

                if (isMatch) {
                    newItem.children = item.children;
                } else if (matchedChildren.length > 0) {
                    newItem = {...item, children: matchedChildren};
                }
            }

            if (newItem) {
                acc.push(newItem);
            }

            return acc;
        }, []);
    }

    return searchRecursive(array);
}
