import {F4FormDiv, F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import {CanEditSettings} from '@/constants/Permissions.c';
import {strings} from '@/localization/i18n';

import {PASSWORD_STRENGTH} from '../constants/Settings.c';

export default function LoginSettings() {
    return (
        <F4Nestor name="login">
            <F4FormDiv colSpan={12} className="mt-8">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('login')}
                            className="text-neutral-600 font-medium"
                        />}
                />
            </F4FormDiv>
            <F4Dropdown
                name="settings_password_strength"
                colSpan={3}
                required
                isDisabled={!CanEditSettings()}
                label={strings('required_password_strength')}
                options={PASSWORD_STRENGTH}
                className="flex justify-start items-start flex-col"
            />
            <F4Textbox
                name="settings_login_token_validity"
                colSpan={3}
                allowDecimal={false}
                isDisabled={!CanEditSettings()}
                required
                type="number"
                min={1}
                placeholder="30"
                label = {strings('login_session_period')}
                rightPlaceholder={strings('minutes')}
            />
            <F4FormDiv colSpan={3}/>
        </F4Nestor>
    );
}
