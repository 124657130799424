import React, {ReactNode} from 'react';

interface F4SidebarProps {
    children: ReactNode;
    width?: string;
}

const F4Sidebar: React.FC<F4SidebarProps> = ({children}) => {
    return (
        <div className="border-r h-full border-neutral-200 bg-white py-4 px-4 ">
            <div>
                {children}
            </div>
        </div>
    );
};

export default F4Sidebar;
