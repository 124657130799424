import {Button, Label} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const LicenseIssued = ({
    validity,
    issueDate,
    expiryDate,
    isActive,
    onRevoke,
    onReissue,
    mode,
}:{
    validity: number;
    issueDate: string;
    expiryDate: string;
    isActive: boolean;
    onRevoke: () => void;
    onReissue: () => void;
    mode?: 'edit' | 'view'| 'create'
  }) => {
    return (
        <div className="bg-white rounded-lg shadow-md p-4 w-11/12 ">
            <div className="flex justify-start items-center mb-4 gap-3">
                <Label text={strings('license_activation')} size="lg" fontWeight="semibold" color="neutral-700" />
                <Label text={isActive ? strings('active') : strings('revoked')} size="xs"
                    fontWeight="medium" color={isActive ? 'success-700': 'error-700'}
                    className={`bg-${isActive ? 'success-50' : 'error-50'} px-2.5 py-0.5 rounded-full`} />

            </div>
            <div className="mb-4">
                <div className="flex justify-between py-2">
                    <Label text={strings('validity')} color="neutral-400" />
                    <Label text={validity + ' days'} color="neutral-900" />
                </div>
                <div className="flex justify-between py-2">
                    <Label text={strings('activated_date')} color="neutral-400" />
                    <Label text={issueDate} color="neutral-900" />
                </div>
                <div className="flex justify-between py-2">
                    <Label text={strings('expiry_ate')} color="neutral-400" />
                    <Label text={expiryDate} color="neutral-900" />
                </div>
            </div>
            {mode != 'view' && <div className="flex justify-end items-center gap-2">
                <Button label={strings('revoke_license')} onClick={onRevoke} variant="error" size="sm" isDisabled={!isActive} />
                <Button label={strings('reactivate_license')} onClick={onReissue} size="sm" variant="primary"/>
            </div>}
        </div>
    );
};

export default LicenseIssued;
