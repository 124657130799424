import {Icon, Label, TabNav} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {faArrowUpRight, faDatabase, faPlusSquare} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useState} from 'react';

import {focusOrOpenNewTab, openTab} from '@/app/Home/Tabs';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

function DataStructureEditorSidebar() {
    const [recentlyUsedSbos, setRecentlyUsedSbos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchSbos = async () => {
        const response = await SBOService.getUsableSBOs(searchTerm, {id: 'name', order: '1'}, 0, 15);

        if (response.isSuccessful()) {
            const data = response.getData();
            const tabs = data?.data?.map((item) => ({
                key: item._id,
                label: item.name,
                icon: faDatabase, // Assuming you want to use faDatabase for all items
            }));

            setRecentlyUsedSbos(tabs);
        }
    };

    useEffect(()=>{
        fetchSbos();
    }, [searchTerm]);

    const tabClick =(newTab, sboId, e) => {
        if (newTab ) {
            openTab('dataStructureEditor', undefined, {sboId: sboId});
            e.stopPropagation();
        } else if (e === null && !newTab) {
            console.log('second');
            focusOrOpenNewTab('dataStructureEditor', undefined, {sboId: sboId});
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center px-3">
                <Label text={strings('recent_data_structures')} color="neutral-600" fontWeight="semibold" />
                <Icon icon={faPlusSquare} className="h-5 w-5" color="neutral-600" onClick={()=>openTab('dataStructureEditor', 'New Data Structure')}/>
            </div>
            <SearchBox
                onSearch={(e)=>setSearchTerm(e)}
                placeholder="Search data structures..."
                hasBottomBorder={false}
                textBoxInputClassName="!bg-neutral-100 !rounded-lg !text-neutral-500 !font-normal"
            />
            {recentlyUsedSbos.length > 0
                ? <TabNav
                    orientation="vertical"
                    tabs={recentlyUsedSbos}
                    value={recentlyUsedSbos[0]}
                    onChange={(tabId)=> {
                        tabClick(false, tabId, null);
                    }}
                    className="-mt-2"
                    buttonClassName="hover:bg-primary-25 hover:text-primary-700 text-wrap !h-full !text-left"
                    getButtonProps={(tab) => {
                        return {
                            edgeIcons: {right:
                         {icon: faArrowUpRight, color: 'neutral-700', className: 'rounded-lg hover:primary-100 hover:bg-primary-100 p-1 w-5 h-5',
                             onClick: (e) => {
                                 tabClick(true, tab.key, e);
                             }}},
                        };
                    }}
                />
                :'No data structures found'}

        </div>
    );
}

export default DataStructureEditorSidebar;
