import {Button, LoadingWrapper, ToggleSwitch} from '@aktek/f4kit';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import EmptyRules from './components/EmptyRules';
import TACTable from './components/TACTable';
import useTAC from './hooks/useTAC';

export default function TAC() {
    const {
        rows, restrictVisibility, setRestrictVisibility,
        tagColors, users, groups,
        isEmpty, isEdited, isLoading,
        handleSave, handleDiscard,
        addNewCondition, handleDelete,
        handleTagConditionClick, handleTargetClick, clearColumn,
        handleConditionTagRemove, handleUserTagRemove, handleGroupTagRemove,
    } = useTAC();

    if (isLoading) return <LoadingWrapper isLoading isFull size="xl" className="w-full h-full"></LoadingWrapper>;

    return (
        <div className="relative">
            {!(isEmpty && !isEdited) && <div className="!absolute top-px right-0 flex gap-4 items-center">
                <Button label={strings('discard')} textColor="neutral-600" isGhost onClick={()=>handleDiscard()} className={`${!isEdited ? 'hidden' : ''}`}/>
                <Button label={strings('save')} onClick={()=>handleSave()} isDisabled={!isEdited}/>
            </div>}
            {isEmpty && <EmptyRules addNewCondition={addNewCondition}/>}
            {!isEmpty && <div>
                <div className="flex justify-between items-center mb-4">
                    <div className="flex justify-start items-center gap-4 w-1/3 min-w-[26rem]">
                        <Button
                            icon={faPlus}
                            label={strings('new_rule')}
                            variant="white"
                            onClick={addNewCondition}
                        />
                        <ToggleSwitch
                            value={restrictVisibility}
                            onChange={setRestrictVisibility}
                            label="Restrict tag visibility"
                        />
                    </div>
                </div>

                <TACTable
                    rows={rows}
                    tagColors={tagColors}
                    handleTagConditionClick={handleTagConditionClick}
                    handleConditionTagRemove={handleConditionTagRemove}
                    clearColumn={clearColumn}
                    handleTargetClick={handleTargetClick}
                    handleUserTagRemove={handleUserTagRemove}
                    handleGroupTagRemove={handleGroupTagRemove}
                    handleDelete={handleDelete}
                    users={users}
                    groups={groups}
                />
            </div>}
        </div>
    );
}
