import {faDatabase, faFolder, faLink} from '@fortawesome/pro-regular-svg-icons';

export type TFileNode = Record<string, {
    _id: string,
    label: string,
    type: 'folderName' | 'label' | 'i18nName',
    children: TFileNode,
}>

type TConvertFilesToTreeView = (node: TFileNode) => void

const convertFilesToTreeView: TConvertFilesToTreeView = (input = null) => {
    return Object.values(input).map((item) => ({
        id: item._id,
        label: getLabel(item),
        type: item.type,
        icon: getIcon(item),
        parentId: item?.parentId,
        children: item.children ? convertFilesToTreeView(item.children): null,
    }));
};

const LabelByType = {
    folder: 'folderName',
    shortcut: 'label',
    report: 'i18nName',
};
const IconByType = {
    folder: faFolder,
    report: faDatabase,
    shortcut: faLink,
};

const getLabel = (item) => item[LabelByType[item.type]] || item.name;
const getIcon = (item) => IconByType[item.type];

export default convertFilesToTreeView;
