import {F4FormElementDecorator} from '@aktek/f4form';
import {Button} from '@aktek/f4kit';
import _ from 'lodash';
import {useEffect, useState} from 'react';

import {CreateRoleAndPermissionModal} from '@/app/Admin/AccessControl/pages/RolesAndPermissions/components/CreateRoleAndPermissionModal';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import SearchBox from '@/components/SearchBox/SearchBox';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import AskForConfirmation from '../../Modals/AskForConfirmation';
import F4CardCheckboxGroup from '../F4CardCheckboxGroup';
import {userRoleSelector} from './hooks/useRoleSelector';
import {TRoleSelectorProps} from './types/F4RoleSelector.t';

const RoleSelector = ({
    value = [],
    onChange,
    className,
    fetchSelectedRoles,
    state: initialValue=[],
    readOnly = false,
} : TRoleSelectorProps) => {
    const [search, setSearch] = useState('');

    const {roles: _roles} = useAdminPermissions();

    const {roles, fetchRoles, fetchSelectedRolesFunction} = userRoleSelector(fetchSelectedRoles, onChange, value, search);

    useEffect(() => {
        fetchRoles();
    }, []);

    const resetRoles = () => {
        onChange(initialValue);
    };

    const isEmpty = roles?.length <= 0;

    return (
        <div className={`${className}`}>
            <div className="flex items-center gap-2 py-2 cursor-pointer -mt-7
                justify-between w-full h-full sticky -top-4 elevation-5 !shadow-none bg-white" >
                <SearchBox
                    placeholder={strings('search_roles')}
                    className="!bg-neutral-100 rounded-lg w-full"
                    textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                    hasBottomBorder={false}
                    value={search}
                    onSearch={(value) => setSearch(value.toLowerCase())}
                />

                {!readOnly && <div className="flex flex-row items-end justify-end gap-2  w-fit ">

                    {_roles?.can_create && <Button
                        label={strings('add_role')}
                        textColor="neutral-700"
                        variant="white"
                        onClick={() => AskForCreationModal(CreateRoleAndPermissionModal, {onSave: () => {
                            fetchRoles();
                            fetchSelectedRoles && fetchSelectedRolesFunction();
                        }, state: {mode: 'create', _id: null}})}
                    />}

                    <Button
                        label={strings('reset_roles')}
                        textColor="neutral-700"
                        variant="white"
                        isDisabled={_.isEqual(initialValue.sort(), value?.sort())}
                        className={`ml-1 !px-5 ${_.isEqual(initialValue.sort(), value?.sort()) && '!bg-neutral-200'}`}
                        onClick={() => AskForConfirmation(
                            strings('reset_roles'),
                            strings('reset_roles_confirmation'),
                            resetRoles,
                            'primary',
                            strings('reset_roles'),
                        )}
                    />
                </div>}
            </div>

            {!isEmpty && <F4CardCheckboxGroup isMulti
                name="roles"
                // in case we need to add the view modal
                // disabled={mode == 'view'}
                colSpan={12}
                items={roles}
                className="-mt-6 overflow-auto"
                showOptional={false}
            />}
            {isEmpty && <div className="px-2 py-4 text-center text-neutral-500">{strings('no_results_found')}</div> }
        </div>
    );
};

const F4RoleSelector = F4FormElementDecorator<TRoleSelectorProps>(RoleSelector);
export default F4RoleSelector;
