const getLimitedRandom = function(min, max, roundToInteger) {
    let number = Math.random() * (max - min) + min;

    if (roundToInteger) {
        number = Math.round(number);
    }

    return number;
};

const returnRandomArrayitem = function(array) {
    return array[Math.floor(Math.random()*array.length)];
};

export default class CanvasAnimatedNode {

    constructor(parentNetwork, x, y) {
        this.parentNetwork = parentNetwork;
        this.canvas = parentNetwork.canvas;
        this.ctx = parentNetwork.ctx;
        this.nodeColor = returnRandomArrayitem(this.parentNetwork.options.nodeColors);
        this.radius = getLimitedRandom(1.5, 2.5);
        this.opacity = 0;
        this.x = x || Math.random() * this.canvas.width;
        this.y = y || Math.random() * this.canvas.height;
        this.velocity = {
            x: (Math.random() - 0.5) * parentNetwork.options.velocity,
            y: (Math.random() - 0.5) * parentNetwork.options.velocity,
        };
    };

    draw = function() {
        // Draw node
        this.ctx.beginPath();
        this.ctx.fillStyle = this.nodeColor;
        this.ctx.globalAlpha = this.opacity;
        this.ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        this.ctx.fill();
    };

    update = function() {
        if (this.opacity < 1) { // Over the next 100 frames, get to full opacity
            this.opacity += 0.01;
        } else {
            this.opacity = 1;
        }

        // Change dir if outside map
        if (this.x > this.canvas.width + 100 || this.x < -100) {
            this.velocity.x = -this.velocity.x;
        }

        if (this.y > this.canvas.height + 100 || this.y < -100) {
            this.velocity.y = -this.velocity.y;
        }

        // Update position
        this.x += this.velocity.x;
        this.y += this.velocity.y;
    };

}
