
import {Datetime} from '@aktek/f4kit';
import {Cell, Row} from '@tanstack/react-table';

import ReferenceButton from '@/components/ReferenceButton/ReferenceButton';

export const CellAutomatic = (columnDef) => {
    const isTime = columnDef.field?.dynamicField == 'Time';

    return {

        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue() + '';

            if (isTime) {
                return <Datetime value={parseInt(value)} />;
            }

            return <div className="flex items-center gap-1">
                <ReferenceButton value={value || ''} />
            </div>;
        },

    };
};
