import {F4Form, useF4FormRef} from '@aktek/f4form';
import {Drawer, FitHeight} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {useEffect, useState} from 'react';

import F4Sidebar from '@/components/F4Sidebar/F4Sidebar';
import {openCloseDrawer} from '@/utils/openCloseDrawer';
import ls from '@/utils/storage/ls';

import DataStructureEditorMainInfo from './DataStructureEditorMainInfo';
import DataStructureEditorSidebar from './DataStructureEditorSidebar';
import DataStructureSectionsFields from './DataStructureSectionsFields';

function DataStructureEditor(props) {
    const settingsFormRef = useF4FormRef();
    const [savedValues, setSavedValues] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchSboById = async ()=> {
        setIsLoading(true);
        const res = await SBOService.getCompleteSBOById(props.sboId);

        if (res.isSuccessful()) {
            const data = res.getData() as object;

            setSavedValues(data);
        }

        setIsLoading(false);
    };

    useEffect(()=>{
        props?.sboId && fetchSboById();
    }, [props?.sboId]);

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                defaultOpen={ls.drawer?.dataStructure ?? true}
                onOpenChange={(e) => openCloseDrawer('dataStructure', !!e)}
                tooltipDelay={200}
                buttonSize="sm"
                className="sm:!w-2/4 md:!w-1/4 !w-11/12 min-w-64"
            >

                <F4Sidebar>
                    <DataStructureEditorSidebar/>
                </F4Sidebar>

                <FitHeight className="h-full w-full overflow-x-auto bg-neutral-50 p-4">
                    <F4Form
                        isLoading={isLoading}
                        formKey="dataStructure"
                        ref={settingsFormRef}
                        printData="console"
                        initialValue={savedValues || {}}
                        className="h-full px-20 py-6"
                    >
                        <DataStructureEditorMainInfo />
                        <DataStructureSectionsFields />
                    </F4Form>
                </FitHeight>
            </Drawer>
        </div>
    );
}

export default DataStructureEditor;
