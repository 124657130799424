import {ColumnSort, SortingState} from '@tanstack/react-table';
import React from 'react';

import {TUseSort} from '../Types/Table.t';

const useSort: TUseSort = (initalValue = []) => {
    const [sortingState, setSortingState] = React.useState<SortingState>(initalValue);

    const setSortingColumn = ({id, desc}: ColumnSort) => {
        setSortingState([{id, desc}]);
    };

    const isColumnSorted = (id:string) => {
        return sortingState[0]?.id == id;
    };

    const isDesc = () => {
        return sortingState[0]?.desc;
    };

    const resetSortingColumn = () => {
        setSortingState([]);
    };

    return {sortingState, setSortingState, setSortingColumn, isColumnSorted, resetSortingColumn, isDesc};
};

export default useSort;
