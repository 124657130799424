const icon = 'w-5 h-5';
const deleteDiv = 'select-text cursor-text px-5 block w-full break-words break-all hyphens-auto text-center';
const actionsModal = '!w-[408px] [&>:first-child]:p-0 py-5';
const labelDiv = 'flex flex-col gap-2 justify-center items-center';
const formDiv = 'flex flex-col items-center justify-center gap-5 ';
const iconDiv = 'flex items-center justify-center w-12 h-12 bg-error-100 rounded-3xl';

export const getDeleteClasses = () => ({
    deleteDiv,
    actionsModal,
    labelDiv,
    icon,
    formDiv,
    iconDiv,
});
