import {F4FormElementDecorator} from '@aktek/f4form';
import {TextBox, TTextBoxProps} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const F4Textbox = F4FormElementDecorator<TTextBoxProps>(TextBox, (value, props)=>{
    const messages =[];

    if (value && value < props.min) {
        messages.push(`${strings('value_cannot_be_less_than')} ${props.min}`);
    }

    if (value && value > props.max) {
        messages.push(`${strings('value_cannot_be_greater_than')} ${props.max}`);
    }

    // checking if required and if empty and if empty but with spaces
    if (props.required && value?.length !== 0) {
        const trimmedValue = typeof value === 'string' ? value.trim() : value;

        if (trimmedValue?.length === 0 ) {
            messages.push(strings('field_cant_be_empty_spaces'));
        }
    }

    // Regex to validate email: no leading/trailing special chars (._%+-) and no consecutive special chars (e.g., "abc@domain.com", "a.b-c@domain.co").
    const emailRegex = /^(?![._%+-])[a-zA-Z0-9._%+-]+(?<![._%+-])@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (props.type == 'email' && value && !emailRegex.test(value)) messages.push(strings('invalid_email'));

    return {isValid: messages.length === 0, messages};
});

export default F4Textbox;

