import {strings} from '@/localization/i18n';

export const ACLNames = {
    'can_create': strings('create'),
    'can_edit': strings('edit'),
    'can_view': strings('view'),
    'can_delete': strings('delete'),
    'can_access': strings('access'),
};

export const PERMISSION_FIELD = ['can_create', 'can_view', 'can_edit', 'can_delete', 'can_access'];
