import {Button, Label} from '@aktek/f4kit';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

import TagConditionColumn from './TagConditionColumn';
import TagTargetColumn from './TagTargetColumn';

export default function TACTable({
    rows,
    tagColors,
    handleTagConditionClick,
    handleConditionTagRemove,
    clearColumn,
    handleTargetClick,
    handleUserTagRemove,
    handleGroupTagRemove,
    handleDelete,
    users,
    groups,
}) {
    return (
        <div className="w-full border border-neutral-200 rounded-xl">
            {/* HEADER */}
            <div className="flex justify-center items-center bg-white p-3 rounded-t-xl">
                <Label text={strings('tag_condition')} color="neutral-600" size="xs" className="w-5/12 font-medium" />
                <Label text={strings('target')} color="neutral-600" size="xs" className="w-6/12 font-medium pl-5" />
                <div className="w-1/12" />
            </div>

            {/* DATA */}
            <div className="rounded-b-xl overflow-hidden">
                {Object.entries(rows).map(([id, row], index) => (
                    <div key={id} className={`flex justify-center items-center border-t border-neutral-200 ${index % 2 === 0 ? 'bg-neutral-50' : 'bg-white'} hover:bg-neutral-100`}>
                        <TagConditionColumn
                            tagColors={tagColors}
                            tagCondition={row.tagCondition}
                            id={id}
                            handleTagConditionClick={handleTagConditionClick}
                            handleConditionTagRemove={handleConditionTagRemove}
                            clearColumn={clearColumn}
                        />
                        <TagTargetColumn
                            target={row.target}
                            id={id}
                            handleTargetClick={handleTargetClick}
                            handleUserTagRemove={handleUserTagRemove}
                            handleGroupTagRemove={handleGroupTagRemove}
                            clearColumn={clearColumn}
                            users={users}
                            groups={groups}
                        />
                        <div className="w-1/12 flex justify-end ml-2 p-3">
                            <Button icon={faTrash} size="xs" isGhost variant="neutral" onClick={() => handleDelete(id)} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
