import React from 'react';

import {TUseCount} from '../Types/Table.t';

const useCount:TUseCount = ({getRowsCount, rowsCount}) => {
    const [count, setCount] = React.useState<number|undefined>(rowsCount);

    const [isCountLoading, setCountLoading] = React.useState(false);

    const fetchCount = async () => {
        setCountLoading(true);
        const count = await getRowsCount();
        setCount(count);

        setCountLoading(false);
    };

    React.useEffect(() => {
        getRowsCount && fetchCount();
    }, []);

    return {
        count, setCount,
        isCountLoading, setCountLoading,
    };
};

export default useCount;
