import {isEmpty} from 'lodash';


const createPermissions = (category) => {
    const permissions = ls.userPermission;

    return {
        PERMISSIONS_EXISTS: () => permissions !== undefined && !isEmpty(permissions),

        EXISTS: (name) => {
            return permissions && permissions[category] && permissions[category][name];
        },
        CREATE: (name) => {
            return permissions && permissions[category] && permissions[category][name]?.can_create === true;
        },
        EDIT: (name) => {
            return permissions && permissions[category] && permissions[category][name]?.can_edit === true;
        },
        VIEW_OR_CREATE: (name) => {
            return (
                permissions
        && permissions[category]
        && (permissions[category][name]?.can_view === true || permissions[category][name]?.can_create === true)
            );
        },
        VIEW: (name) => {
            return permissions && permissions[category] && permissions[category][name]?.can_view === true;
        },
        DELETE: (name) => {
            return permissions && permissions[category] && permissions[category][name]?.can_delete === true;
        },
        ACCESS: (name) => {
            if (Array.isArray(name)) {
                return name.some((entry) => permissions?.[category]?.[entry]?.can_access === true);
            } else {
                return permissions?.[category]?.[name]?.can_access === true;
            }
        },
    };
};

const Permissions = {
    App: () => createPermissions('app'),
    Admin: () => createPermissions('admin'),
};

export default Permissions;
