import {TColumn} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

const IO_JOBS_COLUMNS: Array<TColumn> = [
    {key: 'label', header: strings('name'), type: 'string', isSortable: true},
    {key: 'type', header: strings('type'), type: 'string', isSortable: true},
    {key: 'subType', header: strings('subtype'), type: 'string', isSortable: true},
    {key: 'company', header: strings('company'), type: 'string', isSortable: true},
    {key: 'priority', header: strings('priority'), type: 'string', isSortable: true},

    {key: 'datetime', header: strings('created_at'), type: 'datetime', isSortable: true},
    {key: 'lastChangeDatetime', header: strings('updated_at'), type: 'datetime', isSortable: true},
    {key: 'errorCount', header: strings('num_errors'), type: 'string', isSortable: true},

];

export default IO_JOBS_COLUMNS;
// TODO: we need to add the status, progress, and task type
// const headers = [
//     {
//         _id: 'status', width: 80, title: strings.status, type: 'fontawesome', searchable: false, orderable: true, conditionsFunc: (entry) => {
//             const completed = 0;
//             const planned =0;

//             const progress = `(${completed}/${planned})`;

//             const result = [];

//             if (entry.status === -2) {
//                 return [{icon: 'fa-pause', color: '#fcba03', tooltip: `Paused`}];
//             }

//             if (entry.status === -1) {
//                 return [{icon: 'fa-times', color: '#cc0425', tooltip: `Error`}];
//             }

//             if (entry.status === 0) {
//                 return [{icon: 'fa-ban', color: '#cc0425', tooltip: `Cancelled`}];
//             }

//             if (entry.status === 1) {
//                 return [{icon: 'fa-hourglass-start', color: '#51c3f0', tooltip: `Queued`}];
//             }

//             if (entry.status === 2) {
//                 const stats = entry?.taskStats?.stats;
//                 let isRunning = false;

//                 if (stats) {
//                     for (const taskId in stats) {
//                         if (stats[taskId].s2 > 0) {
//                             isRunning = true;
//                             break;
//                         }
//                     }
//                 }

//                 if (!isRunning) {
//                     return [{icon: 'fa-ellipsis-h', blink: true, color: '#9d04cc', tooltip: `Waiting for slot`}];
//                 } else {
//                     return [{icon: 'fa-play', blink: true, color: '#9d04cc', tooltip: `In Progress`}];
//                 }
//             }

//             if (entry.status === 3) {
//                 return [{icon: 'fa-check', color: '#1a5700', tooltip: `Completed`}];
//             }

//             if (entry.status === 4) {
//                 return [{icon: 'fa-hand-paper-o', color: '#b56400', tooltip: `Interrupted`}];
//             }

//             if (entry.status === 5) {
//                 return [{icon: 'fa-flag-checkered ', color: '#114d82', tooltip: strings.closed}];
//             }

//             return result;
//         },
//     },
//     {_id: 'progress', title: strings.progress, type: 'string', searchable: true, orderable: false, conditionsFunc: (entry) => {
//         let completed = 0;
//         let planned =0;
//         const tasks = entry?.taskStats?.tasks;

//         if (tasks) {
//             for (const task of tasks) {
//                 planned += entry?.taskStats?.[`${task}Count`] || 0;
//                 completed += entry.taskStats?.[`${task}Completed`] || 0;
//             }
//         }

//         const progress = `${Math.ceil(100*completed/planned)||0}% (${completed}/${planned})`;

//         return progress;
//     }},
//     {_id: 'taskTypes', title: strings.task_types, type: 'string', searchable: true, orderable: false, conditionsFunc: (entry) => {
//         const result = [];
//         const tasks = entry?.taskStats?.tasks;

//         if (tasks) {
//             for (const task of tasks) {
//                 const planned = entry?.taskStats?.[`${task}Count`] || 0;
//                 if (planned === 0) continue;

//                 const completed = entry.taskStats?.[`${task}Completed`] || 0;
//                 const res = [];

//                 if (entry?.taskStats?.stats?.[task]?.s2 > 0) {
//                     res.push(`[${entry?.taskStats?.stats?.[task]?.s2} ▶]`);
//                 }

//                 if (entry?.taskStats?.stats?.[task]?.s1 > 0) {
//                     res.push(`[${entry?.taskStats?.stats?.[task]?.s1} ⧗]`);
//                 }

//                 result.push(`${task} (${completed}/${planned} ${res.join(', ')})`);
//             }
//         }

//         return result.sort().join(', ');
//     }},
// ];
