
/* eslint-disable guard-for-in */
import 'react-virtualized/styles.css';

import {Icon} from '@aktek/f4kit';

import {divStyle, expandDivStyle} from '../constants/PermissionConstants';
import QuickSetToggle from './QuickSetToggle';
import {VersionLabel} from './VersionLabel';

const PermissionRowName = ({
    permission,
    selectedSection,
    depth = 0,
    currentPath = '',
    onToggleHelperClick,
    disabled,
    toggleRowExpansion,
    expandControlIcon,
    hasChildren,
    rowState,
    setIsSuperAdmin,
    isSuperAdmin,
}) => {
    return (
        <>
            <div className="ReactVirtualized__Table__rowColumn" role="gridcell"
                style={{width: '12px', ...expandDivStyle, textAlign: 'left'}}>
                {hasChildren && <Icon
                    color="neutral-400"
                    onClick={() => toggleRowExpansion(permission.id)}
                    icon={expandControlIcon} />}
            </div>
            <div className="ReactVirtualized__Table__rowColumn !flex !flex-row !gap-1 !items-center " role="gridcell"
                style={{...divStyle, textAlign: 'left', width: '40%', paddingLeft: depth * 16 + 'px'}}>
                <QuickSetToggle
                    path={currentPath}
                    state={rowState}
                    index={permission.originalIndex}
                    category="horizontal"
                    active={!disabled}
                    selectedSection={selectedSection}

                    setIsSuperAdmin={setIsSuperAdmin}
                    isSuperAdmin={isSuperAdmin}
                    onClick={onToggleHelperClick} />
                <span style={{marginLeft: '3px'}} className="text-neutral-700 font-medium text-sm">
                    {permission.name}
                    <VersionLabel version={permission.lastVersion} />
                </span>
            </div>
        </>
    );
};

export default PermissionRowName;
