import {strings} from '@/localization/i18n';

export default {
    'app': {title: strings('home'), acls: ['can_access'], show: true},
    'admin': {title: strings('admin'), acls: ['can_create', 'can_view', 'can_edit', 'can_delete'], show: true},
    'obac': {title: strings('access_control'), acls: ['can_view', 'can_edit', 'can_delete'], show: true},
    'dashboard': {title: strings('dashboards'), acls: ['can_view', 'can_edit', 'can_delete'], show: true},
    'report': {title: strings('data_structure'), acls: ['can_create', 'can_view', 'can_edit', 'can_delete'], show: true},
    'resource': {title: strings('resources'), acls: ['can_create', 'can_view', 'can_edit', 'can_delete'], show: true},
    'field': {title: strings('fields'), acls: ['can_create', 'can_view', 'can_edit'], show: false},
};
