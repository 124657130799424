import {Button, Label, Modal, TModalProps, useModalRef} from '@aktek/f4kit';
import cn from 'classnames';
import {useEffect} from 'react';

import {strings} from '@/localization/i18n';

export type TCloseWindowModalProps = {
    modalProps?: Partial<TModalProps>;
    className?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    openOnMount?: boolean;
}

export default function CloseWindowModal(props: TCloseWindowModalProps) {
    const {className, openOnMount, onConfirm, onCancel, modalProps={size: 'xs'}} = props;

    const modalRef = useModalRef();

    useEffect(()=> {
        if (openOnMount) {
            modalRef.current.open();
        }
    }, []);

    const onConfirmCallback = () => {
        onConfirm?.();
        modalRef.current.close();
    };

    const onCancelCallback = () => {
        onCancel?.();
        modalRef.current.close();
    };

    return <Modal
        ref={modalRef}
        headerClassName="hidden"
        className={cn('overflow-hidden !rounded-3xl p-6 max-w-[400px]', className)}
        title={null}
        hasXIcon={false}
        size={modalProps.size}
        onClose={onCancel}
        onChange={modalProps.onChange}
    >
        <div className="flex flex-col min-h-36 items-center gap-5">
            <div className="flex flex-col items-center justify-center">
                <Label
                    size="lg"
                    text={'Are you sure you want to close this window?'}
                    className="mb-2 text-center"
                    fontWeight="semibold"
                    color="neutral-900"
                />
                <Label
                    text={'All the data you entered is not saved yet so everything will be discarded.'}
                    color="neutral-600"
                    fontWeight="normal"
                    size="md"
                    className="text-center"
                />
            </div>
            <div className="flex mt-2 w-full gap-4">
                <Button
                    label={strings('stay_here')}
                    variant="white"
                    className="w-1/2"
                    onClick={onCancelCallback}
                    textColor="neutral-700"
                />
                <Button
                    label={strings('discard')}
                    className="w-1/2 !bg-error-25"
                    onClick={onConfirmCallback}
                    variant="error"
                    textColor="error-800"
                />
            </div>
        </div>

    </Modal>;
}
