
import 'react-virtualized/styles.css';

import React from 'react';

import {ChildrenName} from '../constants/ChildrenName';
import QuickSetToggle from './QuickSetToggle';

const Sizes = {
    rowHeight: 50,
    checkBoxColumnWidth: 30,
    permissionNameColumnWidth: 170,
};

const divStyle = {overflow: 'hidden', flexGrow: 1, flexShrink: 1, flexBasis: Sizes.permissionNameColumnWidth + 'px'};
export const checkBoxStyle = {overflow: 'hidden', flexGrow: 1, flexShrink: 1, flexBasis: Sizes.checkBoxColumnWidth + 'px'};

// Hassan: we need to be able to select texts
// export const noSelectionStyle = {
//     MozUserSelect: 'none', WebkitUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'};

const ChildrenHeader = ({
    currentPath,
    permission,
    depth,
    currentACLs,
    selectedSection,
    onToggleHelperClick,
    setIsSuperAdmin,
    isSuperAdmin,
    disabled}) => {
    const childName = ChildrenName[permission.category];

    return (
        <div key={`Perm_Child_${permission.id}`}
            className={`ReactVirtualized__Table__row bg-white`}
            style={{height: Sizes.rowHeight, paddingLeft: ((depth + 1) * 30 + 2)}}
            role="row">
            <td key={`Perm_Child_Title_${permission.id}`} className="ReactVirtualized__Table__rowColumn" role="gridcell"
                style={{...divStyle, textAlign: 'left', width: '40%', paddingLeft: (depth + 1) * 16}}>
                <span style={{marginLeft: '3px', fontWeight: '500'}}>
                    {childName}
                </span>
            </td>
            {currentACLs.map((ACL) => {
                let columnState;

                if (permission.children?.every((val) => val[ACL] === 1)) {
                    columnState = true;
                }

                if (!columnState && permission.children?.every((val) => val[ACL] === 0)) {
                    columnState = false;
                }

                if (columnState === undefined) {
                    columnState = null;
                }

                return (
                    <td key={`${permission.id}_children_${ACL}`}
                        className="ReactVirtualized__Table__rowColumn"
                        role="gridcell" style={checkBoxStyle} align="center">
                        <QuickSetToggle
                            state={columnState}
                            aclType={ACL}
                            path={`${currentPath}.children`}
                            active={!disabled}
                            selectedSection={selectedSection}
                            index={permission.originalIndex}
                            category="vertical"

                            setIsSuperAdmin={setIsSuperAdmin}
                            isSuperAdmin={isSuperAdmin}
                            onClick={onToggleHelperClick} />
                    </td>
                );
            })}
        </div>
    );
};

export default React.memo(ChildrenHeader);
