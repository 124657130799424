import './index.css';

import useInitializeSDKs from '@/services/useInitializeSDKs';

import VerifyToken from './app/Authentication/helpers/verifyToken';
import {useSignUserOut} from './context/UserContext/Hooks/useSignUserOut';
import AppRoutes from './routing/AppRoutes';

export default function App() {
    const signOut = useSignUserOut();

    const logout = () => {
        signOut();
        window.history.replaceState({}, '/');
    };

    useInitializeSDKs({logout});
    VerifyToken();

    return ( <AppRoutes />);
}
