import {Icon, Label} from '@aktek/f4kit';
import {MorphService, SBOService} from '@aktek/morph-frontend-sdk';
import {faCircleInfo, faXmark} from '@fortawesome/pro-regular-svg-icons';

// TODO: this is not done yet, we  will add later the table showing a preview of the data
/**
 * ! https://dev.aktek.io/api/morph/sbos/headers - getHeaders
 * ! https://dev.aktek.io/api/morph/morph/retrieveMorphs - retrieveMorphs
 * ? API's to use
 * todo: MorphService.retrieveMorphsBySBOId
 * todo: MorphService.retrieveMorphsCountBySBOId
 * todo: SBOService.getSBOHeaders
 * todo: SBOService.getCompleteSBOById
 */

export default function DeleteAllData() {
    return (
        <div className="my-2">
            <Label size="xl" fontWeight="semibold" color="error-300" text="Here we will have the Data structure table" />
        </div>
    );
}
