export const ACLRestrictions = {
    report: {
        can_delete: 'can_view',
        can_edit: 'can_view',
    },
    field: {
        can_delete: 'can_view',
        can_edit: 'can_view',
    },
};
