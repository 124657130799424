
import {Cell, Row} from '@tanstack/react-table';

import {strings} from '@/localization/i18n';

export const CellBoolean = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const selected = row.getValue(cell.column.id);

            return <div className="flex items-center">
                {strings(selected ? 'yes': 'no')}
            </div>;
        },
    };
};
