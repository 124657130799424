import '@aktek/f4kit/dist/style.css';
import '@/index.css';
import '@/utils/storage/exports';

import {Toast} from '@aktek/f4kit';
import React from 'react';
import {createRoot} from 'react-dom/client';

import App from '@/App.tsx';

import GlobalContextProviders from './context/Global.Provider';
import DevBox from './DevBox';
import XApplyTheme from './helpers/ApplyTheme.fn';

const Main = () => {
    // apply f3theme to the html after getting the saved theme for the user
    XApplyTheme(ls.theme);

    return (
        <React.StrictMode>
            <GlobalContextProviders>
                <DevBox />
                <Toast />
                <App />
            </GlobalContextProviders>
        </React.StrictMode>
    );
};

const root = document.getElementById('root')!;

createRoot(root).render(<Main />);
