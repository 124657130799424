import {F4FormContainer, F4FormDiv, F4FormElementDecorator, F4FormStep} from '@aktek/f4form';
import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';
import _isEmpty from 'lodash/isEmpty';
import {useEffect, useState} from 'react';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';
import F4AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4TextBox from '@/components/F4Elements/F4TextBox';
import {LANGUAGES} from '@/constants/languages';
import {useAppPermissions} from '@/context/UserContext/Hooks/useAppPermissions';
import {strings} from '@/localization/i18n';

import {convertToAccountManagers} from '../Agency.Columns';
import {COMPANY_SUBTYPE, JOB_PRIORITY, TYPES} from '../constants/constants.c';
import useAgency from '../hooks/useAgency';

export function CompanyInfo(state) {
    const {listAccountManagers, getAllAccountManagers} = useAgency();
    const {extra} = useF4CreatableFormContext();
    const mode = extra?.state?.mode;
    const languages = extra.languages;
    const {manageAccountManagers} = useAppPermissions();

    const isViewMode = mode == 'view';
    const isEditMode = mode == 'edit';

    const [_availableLanguages, setAvailableLanguages] = useState([]);

    const defaultLanguages = _availableLanguages
        .filter((lang) => LANGUAGES[lang])
        .sort((a, b) => LANGUAGES[a].label.localeCompare(LANGUAGES[b].label))
        .reduce((acc, lang) => {
            acc[lang] = LANGUAGES[lang];

            return acc;
        }, {} as Record<TDropdownValue, TDropdownOption>);

    const sortedAvailableLanguages = Object.fromEntries(
        Object.entries(LANGUAGES).sort(([, a], [, b]) => a?.label.localeCompare(b?.label)),
    );

    useEffect(() => {
        if (languages) {
            setAvailableLanguages(languages);
        }
    }, [languages]);

    useEffect(() => {
        if (manageAccountManagers?.can_access) {
            getAllAccountManagers();
        }
    }, []);

    const convertedToAccountManagers = convertToAccountManagers(listAccountManagers);
    const noAccountManagers = _isEmpty(convertedToAccountManagers);

    const getAccountManagersPlaceHolder = ():string => {
        if (isViewMode) {
            return strings('not_specified');
        }

        if (noAccountManagers) {
            return strings('no_account_managers_found');
        }

        return undefined;
    };

    return (
        <F4FormStep step="companyDetails">

            <F4FormDiv className="overflow-y-auto">

                <F4FormContainer colSpan={12} className="w-full flex flex-col !gap-5">

                    <F4AvatarImageSelector
                        title={strings('company_logo')}
                        showOptional={false}
                        size="3xl"
                        isViewMode={state?.mode == 'view'}
                        name="companyLogo"
                        allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                        maxSizeMB={1}
                    />

                    <F4TextBox
                        name="companyLoginId"
                        isDisabled={isViewMode}
                        label={strings('company_id')}
                        showOptional={!isViewMode}
                        placeholder=""
                    />

                    <F4TextBox
                        required
                        isDisabled={isViewMode}
                        name="companyName"
                        label={strings('name')}

                        placeholder={strings('name')} />

                    <F4Dropdown
                        options={TYPES}
                        required
                        label={strings('type')}
                        isDisabled={isEditMode || isViewMode}
                        hideArrow={isViewMode}
                        name="companyType" />

                    <F4Dropdown
                        options={COMPANY_SUBTYPE}
                        isDisabled={isViewMode}
                        hideArrow={isViewMode}
                        required
                        label={strings('company_subtype')}

                        name="companySubtype" />

                    <F4Dropdown
                        options={sortedAvailableLanguages}
                        isDisabled={isViewMode}
                        isClearable={!isViewMode}
                        hideArrow={isViewMode}
                        required
                        isMulti
                        label={strings('available_languages')}
                        onChange={(value) => {
                            if (value) {
                                setAvailableLanguages(value);

                                return;
                            }

                            setAvailableLanguages([]);
                        }}
                        validate={((value) => {
                            if (value?.length == 0) return {isValid: false, messages: [strings('field_is_mandatory')]};

                            return {isValid: true};
                        })}
                        name="languages" />

                    <F4Dropdown
                        options={defaultLanguages}
                        isDisabled={_availableLanguages.length === 0 || isViewMode}
                        hideArrow={isViewMode}
                        required
                        validate={(value) => {
                            if (_availableLanguages.length === 0 || !_availableLanguages.includes(value)) {
                                return {isValid: false, messages: [strings('field_is_mandatory')]};
                            }

                            return {isValid: true};
                        }}
                        label={strings('default_language')}
                        name="defaultLanguage" />

                    <F4Dropdown
                        options={JOB_PRIORITY}
                        isDisabled={isViewMode}
                        hideArrow={isViewMode}
                        required
                        label={strings('job_priority')}
                        name="jobPriority" />

                    {manageAccountManagers?.can_access
                    && <F4Dropdown
                        options={convertedToAccountManagers}
                        isDisabled={isViewMode || noAccountManagers}
                        label={strings('accountManager')}
                        name="accountManagers"
                        isClearable={!isViewMode && !noAccountManagers}
                        hideArrow={isViewMode || noAccountManagers}
                        showOptional={!isViewMode}
                        placeholder={getAccountManagersPlaceHolder()}
                        isMulti
                    />
                    }

                </F4FormContainer>
            </F4FormDiv>
        </F4FormStep>
    );
}

const F4CompanyInfo = F4FormElementDecorator(CompanyInfo);
export default F4CompanyInfo;
