import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import useDocumentTitle from '@/hooks/useDocumentTitle';
import {strings} from '@/localization/i18n';
import {useNavigateToRoot} from '@/routing/navigations';

import AuthContainer from '../../components/AuthContainer';
import LoginCredentials from './components/LoginCredentials';
import TwoFactorAuth from './components/TwoFactorAuth';
import type {TTwoFactorState} from './types/twoFactorState.t';
enum ViewModes { login, twoFactorAuth}

export default function Login() {
    useDocumentTitle(strings('login'));
    const navigateToRoot = useNavigateToRoot();

    const [loginView, setLoginView]=useState<ViewModes>(ViewModes.login);
    const [twoFactorState, setTwoFactorState] = useState<TTwoFactorState>(null);

    const onLoginSuccess = useCallback((twoFactorState: TTwoFactorState)=> {
        setTwoFactorState(twoFactorState);
        setLoginView(ViewModes.twoFactorAuth);
    }, []);

    const on2FASuccess = useCallback(()=> {
        navigateToRoot(true);
    }, []);

    return (
        <AuthContainer>
            {loginView === ViewModes.login
            && <LoginCredentials
                onLoginSuccess={onLoginSuccess}
            />}

            {loginView === ViewModes.twoFactorAuth
            && <TwoFactorAuth
                setLoginView={setLoginView}
                twoFactorAuthState={twoFactorState}
                on2FASuccess={on2FASuccess}
            />}
        </AuthContainer>
    );
}
