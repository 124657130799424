import {useRef, useState} from 'react';

import SearchBox from '@/components/SearchBox/SearchBox';

import ExplorerTree from './ExplorerTree';

export default function ExplorerSideBar({items, isLoading, refresh, deleteFolder}) {
    const [searchValue, setSearchValue] = useState('');
    const searchRef = useRef(null);

    return (
        <div className="flex flex-col h-full overflow-x-hidden w-full relative">
            <SearchBox
                ref={searchRef}
                onSearch={setSearchValue}
                className="sticky w-full elevation-5 !shadow-none top-0 bg-white text-sm" />

            <ExplorerTree searchValue={searchValue} isLoading={isLoading} items={items} refresh={refresh} deleteFolder={deleteFolder} />
        </div>
    );
}

