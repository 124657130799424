class LocalStorageHelper {

    static set(key: string, value: unknown): void {
        try {
            if (!value) {
                localStorage.removeItem(key);

                return;
            }

            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error setting item in local storage:', error);
        }
    }

    static clear(): void {
        try {
            localStorage.clear();
        } catch (error) {
            console.error('Error clearing local Storage', error);
        }
    }

    static get(key: string): unknown {
        const storedItem = localStorage.getItem(key);

        if (storedItem) {
            try {
                return JSON.parse(storedItem);
            } catch (error) {
                // console.error('Error parsing stored item:', error);

                return;
            }
        }

        return;
    }

    static remove(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error('Error removing item from local storage:', error);
        }
    }

}

export default LocalStorageHelper;
