import React from 'react';

import {TUseColumnsProps, TUseColumnsState} from '../Types/Table.t';

const useColumns = ({columns: _columns = [], getColumns, paginationState}: TUseColumnsProps): TUseColumnsState => {
    const [columns, setColumns] = React.useState<TUseColumnsState['columns']>(_columns);
    const [isColumnsLoading, setIsColumnsLoading] = React.useState(false);

    const fetchColumns = async () => {
        if (!getColumns) return;
        setIsColumnsLoading(true);
        const columns = await getColumns(paginationState);
        setColumns(columns);
        setIsColumnsLoading(false);
    };

    React.useEffect(() => {
        fetchColumns();
    }, []);

    return {
        columns,
        isColumnsLoading,
        fetchColumns,
    };
};

export default useColumns;
