
import {BaseTable, ContextMenu, LoadingWrapper, Pagination, useContextMenuRef} from '@aktek/f4kit';
import React from 'react';

import AskForModal from '../Modals/AskForModal';
import {CellByType, createCellActions} from './CellByType';
import ColumnVisibilityModal from './ColumnVisibilityModal';
import EmptyTableState from './Components/EmptyTableState';
import Ribbon from './Components/Ribbon';
import {setUpSelectableColumnCell} from './Helpers/setUpSelectableColumn';
import {setUpSortableColumn} from './Helpers/setUpSortableColumn';
import {GetClasses} from './Styles/GetClasses';
import {TStatelessTableProps} from './Types/Table.t';

const StatelessTable = ({
    state,
    columns = [],
    rows = [],
    searchState,
    isRowsLoading = false,
    isColumnsLoading = false,
    pagination,
    events,
    ribbonActions,
    sorting,
    onRowAction,
    emptyProps,
    name,
    baseTableRef,
    modalColumnsVisibilityRef,
    contextMenuRef,
    className,
    baseTableClasses,
    getRightClickRowActions,
}: TStatelessTableProps) => {
    modalColumnsVisibilityRef.open = () => AskForModal(ColumnVisibilityModal, {baseTableRef});

    const updatedColumns = React.useMemo<TStatelessTableProps['columns']>(() => {
        const _columns:TStatelessTableProps['columns'] = columns.reduce((result, columnDef) => {
            const {isSelectable, isSortable, type, renderCell, renderHeader, ...rest} = columnDef;
            const columnCreator = type == 'actions' ? createCellActions : CellByType[type];

            let baseColumn;

            if (type == 'actions') {
                baseColumn = columnCreator(columnDef, state, onRowAction, contextMenuRef);
            }

            if (!baseColumn) {
                const columnCreator = CellByType[type];
                if (!columnCreator) return result;
                baseColumn = CellByType[type](columnDef);
            }

            Object.assign(baseColumn, rest);

            if (isSelectable) setUpSelectableColumnCell(baseColumn, columnDef);
            if (isSortable) setUpSortableColumn(baseColumn, columnDef, sorting);

            if (renderCell) {
                baseColumn.cell = renderCell;
            }

            if (renderHeader) {
                baseColumn.header = renderHeader;
            }

            result.push(baseColumn);

            return result;
        }, []);

        return _columns;
    }, [columns, sorting?.sortingState]);

    const hasActions =!!(ribbonActions?.leftActions || ribbonActions?.rightActions );
    const isLoading = isColumnsLoading || isRowsLoading;

    const classes = GetClasses(className);

    const _events = {
        ...events,
        onRowContextMenu: (event, table, row) => {
            contextMenuRef?.openAtEvent?.(event, getRightClickRowActions(row));
            event.preventDefault();
        },
    };

    return <div className={classes.container} >
        {hasActions && <div className={classes.actionsContainer}>
            <Ribbon {...ribbonActions} searchState={searchState} state={state} />
        </div>}

        <ContextMenu contextMenuRef={contextMenuRef}><></></ContextMenu>

        <BaseTable
            classes={baseTableClasses}
            ref={baseTableRef}
            events={_events}
            isRoundedTop={!hasActions}
            isBordered={false}
            columns={updatedColumns}
            rows={rows}
            views={{
            }}
        />

        {isLoading && <div className={classes.loadingIndicator}>
            <LoadingWrapper size={'xl'} isLoading borderRadius={'xl'} />
        </div>}

        {!isLoading && Array.isArray(rows) && !rows.length && <div className="text-center text-neutral-900 text-lg w-full h-full font-semibold py-7">
            <EmptyTableState
                message={emptyProps?.message}
                onClick={emptyProps?.onClick}
                search={searchState.query}
                addLabel={emptyProps?.addLabel}
                name={name}/>
        </div> }

        {!isLoading && !Array.isArray(rows) && <div>There was an error, please try again</div>}

        {pagination && !(Array.isArray(rows) && !rows.length) && <div className="py-2 px-6 border-t border-neutral-200">
            <Pagination paginationState={pagination}/>
        </div>}
    </div>;
};

export default StatelessTable;
