import {TTab} from '@aktek/f4kit';
import {faBrush, faGear, faTag} from '@fortawesome/pro-regular-svg-icons';

import {CanAccessSettings, CanAccessTags} from '@/constants/Permissions.c';
import {strings} from '@/localization/i18n';

export const GetCompanySettingsTabs : () => Array<TTab> = () => [
    {key: 'settings', label: strings('settings'), icon: faGear, isVisible: CanAccessSettings()},
    {key: 'appearance', label: strings('appearance'), icon: faBrush, isVisible: CanAccessSettings()},
    {key: 'tags', label: strings('tags'), icon: faTag, isVisible: CanAccessTags()},
];
