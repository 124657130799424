import {UserHistoryService, UserService, VerificationService} from '@aktek/helios-frontend-sdk';
import {faEnvelope, faSnowflake, faUser} from '@fortawesome/pro-regular-svg-icons';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {TF4ViewableComponentPropsReferences} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import AskForConfirmation from '@/components/Modals/AskForConfirmation';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import AskForModal from '@/components/Modals/AskForModal';
import PasswordLinkModal, {TPasswordLinkModalProps} from '@/components/Modals/PasswordLinkModal';
import useTableRef from '@/components/Table/Hooks/useTable';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {useAppPermissions} from '@/context/UserContext/Hooks/useAppPermissions';
import {useCompanySettings} from '@/context/UserContext/Hooks/useCompanySettings';
import {useIsAccountManager, useIsRegularUser} from '@/context/UserContext/Hooks/useCurrentUser';
import {strings} from '@/localization/i18n';

import ViewPermissionModal from '../RolesAndPermissions/components/ViewPermissionModal';
import CreateUserModal from './components/CreateUserModal';
import EditAccountManagerModal from './components/EditAccountManagerModal';
import HistoryModal, {THistoryModalProps} from './components/HistoryModal';
import {USER_HISTORY_COLUMNS} from './constants/userConstants.c';
import {handleDownload} from './helpers/Users.fn';
import GetUsersColumns, {GetRightClickRowUsersActions} from './Users.columns';

export default function Users() {
    const tableRef = useTableRef();

    const {users, permissions} = useAdminPermissions();
    const {resetOtherUserPassword} = useAppPermissions();

    const isCurrentUserRegular = useIsRegularUser();
    const isCurrentUserAccountManger = useIsAccountManager();
    const canFreeze = useCompanySettings('settings_freeze_account_admin_freeze');
    const canAdminGenerateLink = useCompanySettings('settings_admin_reset_user_pass');

    const rightClickPermissions = {
        users,
        permissions,
        resetOtherUserPassword,
        canFreeze,
        canAdminGenerateLink,
        isCurrentUserAccountManger,
        isCurrentUserRegular,
    };
    const refreshTable = ()=> tableRef.refresh?.();

    const UsersOnRowAction = (references: TF4ViewableComponentPropsReferences) => async (row, action) => {
        if (action.name == 'edit') {
            const isAccountManager = row.original.isAccountManager;

            return AskForCreationModal(
                isAccountManager ? EditAccountManagerModal : CreateUserModal,
                {
                    state: {mode: 'edit', _id: row.original._id},
                    refreshTable,
                },
            );
        }

        if (action.name == 'delete') {
            return references.deleteModalRef.current.open({
                id: row.original._id,
                name: row.original.name,
                message: strings('delete_user_message'),
            });
        }

        if (!action.option) return;

        switch (action.option.id) {
            case 'freeze_account':
                AskForConfirmation(
                    `${strings('freeze')} ${row.original.name}`,
                    strings('freeze_confirmation_description'),
                    async (id)=> {
                        const res = await UserService.freezeUserAccount(id);
                        refreshTable();

                        return {isSuccessful: () => res.getData().valid};
                    },
                    'primary',
                    strings('freeze'),
                    null,
                    {
                        icon: faUser,
                        statusIcon: {
                            icon: faSnowflake,
                            className: 'bg-primary-100 rounded-full text-primary-600',
                        },
                    },
                    ()=> {},
                    row.original._id,
                    strings('freeze_success_message'),
                    strings('freeze_error_message'),
                );

                return;

            case 'unfreeze_account':
                AskForConfirmation(
                    `${strings('unfreeze')} ${row.original.name}`,
                    strings('unfreeze_confirmation_description'),
                    async (id)=> {
                        const res = await UserService.unFreezeUserAccount(id);
                        refreshTable();

                        return {isSuccessful: () => res.isSuccessful()};
                    },
                    'primary',
                    strings('unfreeze'),
                    null,
                    {
                        icon: faUser,
                    },
                    ()=> {},
                    row.original._id,
                    strings('unfreeze_success_message'),
                    strings('unfreeze_error_message'),
                );

                return;

            case 'view_history':
                return AskForModal<THistoryModalProps>(HistoryModal, {
                    id: row.original._id,
                    getDataFunction: UserHistoryService.getUserHistory,
                    columns: USER_HISTORY_COLUMNS,
                });

            case 'view_permissions':
                ls.permissionSection = 'admin';

                return AskForModal(ViewPermissionModal, {id: row.original._id});

            case 'send_verification_email':
                AskForConfirmation(
                    strings('send_verification_email'),
                    strings('send_verification_email_confirmation_description'),
                    async (id)=> {
                        const res = await VerificationService.resendVerificationEmail(id);

                        return {isSuccessful: () => res.isSuccessful()};
                    },
                    'primary',
                    null,
                    faEnvelope,
                    null,
                    ()=> {},
                    row.original._id,
                    strings('send_verification_email_success_message'),
                    strings('send_verification_email_error_message'),
                );

                return;

            case 'generate_password_link':
                const response = await UserService.obtainPasswordResetLink(row.original._id);

                if (!response.isSuccessful()) return;

                AskForModal<TPasswordLinkModalProps>(PasswordLinkModal, {
                    openOnMount: true,
                    URL: response.getData().link,
                });

                return;
        }
    };

    return (
        <F4ViewableComponent
            name={strings('user')}
            tableRef={tableRef}
            tableColumns={GetUsersColumns()}
            onRowAction={UsersOnRowAction}
            emptyProps={{addLabel: strings('new_user')}}
            modal={CreateUserModal}
            canCreate={users?.can_create}
            defaultSortedColumn="name"
            getAllData={UserService.getUsers}
            getRightClickRowActions={GetRightClickRowUsersActions(rightClickPermissions)}
            deleteSuccessMessage={strings('delete_user')}
            deleteItem={UserService.deleteUser}
            additionalLeftActions={
                {
                    download: {
                        onRibbonActionClick: handleDownload,
                        tooltip: strings('export'),
                    },
                }
            }
        />
    );
}
