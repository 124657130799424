import dayjs from 'dayjs';

import {strings} from '@/localization/i18n';

export const getMonthsOptions = () => {
    const result = {};

    for (let i = 1; i <= 12; i++) {
        result[i] = {label: strings(`month_${i-1}`)};
    }

    return result;
};

export const getYearOptions = () => {
    const result = {};
    const currentYear = dayjs().year();

    for (let i = currentYear; i >= 2022; i--) {
        result[i] = {label: i.toString()};
    }

    return result;
};
