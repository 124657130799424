import {strings} from '@/localization/i18n';

export const TransformStructure = (initialRow) => {
    const transformedStructure = Object.entries(initialRow).reduce((acc, [id, row]) => {
        if (!row.tagCondition || !row.target) {
            acc.invalidRows.push(id);

            return acc;
        }

        const transformedRow = {
            id,
            target: {isSelected: true},
            condition: {isSelected: true},
        };

        if (typeof row.tagCondition === 'string') {
            if (row.tagCondition === strings('everything')) {
                transformedRow.condition.isEverything = true;
            } else if (row.tagCondition === strings('everything_else')) {
                transformedRow.condition.isEverythingElse = true;
            }
        } else if (Array.isArray(row.tagCondition)) {
            transformedRow.condition.tags = row.tagCondition;
        }

        if (typeof row.target === 'string') {
            if (row.target === strings('everyone')) {
                transformedRow.target.isEveryone = true;
            } else if (row.target === strings('everyone_else')) {
                transformedRow.target.isEveryoneElse = true;
            }
        } else if (typeof row.target === 'object') {
            transformedRow.target.audience = {
                users: row.target.users || [],
                groups: row.target.groups || [],
                emails: [],
                fields: [],
            };
        }

        acc.transformedRows[id] = transformedRow;

        return acc;
    }, {transformedRows: {}, invalidRows: []});

    return transformedStructure;
};

export const ReverseTransformStructure = (apiStructure) => {
    const transformed = Object.entries(apiStructure).reduce((acc, [id, rule]) => {
        const row = {tagCondition: null, target: null};

        if (rule.condition?.isEverything) {
            row.tagCondition = strings('everything');
        } else if (rule.condition?.isEverythingElse) {
            row.tagCondition = strings('everything_else');
        } else if (rule.condition?.tags) {
            row.tagCondition = rule.condition.tags;
        }

        if (rule.target?.isEveryone) {
            row.target = strings('everyone');
        } else if (rule.target?.isEveryoneElse) {
            row.target = strings('everyone_else');
        } else if (rule.target?.audience) {
            row.target = {
                users: rule.target.audience.users || [],
                groups: rule.target.audience.groups || [],
            };
        }

        acc[id] = row;

        return acc;
    }, {});

    return transformed;
};
