import {Breadcrumb, Button, Dropdown, toast} from '@aktek/f4kit';
import {MorphService, SBOService} from '@aktek/morph-frontend-sdk';
import React from 'react';

import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import AskForConfirmation from '@/components/Modals/AskForConfirmation';
import {strings} from '@/localization/i18n';

function AdvancedHeader({selectedTab, selectedDataStructure, setSelectedDataStructure, SBODictionary, setSBODictionary}) {
    const fetchSBODictionary = async () => {
        const res = await SBOService.getSBODictionary(1);

        if (res.isSuccessful()) {
            const data = res.getData() as object;
            const result = {};

            Object.keys(data.dictionary).forEach((key) => {
                result[key] = {
                    key: key,
                    label: data.dictionary[key].name,
                };
            });

            setSBODictionary(result);
        }
    };

    const deleteAllData = async () => {
        const res = await MorphService.deleteAllData(selectedDataStructure);

        if (res.isSuccessful()) {
            toast.success('All data deleted');
        }
    };

    React.useEffect(() => {
        fetchSBODictionary();
    }, []);

    return (
        <div>
            <Breadcrumb
                className="!bg-neutral-50 !p-0 !pb-6"
                size="sm"
                path={[
                    {
                        onClick: () => focusOrOpenNewTab('admin'),
                        title: strings('admin'),
                    },
                    {
                        onClick: () => focusOrOpenNewTab('advanced'),
                        title: strings('advanced'),
                    },
                    {
                        onClick: () => {},
                        title: strings(selectedTab || 'restoreFields'),

                    },
                ]}
            />

            <div className="flex items-end justify-between">
                <div className="w-1/2">
                    <Dropdown
                        label="Data structure"
                        isClearable
                        value={selectedDataStructure}
                        onChange={(e) => setSelectedDataStructure(e)}
                        options={SBODictionary}
                    />
                </div>

                {selectedTab == 'deleteAllData' && (
                    <Button
                        onClick={() => {
                            AskForConfirmation(
                                strings('clear_all_data'),
                                strings('enter_data_structure_name'),
                                deleteAllData,
                                'error',
                            );
                        }}
                        isDisabled={!selectedDataStructure}
                        label="Clear All Data"
                        variant="error"
                    />
                )}
            </div>

        </div>
    );
}

export default AdvancedHeader;
