import {F4FormDiv, F4FormStep} from '@aktek/f4form';
import {TreeView} from '@aktek/f4kit';
import {FoldersService} from '@aktek/morph-frontend-sdk';
import _ from 'lodash';
import {TreeViewProps} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/TreeView/Types/TreeViewProps';
import {useEffect, useRef, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import useFilteredItems from '../hooks/useFilteredItems';

const EditMoveFolderModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, data, item, refresh, ...rest}= props;
    const [searchValue, setSearchValue] = useState('');
    const [defaultExpanded, setDefaultExpanded] = useState(false);
    const [isCaretExpanded, setIsCaretExpanded] = useState(true);
    const [folderName, setFolderName] = useState('');

    const [parentId, setParentId] = useState(undefined);

    const items = useFilteredItems(data, searchValue, true, 'folder', item.id, item.parentId);

    const ref = useRef();

    const storeFunction = async (value) => {
        const [moveResponse, editResponse] = await Promise.all([
            FoldersService.moveFolder(value.id, value.parentId),
            FoldersService.editFolder(value.id, value.label, value.iconUrl || ''),
        ]);

        await refresh?.();
        const success = moveResponse.isSuccessful?.() && editResponse.isSuccessful?.();

        return {
            isSuccessful: () => success,
            getData: () => moveResponse.getData(),
        };
    };

    useEffect(() => {
        if (searchValue !== '') {
            setIsCaretExpanded(false);
            setDefaultExpanded(true);
            setTimeout(() => {
                setIsCaretExpanded(true);
            }, 1);
        } else {
            setDefaultExpanded(false);
        }
    }, [searchValue]);

    const treeViewConditionalProps = {
        size: 'xs',
        node: null,
        defaultExpanded: defaultExpanded,
        treeState: null,
        onCheckChange: (e) => {
            const folders = Object.values(e).filter((item) => item.type === 'folder');
            const itemChecked = folders[0];

            if (!ref?.current || ref.current.getData() === null) return;

            if (itemChecked?.label === 'Folders & Data') {
                setParentId(null);
                ref.current.setValue('parentId', null);
            } else {
                setParentId(itemChecked?.id);
                ref.current.setValue('parentId', itemChecked?.id);
            }

            ref.current.setValue('id', item.id);
        },
        data: items,
        allowNodeMove: true,

        checkboxFilter: (node)=> node.type == 'folder',
        checkMode: 'single',
    } as Partial<TreeViewProps>;

    const getFunction = (finalInitialValue) => {
        return {
            isSuccessful: () => true,
            getData: () => finalInitialValue,
        };
    };

    const _folderName = _.trim(folderName);
    const isParentIdMatching = _.isEqual(parentId, item?.parentId);
    const isFolderNameMatching = _.isEqual(_folderName, item?.label);
    const isFolderNameEmpty = _.isEmpty(_folderName);
    const isParentIdUndefined = _.isUndefined(parentId);

    const isValidFolder = (
        (isParentIdMatching && (isFolderNameMatching || isFolderNameEmpty))
    || isParentIdUndefined
    );

    return (
        <F4CreatableModal
            state={state}
            title={strings('folder')}
            formKey="folder"
            ref={ref}
            hasSidebar={false}
            openOnMount={openOnMount}
            storeFunction={storeFunction}
            isDisabled={isValidFolder}
            getFunction={() => getFunction(item)}
            editSuccessMessage={strings('edit_folder_success')}
            errorMessage={strings('edit_folder_failed')}
            {...rest}
        >

            <F4FormStep autoNest>

                <F4AvatarImageSelector
                    name="iconUrl"
                    size="2xl"
                    title={strings('upload_folder_logo')}
                    showOptional={false}
                    colSpan={4}
                    f4FormDivClassName="flex justify-start items-center -mt-4 "
                />
                <F4Textbox
                    name="label"
                    label={strings('name')}
                    required
                    placeholder=""
                    onChange={(e:string) => setFolderName(e.trim())}
                    type="text"
                    colSpan={8}
                    f4FormDivClassName="flex flex-col justify-center items-start"
                />

                <F4FormDiv colSpan={12} className="-mt-4">

                    <SearchBox onSearch={setSearchValue} className="sticky elevation-5 !shadow-none -top-4 bg-white" />
                    {!!items.length && isCaretExpanded && <TreeView {...treeViewConditionalProps} />}
                    {!items?.length && <p className="flex mt-3 ml-2" >{strings('no_results_found')}</p>}
                </F4FormDiv>
            </F4FormStep>
        </F4CreatableModal>

    );
};

export default EditMoveFolderModal;
