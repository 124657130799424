import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

export const LANGUAGES:Record<TDropdownValue, TDropdownOption>= {
    'en': {label: strings('english')},
    'fr': {label: strings('french')},
    'sw': {label: strings('kiwashili')},
    'so': {label: strings('somali')},
    'es': {label: strings('spanish')},
    'ru': {label: strings('russian')},
    'ua': {label: strings('ukrainian')},
    'pt': {label: strings('portuguese')},
    'ar': {label: strings('arabic')},
};
