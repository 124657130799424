
import 'react-virtualized/styles.css';

import {faCaretDown, faCaretRight} from '@fortawesome/free-solid-svg-icons';

import {ACLPositions} from '../constants/ACLPositions';
import {Sizes} from '../constants/PermissionConstants';
import ScopesACL from '../constants/ScopesACL';
import PermissionRowCell from './PermissionRowCell';
import PermissionRowChildrenHeader from './PermissionRowChildrenHeader';
import PermissionRowName from './PermissionRowName';

const PermissionRow = ({
    permission,
    rowIndex,
    selectedSection,
    style,
    parentCategory,
    depth = 0,
    currentPath = '',
    onToggleHelperClick,
    onCheckBoxClick,
    rowExpansionDict,
    toggleRowExpansion,
    isSuperAdmin,
    setIsSuperAdmin,
    disabled,
}) => {
    const isExpanded = rowExpansionDict[permission.id];
    const expandControlIcon = isExpanded ? faCaretDown : faCaretRight;
    const hasChildren = permission?.children?.length > 0;
    const isReport = permission.category !== 'report';

    const parentACLs = ScopesACL[parentCategory]?.acls;
    const rowACLs = ScopesACL[permission.category].acls;
    const currentACLs = parentACLs ? parentACLs : rowACLs;

    const key =`${rowIndex}_${permission.id}_${permission.id}_${currentPath}`;

    const aclIndexes = permission.aclType ? String(permission.aclType).split('').map(Number) : [];
    const filteredACLs = aclIndexes.length > 0
        ? currentACLs.filter((_, index) => aclIndexes[index] === 1)
        : currentACLs;

    const rowState = getRowState(filteredACLs.length > 0 ? filteredACLs : currentACLs, permission);

    return (
        <div key={`PermissionRow_Perm_${key}`}
            style={{
                borderBottomColor: '#d9d9d9',
                marginTop: '0px',
                marginBottom: depth > 0 ? '0px' : '8px',
                display: 'block',
                ...style,
            }}
            className={`${(rowIndex % 2) ? 'bg-white':'bg-neutral-50'} border-r border-t border-neutral-200 `}>
            <div key={`Perm_Main_${key}`}
                className={`ReactVirtualized__Table__row`}
                style={{height: Sizes.rowHeight + 'px'}}
                role="row">
                <PermissionRowName
                    permission={permission}
                    selectedSection={selectedSection}
                    depth={depth}
                    disabled={disabled || parentCategory == 'app'}
                    currentPath = {currentPath}
                    onToggleHelperClick={onToggleHelperClick}
                    toggleRowExpansion={toggleRowExpansion}
                    expandControlIcon={expandControlIcon}
                    hasChildren={hasChildren}
                    rowState={rowState}

                    setIsSuperAdmin={setIsSuperAdmin}
                    isSuperAdmin={isSuperAdmin}
                />
                {currentACLs.map((ACL, index) => <PermissionRowCell
                    isSuperAdmin={isSuperAdmin}
                    setIsSuperAdmin={setIsSuperAdmin}
                    isReport={isReport}
                    permission={permission}
                    rowACLs={rowACLs}
                    parentACLs={parentACLs}
                    currentPath={currentPath}
                    disabled={disabled}
                    selectedSection={selectedSection}
                    onCheckBoxClick={onCheckBoxClick}
                    key={'acl' + index}
                    ACL={ACL}
                    index={index}
                />,
                )}
            </div>
            {hasChildren && isExpanded && (
                <>
                    <PermissionRowChildrenHeader
                        currentPath={currentPath}
                        permission={permission}
                        depth={depth}
                        disabled={disabled}

                        currentACLs={currentACLs}
                        selectedSection={selectedSection}

                        setIsSuperAdmin={setIsSuperAdmin}
                        isSuperAdmin={isSuperAdmin}
                        onToggleHelperClick={onToggleHelperClick}
                        // disabled={disabled}
                    />
                    {permission.children.map((childPermission, index) => (
                        <PermissionRow
                            key={`child_${key}_${childPermission.id} ${index}`}
                            permission={childPermission}
                            rowIndex={index}
                            selectedSection={selectedSection}
                            parentCategory={parentCategory || permission.category}
                            depth={depth + ACLPositions.can_view}
                            currentPath={`${currentPath}.children.${index}`}
                            onToggleHelperClick={onToggleHelperClick}
                            onCheckBoxClick={onCheckBoxClick}
                            disabled={disabled}
                            rowExpansionDict={rowExpansionDict}
                            toggleRowExpansion={toggleRowExpansion}

                            setIsSuperAdmin={setIsSuperAdmin}
                            isSuperAdmin={isSuperAdmin}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default PermissionRow;

const getRowState = (currentACLs, permission) => {
    let rowState;

    if (currentACLs.every((val) => permission[val] === ACLPositions.can_view)) {
        rowState = true;
    }

    if (!rowState && currentACLs.every((val) => permission[val] === ACLPositions.can_create)) {
        rowState = false;
    }

    if (rowState === undefined) {
        rowState = null;
    }

    return rowState;
};
