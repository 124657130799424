
import {Cell, Row} from '@tanstack/react-table';

import ReferenceButton from '@/components/ReferenceButton/ReferenceButton';

export const CellRefListAnnex= (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const valueList = cell.getValue();

            return <div className="flex items-center gap-1">
                {valueList?.map((value, index) => <ReferenceButton value={value || ''} key={'cell.ref.list'+value + '-' + index} />)}
            </div>;
        },
    };
};
