
import {F4FormElementDecorator} from '@aktek/f4form';
import {Button, Icon, Image, toast, TSize, usePropState} from '@aktek/f4kit';
import {faTrash, faUser} from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import {useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';

import AKTEKLogo from '@/assets/images/aktek_logo.png';
import Env from '@/config/env';
import {strings} from '@/localization/i18n';

type TF4ImageSelectorProps = {
    isDisabled?: boolean;
    size?: TSize;
    title?:string;
    value: string;
    showUserIcon?: boolean;
    maxSizeMB: number;
    defaultImage?: TImage;
    allowedTypes: Array<string>;
    onChange: (e: string) => void;
    setIsLoading: (e: boolean) => void;
    isViewMode?: boolean;
}

type TImage ={
    src: string
    alt: string
}

const DEFAULT_ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

const ALLOWED_MAGIC_NUMBERS = {
    'image/jpeg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2'],
    'image/png': ['89504e47'],
    'image/jpg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2'],
};

function F4AvatarImageSelector({
    onChange,
    value='',
    isDisabled,
    size='3xl',
    title,
    maxSizeMB=1,
    allowedTypes = DEFAULT_ALLOWED_IMAGE_TYPES,
    defaultImage={src: AKTEKLogo, alt: 'AKTEK'},
    showUserIcon,
    isViewMode = false,
}: TF4ImageSelectorProps) {
    const fileInputRef = useRef(null);

    const getImage = (id) => {
        return {
            src: Env.getImageURL(id),
            alt: title || '',
        };
    };

    const [_value, setValue] = usePropState<string>(value, '', onChange);
    const [image, setImage] = useState<TImage | null>(_value ? getImage(_value) : null);

    useEffect(()=>{
        setValue(value);
    }, [value]);

    useEffect(()=>{
        if (!_value) setImage(null);
        else setImage(getImage(_value));
    }, [_value]);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleClearImage = () => setValue('');

    const isValidFileType = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onloadend = (e) => {
                const arrayBuffer = e.target.result;
                const uint = new Uint8Array(arrayBuffer).subarray(0, 4);
                const header = uint.reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');

                const fileType = allowedTypes.find((type) => ALLOWED_MAGIC_NUMBERS[type].includes(header));
                resolve(!!fileType);
            };

            fileReader.onerror = () => reject('Failed to read file');
            fileReader.readAsArrayBuffer(file.slice(0, 4));
        });
    };

    const handleOnChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                toast.error('Invalid image type!');

                return;
            }

            const isValidType = await isValidFileType(file);

            if (!isValidType) {
                toast.error('Invalid image mime type!');

                return;
            }

            const fileSizeMB = file.size / 1024 / 1024; // Convert size from bytes to MB

            if (fileSizeMB > maxSizeMB) {
                toast.error(`The image size should not exceed  ${maxSizeMB}MB`);

                return;
            }

            const options = {
                headers: {
                    'Content-Type': file.type,
                },
            };
            const myUuid = uuid();

            const url = Env.getImageURL(myUuid);

            return new Promise((resolve, reject) => {
                axios.put(url, file, options)
                    .then((result) => {
                        setImage({src: myUuid});

                        onChange(myUuid);
                        toast.success(`Image uploaded successfully`);
                        resolve(result);
                    })
                    .catch((err) => {
                        toast.error(err);
                        reject(err);
                    });
            });
        }
    };

    return <>
        <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleOnChange}
        />

        <div className="w-full flex flex-wrap justify-start items-center gap-2">
            <div className="border-2 border-neutral-200 flex justify-center items-center !w-[6.5rem] !h-[6.5rem] rounded-xl relative overflow-hidden">
                {(!image && showUserIcon) && <Icon icon={faUser} size="5xl" />}
                {!image && !showUserIcon && <Image
                    alt={defaultImage?.alt}
                    src={defaultImage?.src}
                    className="rounded-lg !w-full !h-full"
                    // className="rounded-lg !w-full !h-full !object-cover"
                />}
                {image && <Image
                    alt={image?.alt}
                    src={image?.src}
                    className="rounded-lg !w-full !h-full"
                    // className="rounded-lg !w-full !h-full !object-cover"
                />}
            </div>
            <div className="flex justify-center items-center gap-2">
                <Button
                    label={value ? strings('replace_image') : strings('add_image')}
                    onClick={handleImageClick}
                    isDisabled={isDisabled || isViewMode}
                    variant="white"/>
                {value
                 && <Button isGhost
                     icon={faTrash}
                     onClick={handleClearImage}
                     isDisabled={isDisabled || isViewMode}
                     className="!text-error-700 hover:!bg-error-300"/>}
            </div>
        </div>
    </>;
}

export default F4FormElementDecorator<TF4ImageSelectorProps>(F4AvatarImageSelector);
