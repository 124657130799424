import {createContext, useContext} from 'react';

export type TF4CreatableFormContext = {
    extra?: Record<string, unknown>;
}

type F4CreatableFormContextProps = TF4CreatableFormContext & {
    children: React.ReactNode;
}

// Create a context object
const F4CreatableFormContext = createContext<TF4CreatableFormContext>(null);

export function useF4CreatableFormContext() {
    return useContext(F4CreatableFormContext) || {};
}

// This component will make the loader available down the component tree
export function F4CreatableFormContextProvider({children, ...rest}: F4CreatableFormContextProps) {
    return <F4CreatableFormContext.Provider value={ rest }>
        { children }
    </F4CreatableFormContext.Provider>;
}
