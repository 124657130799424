import {Navigate, Route, Routes} from 'react-router-dom';

import SetPassword from '@/app/Authentication/pages/ForgetPassword/components/SetPassword';
import ForgotPassword from '@/app/Authentication/pages/ForgetPassword/ForgotPassword';
import Login from '@/app/Authentication/pages/Login/Login';
import Home from '@/app/Home/Home';

import Path from './Path';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<PrivateRoutes />} >
                <Route path={Path.Root} element={<Home />} />
                <Route path="*" element={<Navigate to={Path.Root} replace/>} />
            </Route>

            <Route element={<PublicRoutes />} >
                <Route path={Path.Login} element={<Login />} />
                <Route path="*" element={<Navigate to={Path.Login} replace/>} />
            </Route>
            <Route path={Path.VerifyURL} element={<SetPassword />} />
            <Route path={Path.ResetPassword} element={<SetPassword />} />
            <Route path={Path.requestResetPassword} element={<ForgotPassword />} />
        </Routes>
    );
};

export default AppRoutes;
