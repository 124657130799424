import {F4FormElementDecorator} from '@aktek/f4form';
import {PhoneNumber, TPhone, TPhoneNumberProps} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const F4PhoneNumber = F4FormElementDecorator<TPhoneNumberProps>(PhoneNumber, (value : TPhone)=>{
    const messages =[];

    if (!value?.countryCode && !value?.isoCode && !value?.number) return {isValid: true};

    if (value.countryCode && value.isoCode && value.number) {
        if (value.number.toString().length === COUNTRY_PHONE_LENGTH[value.isoCode]) return {isValid: true};

        messages.push(`${strings('phone_number_length_error')} ${COUNTRY_PHONE_LENGTH[value.isoCode]}`);
    }

    if (!value?.countryCode || !value?.isoCode) messages.push(strings('choose_country_code'));

    if (!value?.number) messages.push(strings('enter_phone_number'));

    return {isValid: messages.length === 0, messages};
});

export default F4PhoneNumber;

const COUNTRY_PHONE_LENGTH = {
    AD: 6, AE: 9, AF: 9,
    AG: 10, AI: 10, AL: 9,
    AM: 8, AO: 9, AQ: 6, AR: 10,
    AS: 7, AT: 10, AU: 9,
    AW: 7, AX: 9, AZ: 9,
    BA: 8, BB: 10, BD: 10,
    BE: 9, BF: 8, BG: 9,
    BH: 8, BI: 8, BJ: 8,
    BL: 9, BM: 10, BN: 7,
    BO: 8, BQ: 10, BR: 11,
    BS: 10, BT: 8, BV: 9,
    BW: 9, BY: 9, BZ: 7,
    CA: 10, CC: 6, CD: 9,
    CF: 8, CG: 9, CH: 9,
    CI: 10, CK: 8, CL: 9,
    CM: 9, CN: 11, CO: 10,
    CR: 8, CU: 9, CV: 8,
    CW: 9, CX: 6, CY: 8,
    CZ: 9, DE: 10, DJ: 6,
    DK: 8, DM: 10, DO: 10,
    DZ: 9, EC: 9, EE: 8,
    EG: 10, EH: 9, ER: 8,
    ES: 9, ET: 9, FI: 10,
    FJ: 7, FM: 7, FO: 6,
    FR: 9, GA: 8, GB: 10,
    GD: 10, GE: 9, GF: 9,
    GG: 10, GH: 9, GI: 8,
    GL: 8, GM: 9, GN: 9,
    GP: 9, GQ: 9, GR: 10,
    GT: 8, GU: 10, GW: 7,
    GY: 9, HK: 8, HM: 6,
    HN: 8, HR: 9, HT: 8,
    HU: 9, ID: 9, IE: 9,
    IL: 9, IM: 10, IN: 10,
    IO: 9, IQ: 10, IR: 10,
    IS: 7, IT: 9, JE: 10,
    JM: 10, JO: 9, JP: 10,
    KE: 9, KG: 9, KH: 9,
    KI: 7, KM: 8, KN: 10,
    KP: 10, KR: 10, KW: 8,
    KY: 10, KZ: 9, LA: 9,
    LB: 8, LC: 10, LI: 7,
    LK: 10, LR: 8, LS: 8,
    LT: 8, LU: 9, LV: 8,
    LY: 9, MA: 9, MC: 8,
    MD: 8, ME: 8, MF: 9,
    MG: 9, MH: 7, MK: 8,
    ML: 8, MM: 9, MN: 9,
    MO: 8, MP: 10, MQ: 9,
    MR: 9, MS: 10, MT: 8,
    MU: 8, MV: 8, MW: 9,
    MX: 10, MY: 9, MZ: 9,
    NA: 9, NC: 9, NE: 8,
    NF: 6, NG: 9, NI: 8,
    NL: 9, NO: 8, NP: 10,
    NR: 7, NU: 8, NZ: 9,
    OM: 8, PA: 8, PE: 9,
    PF: 9, PG: 8, PH: 10,
    PK: 10, PL: 9, PM: 9,
    PN: 6, PR: 10, PT: 9,
    PW: 7, PY: 9, QA: 8,
    RE: 9, RO: 9, RS: 8,
    RU: 10, RW: 9, SA: 9,
    SB: 7, SC: 7, SD: 9,
    SE: 10, SG: 8, SH: 7,
    SI: 8, SJ: 6, SK: 9,
    SL: 8, SM: 8, SN: 9,
    SO: 8, SR: 7, SS: 9,
    ST: 9, SV: 8, SX: 10,
    SY: 9, SZ: 9, TC: 10,
    TD: 8, TF: 9, TG: 8,
    TH: 9, TJ: 9, TK: 9,
    TL: 8, TM: 9, TN: 8,
    TO: 8, TR: 10, TT: 10,
    TV: 8, TW: 9, TZ: 9,
    UA: 9, UG: 9, US: 10,
    UY: 9, UZ: 9, VA: 8,
    VC: 10, VE: 10, VG: 10,
    VI: 10, VN: 9, VU: 7,
    WF: 6, WS: 7, YE: 9,
    YT: 9, ZA: 9, ZM: 9,
    ZW: 9,
};
