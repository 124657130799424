
export const GetRestoreFieldsTableColumns = (render) => {
    return [
        {
            key: 'type',
            header: 'Type',
            type: 'string',
        },
        {
            key: 'name.def',
            header: 'Deleted Field',
            type: 'string',
        },
        {
            key: 'dt',
            header: 'Deletion Date',
            type: 'string',
        },
        {
            key: 'restoreFieldsAction',
            header: '',
            type: 'custom',
            render: render,
        },
    ];
};
