import {CompanyTypes} from '@/constants/CompanyTypes.c';

import {useUserContext} from '../Provider';

export function useCurrentCompany() {
    const {userAuth} = useUserContext();

    return userAuth.currentCompany;
}

export function useCurrentCompanyType() {
    const currentCompany = useCurrentCompany();

    return currentCompany?.companyType;
}

export function useIsCompanyOPS() {
    const currentCompanyType = useCurrentCompanyType();

    return currentCompanyType == CompanyTypes.OPS;
}

export function useIsCompany() {
    const currentCompanyType = useCurrentCompanyType();

    return currentCompanyType == CompanyTypes.Company;
}
