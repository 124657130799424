import {F4FormDiv, F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label, TDropdownOption, TDropdownValue} from '@aktek/f4kit';
import Horae from '@aktek/horae';

import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {CanEditSettings} from '@/constants/Permissions.c';
import {strings} from '@/localization/i18n';

import {DATE_FORMAT} from '../constants/Settings.c';

export default function TimezoneSettings() {
    const timezoneOption = Horae.getTimeZonesOptions();
    const transformedObject: Record<TDropdownValue, TDropdownOption> = {};

    timezoneOption.forEach((element) => {
        transformedObject[element.value] = element.label;
    });

    return (
        <F4Nestor name="timezone">

            <F4FormDiv colSpan={12} className="mt-8">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('timezone')}
                            className="text-neutral-600 font-medium"/>}/>
            </F4FormDiv>

            <F4Dropdown
                name="settings_timezone"
                colSpan={3}
                required
                isDisabled={!CanEditSettings()}
                label={strings('timezone')}
                options={transformedObject}
                className="flex justify-start items-start flex-col z-[20]"/>

            <F4Dropdown
                name="settings_date_format"
                colSpan={3}
                required
                isDisabled={!CanEditSettings()}
                label={strings('date_format')}
                options={DATE_FORMAT}
                className="flex justify-start items-start flex-col mb-8" />

            <F4ToggleSwitch
                name="settings_is_date_input_timezone"
                colSpan={3}
                label=""
                size="md"
                isDisabled={!CanEditSettings()}
                descriptionClassName="!text-neutral-700 !font-medium"
                description={strings('tie_date_and_time_to_company_timezone')}
                showOptional={false}
            />

        </F4Nestor>
    );
}
