import {Label} from '@aktek/f4kit';

export type TProps = {
    children: React.ReactNode | React.ReactNode[],
    label: string
}

const NavigationSection = ({children, label}: TProps): React.ReactElement => {
    return <section className={'flex flex-col items-start mb-8'}>
        <Label className="mb-4" text={label}
            fontWeight="semibold" color="neutral-500" size="sm" />
        <div className="flex flex-wrap gap-3">
            {children}
        </div>
    </section>;
};

export default NavigationSection;
