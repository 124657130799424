
import {Datetime} from '@aktek/f4kit';
import {Cell, Row} from '@tanstack/react-table';

export const CellDate = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => (
            <div className="flex items-center gap-1">
                <Datetime date value={parseInt(row.getValue(cell.column.id))} />
            </div>),
    };
};
