import {useCallback, useState} from 'react';

export function useDate(value:number, isOpened:boolean, setIsOpened:React.Dispatch<React.SetStateAction<boolean>>,
    onClickHandler?:(timestamp:number) => void, initialYear: number = new Date().getFullYear(), initialMonth: number = new Date().getMonth()) {
    const [selectedDate, setSelectedDate] = useState<number>(value);
    const [month, setMonth] = useState<number>(initialMonth);
    const [year, setYear] = useState<number>(initialYear);

    const goToNextMonth = useCallback(() => {
        setMonth((prevMonth) => (prevMonth + 1) % 12);

        if (month === 11) {
            setYear((prevYear) => prevYear + 1);
        }
    }, [month]);

    const goToPreviousMonth = useCallback(() => {
        setMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));

        if (month === 0) {
            setYear((prevYear) => prevYear - 1);
        }
    }, [month]);

    const goToNextYear = useCallback(() => {
        setYear((prevYear) => prevYear + 1);
    }, []);

    const goToPreviousYear = useCallback(() => {
        setYear((prevYear) => prevYear - 1);
    }, []);

    const handleDayClick = (timestamp: number) => {
        setSelectedDate(timestamp);
        setIsOpened(!isOpened);
        onClickHandler?.(timestamp);
    };

    return {selectedDate, setSelectedDate, month, year, goToNextMonth, goToPreviousMonth,
        goToNextYear, goToPreviousYear, handleDayClick, isOpened, setIsOpened, setMonth, setYear};
}

