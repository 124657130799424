
import {Button, ContextMenu, Label, PopoverDropdown, Tooltip, TPopoverDropdownListItemProps} from '@aktek/f4kit';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {useRef} from 'react';

import ActionsInformation from '../Constants/ActionsInformation';
import {TActionButtonProps} from '../Types/ActionButton.t';

const ActionButton : React.FC<TActionButtonProps> = ({onClick, row, options, size, name, tooltip, variant, isGhost,
    isOutlined, icon, textColor, contextMenuItems, noMargin, className, contextMenuRef}) => {
    const Action = ActionsInformation[name] || {};

    const _tooltip = tooltip || Action.tooltip;
    const _isFilled = isOutlined === false || Action.isOutlined === false;

    const buttonRef = useRef();
    const button = <Button
        onClick={onClick && ((event) => onClick(event))}
        className={className}
        ref={buttonRef}
        key={0}
        size={size}
        isOutlined={!_isFilled}
        isGhost={isGhost}
        variant={variant || Action?.variant || 'white'}
        textColor={textColor || Action?.textColor}
        icon={icon || Action.icon || faPlus}
    />;

    if (contextMenuItems) {
        return <ContextMenu
            contextMenuRef={contextMenuRef}
            trigger="click"
            items={contextMenuItems}
            onOpen={() => {

            }}>
            {button}
        </ContextMenu>;
    }

    if (options) {
        const _options : TPopoverDropdownListItemProps = Object.entries(options || {})
            .filter(([id, option]) => !option?.hide)
            .sort(([id1, option1], [id2, option2]) => option1.order < option2.order ? 1 : -1)
            .map(([id, _option])=>{
                const option = {..._option, id};

                return {
                    ...option,
                    onClick: (event)=> {
                        option.onClick?.(row);
                        onClick?.(event, {option});
                    },
                };
            });

        return <PopoverDropdown component={button} options={_options} size={size}/>;
    }

    if (!_tooltip) {
        return button;
    }

    return <Tooltip
        placement="top"
        showDelay={1000}
        content={
            <div className="flex justify-center items-center px-1 py-0.5">
                <Label text={_tooltip} size="xs"/>
            </div>
        }
    >
        {button}
    </Tooltip>;
};

export default ActionButton;
