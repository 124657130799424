import './BuildingBlocks/CSS/animatedNetwork.css';

import CanvasAnimatedNetwork from './BuildingBlocks/AnimatedNetwork.canvas';

/**
 * As simple as calling the static function {@link animate}
 */
export default class AnimatedNetwork {

    /**
    *
    * @param {String} className Classname of the object to apply the animation to
    * @param {Boolean} startWithDefaults If passed true, the network will start with a default number of nodes
    * @param {Number} defaultNodeCount if startsWithDefaults is true, then defaultNodeCount will indicate the initial nodes at the start of the animation. Default=35
    * @param {Object} options Option object. See {@link CanvasAnimatedNetwork} for parameters
    * @return {AnimatedNetwork} An instance of an animated network
    */
    static animate(className, startWithDefaults = false, defaultNodeCount = 35, options?) {
        const animatedNetwork = new AnimatedNetwork();
        const matchingElements = document.getElementsByClassName(className);

        if (matchingElements.length > 0) {
            animatedNetwork.init(matchingElements[0], startWithDefaults, defaultNodeCount, options);
        }

        return animatedNetwork;
    }

    public static cleanup() {
        while (AnimatedNetwork.container?.firstChild) {
            AnimatedNetwork.container.removeChild(AnimatedNetwork.container.firstChild);
        }
    }

    /**
     * If you like what you see, call this function and it will print in JSON format the current network configuration
     * @return {null} nothing
     */
    printNodes = () => {
        return this?.network?.printNodes();
    };

    /**
     *
     * @param {HTMLElement} element Any HTML element on which to apply the animation to
     * @param {Boolean} startWithDefaults If passed true, the network will start with a default number of nodes
     * @param {Number} defaultNodeCount if startsWithDefaults is true, then defaultNodeCount will indicate the initial nodes at the start of the animation. Default=35
     * @param {Object} options Option object. See AnimatedNetwork.canvas for parameters
     * @return {Object} this
     */
    init = function(element, startWithDefaults, defaultNodeCount, options) {
        AnimatedNetwork.container = element;
        this.container = element;
        this.canvas = document.createElement('canvas');
        this.sizeCanvas();
        this.container.appendChild(this.canvas);
        this.ctx = this.canvas.getContext('2d');
        this.network = new CanvasAnimatedNetwork(this, startWithDefaults, defaultNodeCount, options);

        this.bindUiActions();

        return this;
    };

    bindUiActions = function() {
        window.addEventListener('resize', function() {
            // this.sizeContainer();
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.sizeCanvas();
            this.network.createNodes();
        }.bind(this));
    };

    sizeCanvas = function() {
        this.canvas.width = this.container?.getBoundingClientRect?.()?.width;// this.container.offsetWidth;
        this.canvas.height = this.container?.getBoundingClientRect?.()?.height;// this.container.offsetHeight;
    };

}

