import cn from 'classnames';

const hoverEffect = 'transition-transform duration-200 ease-in-out transform hover:-translate-y-1';
const card =cn('flex flex-col items-center justify-center gap-4 p-4 w-72 min-w-80 cursor-pointer rounded-2xl  hover:!shadow-none');
const cardHeader = 'flex flex-row items-center justify-start gap-2 w-full p-4 h-14 bg-neutral-50 rounded-lg';
const iconContainer = 'w-6 h-6 p-4 flex items-center justify-center rounded-2xl';

const badgeContainer = 'flex flex-wrap w-full items-center justify-start gap-2 ';

const badgeClassName = cn('px-2 cursor-pointer hover:bg-neutral-200');

export const NavigationButtonClasses = () => ({
    card,
    cardHeader,
    iconContainer,
    badgeContainer,
    badgeClassName,
});
