
import {
    faArrowUpFromBracket, faClone, faCloudArrowDown,
    faCopy, faListOl, faPaste,
    faPen, faRefresh, faTimer,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faClose, faEllipsisVertical, faEnvelope,
    faFilter, faHistory, faKey,
    faPlus, faSearch, faSnowflake,
    faUserUnlock,
} from '@fortawesome/pro-regular-svg-icons';

import {TAction} from '../Types/ActionButton.t';
import {TActionsNames} from '../Types/ActionsNames.t';

const Actions: Record<TActionsNames, TAction>= {
    add: {
        variant: 'primary',
        isOutlined: false,
        // textColor: 'white',
        icon: faPlus,
    },
    refresh: {
        icon: faRefresh,
    },
    upload: {
        icon: faArrowUpFromBracket,
    },
    download: {
        icon: faCloudArrowDown,
    },
    edit: {
        icon: faPen,
    },
    copy: {
        icon: faCopy,
    },
    duplicate: {
        icon: faClone,
    },
    paste: {
        icon: faPaste,
    },
    delete: {
        icon: faTrash,
    },
    scheduler: {
        icon: faTimer,
    },
    list: {
        icon: faListOl,
    },
    ellipsis: {
        icon: faEllipsisVertical,
    },
    search: {
        icon: faSearch,
    },
    filter: {
        icon: faFilter,
    },
    history: {
        icon: faHistory,
    },
    close: {
        icon: faClose,
    },
    freeze: {
        icon: faSnowflake,
    },
    activate: {
        icon: faUserUnlock,
    },
    envelope: {
        icon: faEnvelope,
    },
    key: {
        icon: faKey,
    },

};
export default Actions;
