import {Label} from '@aktek/f4kit';
import {UsageService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import UsageMetricViewer from './UsageMetricViewer';

function OverallUsage({metrics, license}) {
    const [ownMetrics, setOwnMetrics] = useState([]);

    const getUsageMetrics = async () =>{
        const res = await UsageService.getOwnUsage();

        if (res.isSuccessful()) {
            const ownMetrics = res.getData();
            setOwnMetrics(ownMetrics);
        }
    };

    useEffect(()=>{
        getUsageMetrics();
    }, []);

    return (
        <div className=" bg-white p-4 border border-neutral-200 rounded-xl overflow-scroll">
            <div className="mb-4">
                <Label text="Overall Usage" className="text-2xl" fontWeight="semibold"/>
            </div>

            <UsageMetricViewer metrics={metrics} license={license} ownMetrics={ownMetrics} showOnlyRolling={false}/>
        </div>
    );
}

export default OverallUsage;
