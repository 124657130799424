import {Icon} from '@aktek/f4kit';
import {faAdd, faPen, faTrash} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import CreateUserModal from '@/app/Admin/AccessControl/pages/Users/components/CreateUserModal';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {TColumn, TGetRightClickRowActions} from '@/components/Table/Types/Table.t';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import CreateGroupModal from './components/CreateGroupModal';

const GROUP_COLUMNS = (): Array<TColumn> => {
    const {groups} = useAdminPermissions();

    return [
        {key: 'name', header: strings('group'), type: 'string', isSortable: true},
        {
            key: 'users',
            header: strings('users'),
            type: 'custom',
            render: () => (
                <div>
                    {groups?.can_edit && <Icon
                        icon={faAdd}
                        className="border border-neutral-300 p-1.5 rounded-full border-dashed bg-white"
                        color="neutral-900"
                        size="md"
                        tooltip={strings('add_user')}
                        onClick={() => AskForCreationModal(CreateUserModal, {state: {mode: 'create', _id: null}})}
                    />}
                </div>
            ),
        },
        {
            key: 'groupActions',
            header: '',
            type: 'actions',
            actions: {
                delete: {
                    tooltip: strings('delete'),
                    isVisible: groups?.can_delete,
                },
                edit: {
                    tooltip: strings('edit'),
                    isVisible: groups?.can_edit,
                },
            },
        },
    ];
};

export default GROUP_COLUMNS;

export const GetRightClickRowGroupsActions: TGetRightClickRowActions = (permission) => (refreshTable, deleteModalRef) => (row) => {
    return _compact([
        permission?.can_delete && {
            label: strings('delete'),
            borderButton: true,
            icon: faTrash,
            onClick: () => deleteModalRef.current.open({id: row.original._id, name: row.original.name, message: strings('delete_group_message')}),
        },
        permission?.can_edit && {
            label: strings('edit'),
            icon: faPen,
            onClick: () => AskForCreationModal(CreateGroupModal, {state: {mode: 'edit', _id: row.original._id}, refreshTable}),
        },
    ]);
};
