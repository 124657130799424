import {FitHeight} from '@aktek/f4kit';
import {CompanyLicenseService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import MonthlyUsage from './components/MonthlyUsage';
import OverallUsage from './components/OverallUsage';
import METRICS from './constants/Metrics';

function UsageMetrics() {
    const [license, setLicense] = useState([]);

    const getLicense = async () =>{
        const res = await CompanyLicenseService.getOwnCompanyLicense();

        if (res.isSuccessful()) {
            setLicense(res.getData());
        }
    };

    useEffect(()=>{
        getLicense();
    }, []);

    return (
        <FitHeight >
            <div className="bg-neutral-50 px-4 py-6">
                <MonthlyUsage metrics={METRICS} license={license}/>
                <OverallUsage metrics={METRICS} license={license}/>
            </div>
        </FitHeight>
    );
}

export default UsageMetrics;
