import {useF4FormRef} from '@aktek/f4form';
import {TDropdownOption, TDropdownValue, toast} from '@aktek/f4kit';
import {CompanyLicenseService, LicensePlanService, UserService} from '@aktek/helios-frontend-sdk';
import {useState} from 'react';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';

const useAgency = () => {
    const createCompanyFormRef = useF4FormRef();

    const [listAccountManagers, setListAccountManagers] = useState<Array<Record<TDropdownValue, TDropdownOption>>>([]);
    const [dropdownOptions, setDropdownOptions] = useState();
    const {extra} = useF4CreatableFormContext();
    const ref = extra.ref;

    const transformDataToOptions = (data, keyField, valueField) => {
        return data.reduce((acc, item) => {
            acc[item[keyField]] = item[valueField];

            return acc;
        }, {});
    };

    const getLicenses = async (search, sort, skip, limit) => {
        const response = await LicensePlanService.getAllLicensePlans(search, sort, skip, limit);
        const success = response.isSuccessful?.();
        success && setDropdownOptions(transformDataToOptions(response?.Body, '_id', 'plan'));

        return response?.Body?.data;
    };

    const getAllAccountManagers = async () => {
        const response = await UserService.getAllAccountManagers();
        const success = response.isSuccessful?.();

        success && setListAccountManagers(response?.Body as Array<Record<TDropdownValue, TDropdownOption>>);

        return response?.Body;
    };

    const revokeFunction = async (id, license) => {
        const finalLicense = {
            ...license,
            validity: -1,
            isNewLicense: true,
        };
        const response = await CompanyLicenseService.setLicenseProgram(id, finalLicense);
        const success = response.isSuccessful?.();

        success ? toast.success?.('License Updated Successfully!') : toast.error?.('Failed to update license!');
        ref?.current?.close();
    };

    return {revokeFunction,
        createCompanyFormRef, dropdownOptions, getLicenses,
        getAllAccountManagers, listAccountManagers};
};

export default useAgency;
