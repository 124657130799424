
import {Button, Modal, TSize, useModalRef} from '@aktek/f4kit';
import cn from 'classnames';
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';

import {strings} from '@/localization/i18n';

import F4CreatableForm from '../F4CreatableForm/F4CreatableForm';
import useF4CreatableFormRef from '../F4CreatableForm/hooks/useF4CreatableFormRef';
import AskForCloseWindowModal from '../Modals/AskForCloseWindowModal';
import {F4CreatableModalProps} from './types/F4CreatableModalProps.t';

function F4CreatableModal({
    state={mode: 'create', actionTitle: 'Create'},
    openOnMount,
    title,
    getFunction,
    storeFunction,
    onDataLoad,
    onPreSave,
    buttons,
    className,
    sideBarExtraElement,
    sideBarExtraElementPosition,
    modalProps = {},
    extra,
    onPreGetItem,
    steps,
    children,
    createSuccessMessage = strings('success'),
    editSuccessMessage = strings('success'),
    errorMessage = strings('failed'),
    closeOnSave = true,
    onSave,
    defaultStep,
    onClose,
    onSetValue,
    childrenClassName,
    isDisabled = false,
    refreshTable,
}: F4CreatableModalProps, ref) {
    const [currentStep, setCurrentStep] = useState(0);
    const [internalState, setInternalState] = useState<F4CreatableModalProps['state']>(state);

    const [modalSize, setModalSize] = useState<TSize>('md');

    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const {mode, _id, actionTitle} = internalState;

    const creatableFormRef = useF4CreatableFormRef();
    const modalRef = useModalRef();

    useEffect(()=>{
        if (window.innerWidth<1024) setModalSize('xl');
        if (window.innerWidth>=1024) setModalSize('md');
    }, [window.innerWidth]);

    useImperativeHandle(ref, () => ({
        open: (state: F4CreatableModalProps['state']) => {
            if (!!state) setInternalState(state);

            modalRef.current?.open?.();
        },
        close: () => modalRef.current?.close?.(),

        setValue: (key, value) => creatableFormRef?.current?.setValue(key, value),
        getData: () =>{
            return creatableFormRef?.current?.getData();
        },
    }), []);

    const handleBackClick = useCallback(() => {
        const closeModal = () => modalRef.current?.close?.();
        const askForConfirmation = () => AskForCloseWindowModal(closeModal);
        const canAskForConfirmation = isEdited && !isDisabled;

        if (mode === 'view' || (mode === 'edit' && !isEdited)) {
            closeModal();

            return;
        }

        if (mode === 'edit' && canAskForConfirmation) {
            askForConfirmation();

            return;
        }

        if (currentStep <= 0) {
            if (canAskForConfirmation || (mode === 'create' && canAskForConfirmation)) {
                askForConfirmation();

                return;
            }

            closeModal();

            return;
        }

        setCurrentStep(currentStep - 1);
        creatableFormRef.current.goToPreviousStep();
    }, [currentStep, modalRef, creatableFormRef, isEdited, isDisabled, mode]);

    const _OnClose = () => {
        if (isEdited && !isDisabled) {
            AskForCloseWindowModal(
                ()=>modalRef.current?.close?.(),
            );

            return;
        }

        onClose?.();
        modalRef.current?.close();
    };

    const handleNextClick = useCallback(() => {
        creatableFormRef.current.goToNextStep();
    }, []);

    const _OnSetValue = (key: string, value: unknown) => {
        if (!isEdited) setIsEdited(true);

        onSetValue?.(key, value);
    };

    const getButtonText = () => {
        if (mode == 'create') return strings('create');
        if (mode == 'edit') return strings('save');

        return strings('cancel');
    };

    const getTitlePrefix = () => {
        if (actionTitle) return actionTitle;
        if (mode === 'create') return strings('create');
        if (mode === 'view') return strings('view');

        return strings('edit');
    };

    const modalTitle = `${getTitlePrefix()} ${title}`;

    return (
        <Modal
            size={modalSize}
            className={cn('overflow-hidden !rounded-3xl h-full ', className)}
            id={_id}
            title={modalTitle}
            defaultIsOpened={openOnMount}
            headerClassName={cn('border-b border-b-neutral-100 pr-2 pl-6 pb-1 pt-2  text-xl', modalProps.headerClassName)}
            ref={modalRef}
            isControlled
            onClose={_OnClose}
            {...modalProps}
        >
            <F4CreatableForm
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                className="h-full overflow-auto pl-2 pr-1"
                _id={_id}
                childrenClassName={childrenClassName}
                onPreGetItem={onPreGetItem}
                onPreSave={onPreSave}
                onSetValue={_OnSetValue}
                extra={extra}
                defaultStep={defaultStep}
                onSave={()=> {
                    if (closeOnSave) {
                        modalRef.current.close();
                    }

                    onSave?.();
                    refreshTable?.();
                }}
                onStepChange={setCurrentStep}
                mode={mode}
                sideBarExtraElement={sideBarExtraElement}
                sideBarExtraElementPosition={sideBarExtraElementPosition}
                createSuccessMessage={createSuccessMessage}
                editSuccessMessage={editSuccessMessage}
                errorMessage={errorMessage}
                formKey="roles"
                ref={creatableFormRef}
                getFunction={getFunction}
                storeFunction={storeFunction}
                onDataLoad={onDataLoad}
                steps={steps}
            >
                {children}
            </F4CreatableForm>

            <div className="flex justify-end p-6 gap-3 border-t border-neutral-100">
                {buttons ? buttons
                    : <>
                        <Button
                            size="sm"
                            isGhost={mode != 'view'}
                            label={
                                mode === 'view'
                                    ? strings('close')
                                    : (currentStep === 0 || !steps || mode === 'edit')
                                        ? strings('cancel')
                                        : strings('back')
                            }
                            onClick={handleBackClick}
                            isDisabled={isLoading}
                        />

                        {mode == 'create' && (
                            <Button
                                size="sm"
                                label={(!steps?.length || currentStep === steps.length - 1) ? getButtonText() : strings('next')}
                                isDisabled={isDisabled || isLoading}
                                onClick={handleNextClick}

                            />
                        )}

                        {mode == 'edit' && (
                            <Button
                                size="sm"
                                label={strings('save')}
                                onClick={()=>{
                                    creatableFormRef.current.saveForm();
                                }}
                                isDisabled={isDisabled || isLoading}
                            />
                        )}
                    </>

                }
            </div>
        </Modal>
    );
}

export default forwardRef(F4CreatableModal);
