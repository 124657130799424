import {RolePermissionsService, RolesService, UserRolesService, UserRolesv4Service} from '@aktek/helios-frontend-sdk';
import _omit from 'lodash/omit';
import {useState} from 'react';

const useRoles = () => {
    const [roleId, setRoleId] = useState();
    const [initialIsSuperAdmin, setInitialIsSuperAdmin] = useState();

    const storeRole = async (role) => {
        const response = await RolesService.storeRole(_omit(role, ['permissions', 'users']));

        if (!response.isSuccessful?.()) {
            return {isSuccessful: () => false, getData: () => {}};
        }

        const permissionsData = role.permissions || [];
        const usersData = role.users || [];

        if (role.permissions) {
            await RolePermissionsService.storeRolePermissions(role.id || response.Body.id, permissionsData);
        }

        if (role.users) {
            await UserRolesv4Service.assignUsersToRole(usersData, role.id || response.Body.id );
        }

        return response;
    };

    const getRoles = async (roleId) => {
        setRoleId(roleId);
        const response = await RolesService.getRoleByID(roleId);
        const usersResponse = await UserRolesService.getUsersByRole(roleId);

        if (!response.isSuccessful?.() || !usersResponse.isSuccessful()) {
            return {isSuccessful: () => false, getData: () => {}};
        }

        const data = response.getData();
        const finalInitialValue = {
            ...data as object,
            users: usersResponse.getData(),
        };

        setInitialIsSuperAdmin(finalInitialValue?.overridePermissions);

        return {
            isSuccessful: () => true,
            getData: () => finalInitialValue,
        };
    };

    return {getRoles, storeRole, roleId, initialIsSuperAdmin};
};

export default useRoles;
