import {UserRolesService} from '@aktek/helios-frontend-sdk';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import {strings} from '@/localization/i18n';

import {UserRoles} from './UserRoles';

const EditAccountManagerModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, ...rest}= props;

    return <F4CreatableModal
        state={state}
        openOnMount={openOnMount}
        title={strings('user')}
        formKey="users"
        sideBarExtraElement={
            <F4AvatarImageSelector
                name="profilePicURL"
                title={strings('company_logo')}
                wrapper={{className: 'mt-4'}}
                size="3xl"
                showUserIcon
                showOptional={false}
                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                maxSizeMB={1}
            />
        }
        extra={{state}}
        getFunction={UserRolesService.getUserRoles}
        onDataLoad={(data : object)=>{
            return {roles: data};
        }}
        onPreSave={(item)=> {
            return item?.roles;
        }}
        storeFunction={(...args) => {
            const [roles, mode, _id] = args;

            return UserRolesService.assignRolesToUser(roles, _id);
        }}
        createSuccessMessage={strings('user_created_success')}
        errorMessage={strings('user_created_failed')}
        editSuccessMessage={strings('edit_success_message')}
        steps={[
            {key: 'userRoles', label: strings('user_roles')},
        ]}
        {...rest}
    >
        <UserRoles />
    </F4CreatableModal>;
};

export default EditAccountManagerModal;
