
import {Checkbox} from '@aktek/f4kit';
import {Table as ITable} from '@tanstack/react-table';

export const setUpSelectableColumnCell = (column, columnDef) => {
    column.header= ({table}: {table: ITable<unknown>}) => {
        return <div className="flex items-center">
            <Checkbox
                value={table.getIsAllRowsSelected()}
                isIndeterminate={(!table.getIsAllRowsSelected() && table.getIsSomeRowsSelected())}
                onChange={table.toggleAllRowsSelected}
                size="xs"
            />
            {columnDef.header || columnDef.key}
        </div>;
    };

    column.cell= ({cell, row}) => (
        <div className="flex items-center">
            <Checkbox
                value={row.getIsSelected()}
                isIndeterminate={row.getIsSomeSelected()}
                onChange={row.toggleSelected}
                size="xs"
            />
            {cell.renderValue()}
        </div>);
};
