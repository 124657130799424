import {F4FormStep} from '@aktek/f4form';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4ButtonColorPicker from '@/components/F4Elements/F4ButtonColorPicker';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import {strings} from '@/localization/i18n';

import useTags from '../hooks/useTags';

export default function CreateTagModal(props: F4CreatableModalProps) {
    const {openOnMount, state, ...rest}= props;
    const {storeTag, getTagByID} = useTags();

    return (

        <F4CreatableModal
            state={state}
            title={strings('new_tag')}
            formKey="tag"
            hasSidebar={false}
            className="!h-[23.5rem] w-2/3 lg:w-1/3"
            editSuccessMessage={strings('edit_tag_success')}
            openOnMount={openOnMount}
            getFunction={getTagByID}
            storeFunction={storeTag}
            createSuccessMessage={strings('store_tag_success')}
            {...rest}
        >
            <F4FormStep autoNest>

                <F4Textbox
                    name="tag"
                    required
                    isDisabled={state?.mode === 'edit'}
                    label={strings('label')}
                    placeholder="Active"
                    type={'text'}
                    colSpan={12}
                />
                <F4ButtonColorPicker
                    name="color"
                    label={strings('color')}
                    size="2xl"
                    colSpan={12}
                />

            </F4FormStep>
        </F4CreatableModal>

    );
}
