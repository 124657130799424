import {TDropdownOption} from '@aktek/f4kit';

import {TF4ViewableComponentPropsReferences} from '@/components/F4ViewableComponent/F4ViewableComponent';
import {TColumn} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

export const DASHBOARD_COLUMNS:Array<TColumn> = [
    {key: 'title', header: 'Dashboard', type: 'string'},
    {key: 'actions', header: '', type: 'actions', actions: {edit: true, delete: true}},
];

export const DashboardOnRowAction = (references: TF4ViewableComponentPropsReferences) => (row, action, event, state) => {
    if (action.name == 'edit') {
        references.modalRef.current.open({mode: 'edit', _id: row.original.companyName});
    }

    if (action.name == 'delete') {
        references.deleteModalRef.current.open({id: row.original.companyName});
    }
};

export const AGENCY_HISTORY_COLUMNS : TColumn[] = [
    {
        key: 'action',
        header: 'Action',
        type: 'string',
    },
    {
        key: 'date',
        header: 'Date and Time',
        type: 'string',
    },
];

export const COMPANY_MODAL_TABS = [
    {key: 'companyDetails', label: 'Company Details'},
    {key: 'license', label: 'License'},
];

export const TYPES = {
    'AGENCY': 'Dashboard',
    'COMPANY': 'Company',
};

export const COMPANY_SUBTYPE = {
    'CLIENT': 'Client Company',
    'DEMO': 'Demo Company',
};

export const JOB_PRIORITY:Record<number, TDropdownOption>= {
    1: {label: strings('low_priority')},
    2: {label: strings('medium_priority')},
    3: {label: strings('high_priority')},
    4: {label: strings('very_high_priority')},
};

export const FEATURES:Record<string, boolean> = {
    'Advanced Data Actions': false,
    'Dashboard': false,
    'Anonymous Mobility Patterns': false,
    'AMP AIDs to Device IDs': true,
    'AMP Device Country Search': false,
    'AMP Device IDs to AIDs': false,
};
