import {useRef} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {strings} from '@/localization/i18n';

import useRoles from '../hooks/useRoles';
import RoleInfo from './RoleInfo';
import RolesPermissions from './RolesPermissions';
import UsersAssigned from './UsersAssigned';

export const CreateRoleAndPermissionModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, ...rest}= props;

    const {getRoles, storeRole, roleId, initialIsSuperAdmin} = useRoles();
    const ref = useRef();

    return <F4CreatableModal
        ref={ref}
        state={state}
        openOnMount={openOnMount}
        title={strings('role')}
        className="w-5/6"
        formKey="roles"
        createSuccessMessage={strings('create_role')}
        editSuccessMessage={strings('edit_role')}
        extra={{ref, roleId, state, initialIsSuperAdmin}}
        getFunction={getRoles}
        storeFunction={storeRole}
        steps={[
            {key: 'roleInfo', label: 'Role Info'},
            {key: 'permissions', label: 'Permissions'},
            {key: 'assignedUsers', label: 'Users Assigned'},
        ]}
        {...rest}
    >
        <RoleInfo />
        <RolesPermissions />
        <UsersAssigned />
    </F4CreatableModal>;
};
