
import {F4Form, F4FormDiv, useF4FormRef} from '@aktek/f4form';
import {Button, Label, Modal, useModalRef} from '@aktek/f4kit';
import {faArrowDownToLine} from '@fortawesome/pro-regular-svg-icons';
import {useEffect} from 'react';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import {strings} from '@/localization/i18n';

export type TExportModalProps = {
    openOnMount?: boolean;
    appearanceRef: unknown;
}

export default function ExportModal({
    openOnMount,
    appearanceRef,
} : TExportModalProps) {
    const formRef = useF4FormRef();

    const modalRef = useModalRef();

    useEffect(()=> {
        if (openOnMount) {
            modalRef.current.open();
        }
    }, []);

    return (
        <Modal
            size="md"
            ref={modalRef}
            className="w-fit h-fit"
            headerClassName="!absolute !w-fit !h-fit right-0 elevation-1 !shadow-none rounded-xl"
        >
            <F4Form ref={formRef} formKey="export" className="p-4">

                <F4FormDiv className="flex  flex-col justify-center items-center ">

                    <Button
                        icon={faArrowDownToLine}
                        textColor="primary-600"
                        className="!bg-primary-25 cursor-default"
                        size="xl"
                        isCircle/>
                    <Label text={strings('export_theme')} size="lg" className="mt-4 font-semibold" color="neutral-900"/>

                </F4FormDiv>

                <F4Textbox
                    name="fileName"
                    required
                    colSpan={12}
                    label={strings('file_name')}
                    placeholder=""
                    f4FormDivClassName="mt-4"
                    className="mt-2"
                    rightPlaceholder={'.iotheme'}
                    type={'text'}
                />
                <F4FormDiv colSpan={12} className="flex justify-between items-center gap-4 mt-4">

                    <Button
                        label={strings('cancel')}
                        variant="white"
                        className="w-[10.625rem]"
                        onClick={(e) => modalRef.current.close()}
                    />

                    <Button
                        label={strings('export')}
                        className="w-[10.625rem]"
                        onClick={ () => {
                            appearanceRef?.current?.exportData(formRef?.current?.getData().fileName, 'iotheme');
                        }}
                    />
                </F4FormDiv>
            </F4Form>
        </Modal>
    );
}
