import {useEffect} from 'react';

const useKeydownEventListener = (handleKeyDown, dependencies) => {
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [dependencies]);
};

export default useKeydownEventListener;
