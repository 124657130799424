import {Checkbox} from '@aktek/f4kit';
import React, {useCallback} from 'react';

interface QuickSetToggleProps {
  active?: boolean;
  onClick?: (props: QuickSetToggleProps, value: number) => void;
  state?: boolean | null;
  setIsSuperAdmin?:React.Dispatch<boolean | null>
  isSuperAdmin?:boolean | null
  isDisabled?:boolean
}

const QuickSetToggle: React.FC<QuickSetToggleProps> = ({active = false, onClick, state, setIsSuperAdmin, isSuperAdmin, isDisabled, ...restProps}) => {
    const handleClick = useCallback(() => {
        if (!onClick) return;
        setIsSuperAdmin?.(false);
        const value = state ? 0 : 1;
        onClick({active, onClick, ...restProps}, value);
    }, [state, onClick, active, restProps, setIsSuperAdmin]);

    if (!active) return <div />;

    return (
        <Checkbox isDisabled={isDisabled} size="sm" className=" !rounded-md" onChange={handleClick} value={isSuperAdmin || state} isIndeterminate={state === null} />
    );
};

export default QuickSetToggle;
