import {toast} from '@aktek/f4kit';
import {FoldersService, RecentlyUsedService} from '@aktek/morph-frontend-sdk';
import {useState} from 'react';

import {getFilesData} from '../app/Explorer/utils/getFilesData.fn';

type TState = {
        recentFolders: Array<unknown>
        allFolders: Array<unknown>
}

export type TFilesAndFoldersState = {
        GetAllFolders: () => void
        folders: Array<unknown>
        refresh: () => void
        deleteFolder: (item: unknown) => Promise<void>
        recentlyUsed: Array<unknown>
        isLoading: boolean,
}

const useFilesAndFoldersState: () => TFilesAndFoldersState = () => {
    const [state, setState] = useState<TState>({
        recentFolders: [],
        allFolders: [],
    });

    const updateState= <T>(key, value: T | void) => setState((state) => ({...state, [key]: value}));

    const folders = state.allFolders;
    const recentlyUsed = state.recentFolders;
    const [isLoading, setIsLoading] = useState(false);

    const refresh = () => {
        GetAllFolders();
        GetRecentTabs();
    };

    const GetRecentTabs = async () => {
        setIsLoading(true);
        const response = await RecentlyUsedService.getRecentTabs();
        const data = response.getData();
        const success = response.isSuccessful?.();
        success && updateState<unknown[]>('recentFolders', data?.tabs);
        setIsLoading(false);
    };

    const GetAllFolders = async () => {
        setIsLoading(true);
        const response = await FoldersService.getAllFolders();
        const success = response.isSuccessful?.();
        success && updateState<unknown[]>('allFolders', getFilesData(response?.Body));
        setIsLoading(false);
    };

    const deleteFolder = async (item) => {
        setIsLoading(true);
        const response = await FoldersService.deleteFolder(item.id);
        await refresh();
        const deleted = response.isSuccessful?.();

        deleted ? toast.success?.('Folder deleted successfully!') : toast.error?.('Failed to delete folder!');
        setIsLoading(false);
    };

    return {
        GetAllFolders,
        folders,
        refresh,
        deleteFolder,
        recentlyUsed,
        isLoading,
    };
};

export default useFilesAndFoldersState;
