import {TDropdownOption, TDropdownValue, TRadioButton} from '@aktek/f4kit';

import {COLORS} from '@/constants/colors.c';

export const THEME_COLORS = [
    {name: 'primary', label: 'color_primary', value: '#27666C'},
    {name: 'neutral', label: 'color_neutral', value: '#27666C'},
    {name: 'error', label: 'color_error', value: '#D92D20'},
    {name: 'warning', label: 'color_warning', value: '#556878'},
    {name: 'success', label: 'color_success', value: '#321578'},
    {name: 'white', label: 'color_white', value: '#000000'},
    {name: 'black', label: 'color_black', value: '#ffffff'},
];

export const FONT_STYLES: Record<TDropdownValue, TDropdownOption> = {
    '"Inter", sans-serif': 'Inter',
    '"Josefin Sans", sans-serif': 'Josefin Sans',
    '"Playfair Display", serif': 'Playfair Display',
    '"Plus Jakarta Sans", sans-serif': 'Plus Jakarta Sans',
    '"Space Grotesk", sans-serif': 'Space Grotesk',
};
export const FONT_SIZES: TRadioButton[] = [
    {value: 'small', label: 'Small', buttonString: 'Aa', buttonStringSize: 'sm'},
    {value: 'medium', label: 'Medium', buttonString: 'Aa', buttonStringSize: 'md'},
    {value: 'large', label: 'Large', buttonString: 'Aa', buttonStringSize: 'xl'},
];

export const DEFAULT_APPEARANCE = {
    colors: COLORS,
    font: {
        fontSize: 'medium',
        fontStyle: '"Inter", sans-serif',
    },
    images: {
        logo: null,
        background: null,
    },
};
