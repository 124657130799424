import {TColumn} from '@/components/Table/Types/Table.t';

export const GEO_COLUMNS:Array<TColumn> = [
    {key: 'key', header: 'Key', type: 'string'},
    {key: 'name', header: 'Name', type: 'string'},
    {key: 'description', header: 'Description', type: 'string'},
    {key: 'type', header: 'Type', type: 'string',
    //      renderCell: (row, cell) => {
    // }
    },
    {key: 'size', header: 'Size', type: 'string'},
    {key: 'createdOn', header: 'Created On', type: 'string'},
    {key: 'createdBy', header: 'Created By', type: 'string'},

];
