import {toast} from '@aktek/f4kit';
import {AuthenticationService} from '@aktek/helios-frontend-sdk';
import {useState} from 'react';

import {useSignUserIn} from '@/context/UserContext/Hooks/useSignUserIn';
import {strings} from '@/localization/i18n';
import {useNavigateToRoot} from '@/routing/navigations';
import {setCloudFrontCookies} from '@/utils/storage/Cookies';

import {TLoginState} from '../types/loginTypes.t';
import type {TTwoFactorState} from '../types/twoFactorState.t';
import {useLoginValidation} from './useAuthValidation';

export default function useHandleLogin(setCanResetPassword) {
    const navigateToRoot = useNavigateToRoot();

    const signIn = useSignUserIn();
    const [loading, setLoading] = useState(false);

    const [loginState, setLoginState] = useState<TLoginState>({
        email: '',
        emailTouched: false,
        password: '',
        passwordTouched: false,
    });

    const {validationResult} = useLoginValidation(loginState);
    const [twoFactorAuthState, setTwoFactorAuthState] = useState({challenge: '', email: ''});

    const handleEmailChange = (email)=> {
        setLoginState({...loginState, email: email.trim()?.toLowerCase()});
    };

    const handlePasswordChange = (password)=> {
        setLoginState({...loginState, password: password.trim()});
    };

    const handleLoginKeyDown = (event, onSuccess) => {
        if (event.key === 'Enter') {
            login(onSuccess);
        }
    };

    const login = async (onLoginSuccess: (twoFactorState: TTwoFactorState)=> void)=> {
        if (!validationResult.isValid) return;
        setLoading(true);
        const loginResponse = await AuthenticationService.login(
            loginState.email,
            loginState.password,
        );

        setLoading(false);

        if (loginResponse.isSuccessful?.()) {
            const data = loginResponse.getData?.();

            if (data.requires2FA) {
                const twoFactorState : TTwoFactorState = {
                    challenge: data.challenge,
                    email: data.email,
                };

                onLoginSuccess(twoFactorState);
            } else {
                setCloudFrontCookies(data?.cloudfrontCookie);
                signIn(data);
                navigateToRoot(true);
            }
        } else {
            setCanResetPassword(loginResponse.getData().canReset);

            // toast.error(strings('login_failed'));
        }

        setLoading(false);
    };

    return {loginState, setLoginState, loading,
        handleEmailChange, handlePasswordChange, validationResult,
        login, handleLoginKeyDown, twoFactorAuthState, setTwoFactorAuthState,
    };
}

