
import Cookies from 'js-cookie';

import {CloudFrontKeys} from '@/config/constants';

export const setCloudFrontCookies = (cloudfrontCookie) => {
    Cookies.set(CloudFrontKeys.CloudFrontPolicyKey, cloudfrontCookie?.[CloudFrontKeys.CloudFrontPolicyKey]);
    Cookies.set(CloudFrontKeys.CloudFrontKeyPairIdKey, cloudfrontCookie?.[CloudFrontKeys.CloudFrontKeyPairIdKey]);
    Cookies.set(CloudFrontKeys.CloudFrontSignatureKey, cloudfrontCookie?.[CloudFrontKeys.CloudFrontSignatureKey]);
};

export const clearCloudFrontCookies = () => {
    Cookies.remove(CloudFrontKeys.CloudFrontPolicyKey);
    Cookies.remove(CloudFrontKeys.CloudFrontKeyPairIdKey);
    Cookies.remove(CloudFrontKeys.CloudFrontSignatureKey);
};
