import {useF4FormRef} from '@aktek/f4form';
import {toast} from '@aktek/f4kit';
import {CompanySettingsService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import {strings} from '@/localization/i18n';

const useSettings = () => {
    const [mapWidth, setMapWidth] = useState(0);
    const [mapHeight, setMapHeight] = useState(0);
    const [savedValues, setSavedValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const settingsFormRef = useF4FormRef();

    useEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setMapWidth(window.innerWidth * 1);
            setMapHeight(window.innerHeight * 0.5);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getSettings = async () => {
        setIsLoading(true);
        const settings = await CompanySettingsService.getCompanySettings();

        if (!settings.isSuccessful?.()) return;

        const settingsData = settings.getData();
        const savedValues = settingsData.reduce((acc, {section, setting, value}) => {
            if (!acc[section]) {
                acc[section] = {};
            }

            acc[section][setting] = value;

            return acc;
        }, {});
        setSavedValues(savedValues);

        setIsLoading(false);
    };

    const handleSave = async () => {
        if (!settingsFormRef.current.validate().isFormValid) return;

        setIsLoading(true);
        const formData = settingsFormRef.current.getData();

        // Check if settings_freeze_account_inactive_time is false and update accordingly to remove previously stored value in DB
        // if (formData['account_freezing']?.settings_freeze_account_inactive_time === false) {
        //     formData['account_freezing'].settings_max_allowed_inactive_time = null;
        // }

        const transformedData = Object.entries(formData).flatMap(([section, settings]) =>
            Object.entries(settings).map(([setting, value]) => ({
                section,
                setting,
                value: typeof value === 'boolean' ? value : isNaN(value) ? value : Number(value),
            })),
        );

        const res = await CompanySettingsService.storeCompanySettings(transformedData);

        if (!res.isSuccessful()) return;

        toast.success(strings('settings_saved_successfully'));

        setSavedValues(formData);

        const localStorageData = Object.entries(formData).reduce((acc, [section, settings]) => {
            Object.entries(settings).forEach(([setting, value]) => {
                if (typeof value === 'boolean') {
                    acc[setting] = value;
                } else if (!isNaN(value)) {
                    acc[setting] = Number(value);
                } else {
                    acc[setting] = value;
                }
            });

            return acc;
        }, {});
        ls.companySettings = localStorageData;
        setIsLoading(false);
    };

    return {
        isLoading,
        mapWidth,
        mapHeight,
        savedValues: savedValues,
        getSettings,
        handleSave,
        settingsFormRef,
    };
};

export default useSettings;
