import {LicensePlanService} from '@aktek/helios-frontend-sdk';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {strings} from '@/localization/i18n';

import LicenseInfo from './LicenseInfo';

const CreateLicenseModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, ...rest}= props;

    return (
        <F4CreatableModal
            state={state}
            title="License"
            formKey="license"
            // className="md:w-3/5 lg:w-[45%] xl:w-2/5"
            className="md:w-3/5 lg:w-2/5 "
            hasSidebar={false}
            openOnMount={openOnMount}
            getFunction={LicensePlanService.getLicensePlan}
            editSuccessMessage={strings('edit_license_successfully')}
            createSuccessMessage={strings('create_license_successfully')}
            storeFunction={LicensePlanService.storeLicensePlan}
            {...rest}
        >
            <LicenseInfo />
        </F4CreatableModal>
    );
};

export default CreateLicenseModal;
