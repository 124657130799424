import {F4Form, F4FormDiv} from '@aktek/f4form';
import {LoadingWrapper} from '@aktek/f4kit';
import {transform} from 'lodash';
import isEqual from 'lodash/isEqual';
import {useState} from 'react';

import SettingsHeader from '../../components/SettingsHeader';
import FreezeAccount from './components/FreezeAccountSettings';
import Geographical from './components/GeographicalSettings';
import LoginSettings from './components/LoginSettings';
import PasswordSettings from './components/PasswordSettings';
import SecuritySettings from './components/SecuritySettings';
import TimezoneSettings from './components/TimezoneSettings';
import {DEFAULT_SETTINGS} from './constants/Settings.c';
import useSettings from './hooks/useSettings';

export default function Settings() {
    const {
        isLoading,
        mapWidth,
        mapHeight,
        savedValues,
        handleSave,
        settingsFormRef,
    } = useSettings();

    // FIXME: remove this when Atlas is fixed, synced with outside props
    const [dataUpdated, setDataUpdate] = useState(Date.now());

    const handleReset = () => {
        setDataUpdate(Date.now()); // FIXME: remove this when Atlas is fixed, synced with outside props
        settingsFormRef.current.setData(DEFAULT_SETTINGS);
    };

    const handleCancel = () => {
        setDataUpdate(Date.now()); // FIXME: remove this when Atlas is fixed, synced with outside props
        settingsFormRef.current.setData(savedValues);
    };

    return (
        <F4Form
            formKey="settings"
            ref={settingsFormRef}
            printData="console"
            initialValue={savedValues}
            className=" h-full "
        >

            {({getValue, setValue, data}) => {
                return <F4FormDiv className="mt-2 ">
                    <SettingsHeader
                        onSave={handleSave}
                        handleCancel={handleCancel}
                        handleReset={handleReset}
                        isInitialValue={isEqual(savedValues, data)}
                        isDefault={isEqual(DEFAULT_SETTINGS, data)}
                    />
                    <LoadingWrapper isLoading={isLoading} isFull className="w-full p-4" size="lg">
                        <F4FormDiv className="grid gap-4 grid-cols-9">
                            <FreezeAccount />
                            <Geographical
                                key={dataUpdated} // FIXME: remove this when Atlas is fixed, synced with outside props
                                mapHeight={mapHeight} mapWidth={mapWidth}
                                geo={getValue('geo')}
                                setValue={(path, value) => setValue('geo.' + path, value)}
                            />
                            <LoginSettings />
                            <PasswordSettings/>
                            <SecuritySettings />
                            <TimezoneSettings />
                        </F4FormDiv>
                    </LoadingWrapper>
                </F4FormDiv>;
            }}
        </F4Form>

    );
}
