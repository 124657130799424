/* eslint-disable max-len */
import Node from './Node.canvas';

const defaultOptions = {
    velocity: 1, // bigger = faster
    density: 12000, // smaller = denser
    spawnQuantity: 3, // On user click if enableMouseInteraction is true
    netLineDistance: 300,
    netLineColor: '#547d80',
    nodeColors: ['#877f7f', '#d0f2e1', '#878c89'],
    enableMouseInteraction: true,
};

export default class CanvasAnimatedNetwork {

    constructor(parent, startWithDefaults, defaultNodeCount, options = {}) {
        this.options = {...defaultOptions, ...options};
        this.canvas = parent.canvas;
        this.ctx = parent.ctx;
        this.startWithDefaults = startWithDefaults;
        this.defaultNodeCount = defaultNodeCount;
        this.init();
    };

    printNodes = () => {
        const mutableNodes = Object.assign(this.nodes);

        for (const node of mutableNodes) {
            delete node.canvas;
            delete node.ctx;
            delete node.parentNetwork;
        }
    };

    bindMouseActions = () => {
        // Mouse / touch event handling
        this.mouseIsDown = false;
        this.touchIsMoving = false;

        this.onMouseMove = function(e) {
            if (!this.interactionNode) {
                this.createInteractionNode();
            }

            this.interactionNode.x = e.offsetX;
            this.interactionNode.y = e.offsetY;
        }.bind(this);

        this.onTouchMove = function(e) {
            e.preventDefault();
            this.touchIsMoving = true;

            if (!this.interactionNode) {
                this.createInteractionNode();
            }

            this.interactionNode.x = e.changedTouches[0].clientX;
            this.interactionNode.y = e.changedTouches[0].clientY;
        }.bind(this);

        this.onMouseDown = function(e) {
            this.mouseIsDown = true;
            let counter = 0;
            let quantity = this.options.spawnQuantity;
            const intervalId = setInterval(function() {
                if (this.mouseIsDown) {
                    if (counter === 1) {
                        quantity = 1;
                    }

                    for (let i = 0; i < quantity; i++) {
                        if (this.interactionNode) {
                            this.nodes.push(new Node(this, this.interactionNode.x, this.interactionNode.y));
                        }
                    }
                } else {
                    clearInterval(intervalId);
                }

                counter++;
            }.bind(this), 50);
        }.bind(this);

        this.onTouchStart = function(e) {
            e.preventDefault();
            setTimeout(function() {
                if (!this.touchIsMoving) {
                    for (let i = 0; i < this.options.spawnQuantity; i++) {
                        this.nodes.push(new Node(this, e.changedTouches[0].clientX, e.changedTouches[0].clientY));
                    }
                }
            }.bind(this), 200);
        }.bind(this);

        this.onMouseUp = function(e) {
            this.mouseIsDown = false;
        }.bind(this);

        this.onMouseOut = function(e) {
            this.removeInteractionNode();
        }.bind(this);

        this.onTouchEnd = function(e) {
            e.preventDefault();
            this.touchIsMoving = false;
            this.removeInteractionNode();
        }.bind(this);

        this.canvas.addEventListener('mousemove', this.onMouseMove);
        this.canvas.addEventListener('touchmove', this.onTouchMove);
        this.canvas.addEventListener('mousedown', this.onMouseDown);
        this.canvas.addEventListener('touchstart', this.onTouchStart);
        this.canvas.addEventListener('mouseup', this.onMouseUp);
        this.canvas.addEventListener('mouseout', this.onMouseOut);
        this.canvas.addEventListener('touchend', this.onTouchEnd);
    };

    unbindMouseActions = function() {
        if (this.canvas) {
            this.canvas.removeEventListener('mousemove', this.onMouseMove);
            this.canvas.removeEventListener('touchmove', this.onTouchMove);
            this.canvas.removeEventListener('mousedown', this.onMouseDown);
            this.canvas.removeEventListener('touchstart', this.onTouchStart);
            this.canvas.removeEventListener('mouseup', this.onMouseUp);
            this.canvas.removeEventListener('mouseout', this.onMouseOut);
            this.canvas.removeEventListener('touchend', this.onTouchEnd);
        }
    };

    init = () => {
        // Create node objects
        this.createNodes(true);

        // Update canvas
        this.animationFrame = requestAnimationFrame(this.update.bind(this));

        if (this.options.enableMouseInteraction === true) {
            this.bindMouseActions();
        }
    };

    createDefaultNodes = () =>{
        if (this.startWithDefaults) {
            const defaultCount = this.defaultNodeCount;
            this.nodes = [];

            for (let i = 0; i < defaultCount; i++) {
                this.nodes.push(new Node(this));
            }
        }

        // Piotr suggested default
        // const defaultNodes = [{'x': 1098, 'y': 871}, {'x': 1098, 'y': 871}, {'x': 1098, 'y': 871}, {'x': 1017, 'y': 884}, {'x': 1017, 'y': 884}, {'x': 1017, 'y': 884}, {'x': 1102, 'y': 809}, {'x': 1102, 'y': 809}, {'x': 1102, 'y': 809}, {'x': 1215, 'y': 778}, {'x': 1215, 'y': 778}, {'x': 1215, 'y': 778}, {'x': 1259, 'y': 813}, {'x': 1259, 'y': 813}, {'x': 1259, 'y': 813}, {'x': 1254, 'y': 986}, {'x': 1254, 'y': 986}, {'x': 1254, 'y': 986}, {'x': 854, 'y': 786}, {'x': 854, 'y': 786}, {'x': 854, 'y': 786}, {'x': 757, 'y': 875}, {'x': 757, 'y': 875}, {'x': 757, 'y': 875}, {'x': 586, 'y': 920}, {'x': 586, 'y': 920}, {'x': 586, 'y': 920}, {'x': 411, 'y': 889}, {'x': 411, 'y': 889}, {'x': 411, 'y': 889}, {'x': 364, 'y': 776}, {'x': 364, 'y': 776}, {'x': 364, 'y': 776}, {'x': 250, 'y': 734}, {'x': 250, 'y': 734}, {'x': 250, 'y': 734}, {'x': 133, 'y': 787}, {'x': 133, 'y': 787}, {'x': 133, 'y': 787}, {'x': 42, 'y': 857}, {'x': 42, 'y': 857}, {'x': 42, 'y': 857}, {'x': 6, 'y': 958}, {'x': 6, 'y': 958}, {'x': 6, 'y': 958}, {'x': 280, 'y': 1000}, {'x': 280, 'y': 1000}, {'x': 280, 'y': 1000}, {'x': 763, 'y': 1012}, {'x': 763, 'y': 1012}, {'x': 763, 'y': 1012}, {'x': 1061, 'y': 1011}, {'x': 1061, 'y': 1011}, {'x': 1061, 'y': 1011}, {'x': 1208, 'y': 560}, {'x': 1208, 'y': 560}, {'x': 1208, 'y': 560}, {'x': 1069, 'y': 541}, {'x': 1069, 'y': 541}, {'x': 1069, 'y': 541}, {'x': 1262, 'y': 334}, {'x': 1262, 'y': 334}, {'x': 1262, 'y': 334}, {'x': 1235, 'y': 188}, {'x': 1235, 'y': 188}, {'x': 1235, 'y': 188}, {'x': 186, 'y': 77}, {'x': 186, 'y': 77}, {'x': 186, 'y': 77}, {'x': 69, 'y': 98}, {'x': 69, 'y': 98}, {'x': 69, 'y': 98}, {'x': 16, 'y': 252}, {'x': 16, 'y': 252}, {'x': 16, 'y': 252}, {'x': 38, 'y': 444}, {'x': 38, 'y': 444}, {'x': 38, 'y': 444}];

        /* for (const node of defaultNodes) {
            this.nodes.push(new Node(this, node.x, node.y));
        }*/
    };

    createNodes = (isInitial) => {
        // Initialise / reset nodes
        const me = this;
        this.nodes = [];
        const quantity = this.canvas.width * this.canvas.height / this.options.density;

        if (isInitial) {
            this.createDefaultNodes();
            let counter = 0;
            clearInterval(this.createIntervalId);
            this.createIntervalId = setInterval(function() {
                if (counter < quantity - 1) {
                    // Create node object
                    this.nodes.push(new Node(this));
                } else {
                    clearInterval(me.createIntervalId);
                }

                counter++;
            }.bind(this), 250);
        } else {
            // Create node objects
            for (let i = 0; i < quantity; i++) {
                this.nodes.push(new Node(this));
            }
        }
    };

    createInteractionNode = () => {
        // Add interaction node
        this.interactionNode = new Node(this);
        this.interactionNode.velocity = {
            x: 0,
            y: 0,
        };
        this.nodes.push(this.interactionNode);

        return this.interactionNode;
    };

    removeInteractionNode = () => {
        // Find it
        const index = this.nodes.indexOf(this.interactionNode);

        if (index > -1) {
            // Remove it
            this.interactionNode = undefined;
            this.nodes.splice(index, 1);
        }
    };

    update = () => {
        if (this.canvas) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.globalAlpha = 1;

            // Draw connections
            for (let i = 0; i < this.nodes.length; i++) {
                for (let j = this.nodes.length - 1; j > i; j--) {
                    let distance; const p1 = this.nodes[i]; const p2 = this.nodes[j];

                    // check very simply if the two points are even a candidate for further measurements
                    distance = Math.min(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));

                    if (distance > this.options.netLineDistance) {
                        continue;
                    }

                    // the two points seem close enough, now let's measure precisely
                    distance = Math.sqrt(
                        Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2),
                    );

                    if (distance > this.options.netLineDistance) {
                        continue;
                    }

                    this.ctx.beginPath();
                    this.ctx.strokeStyle = this.options.netLineColor;
                    this.ctx.globalAlpha = (this.options.netLineDistance - distance) / this.options.netLineDistance * p1.opacity * p2.opacity;
                    this.ctx.lineWidth = 0.7;
                    this.ctx.moveTo(p1.x, p1.y);
                    this.ctx.lineTo(p2.x, p2.y);
                    this.ctx.stroke();
                }
            }

            // Draw nodes
            for (let i = 0; i < this.nodes.length; i++) {
                this.nodes[i].update();
                this.nodes[i].draw();
            }

            if (this.options.velocity !== 0) {
                this.animationFrame = requestAnimationFrame(this.update.bind(this));
            }
        } else {
            cancelAnimationFrame(this.animationFrame);
        }
    };

}
