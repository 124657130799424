
import {Icon} from '@aktek/f4kit';
import {faArrowDown, faArrowUp} from '@fortawesome/pro-regular-svg-icons';
import {Header, RowData, Table as ITable} from '@tanstack/react-table';

import {TColumn, TUseSortReturnee} from '../Types/Table.t';

export const setUpSortableColumn = (column, columnDef: TColumn, sorting: TUseSortReturnee) => {
    if (!sorting) return;

    column.header= ({table, header}: {table: ITable<unknown>, header:Header<RowData, unknown>}) => {
        const isColumnSorted = sorting.isColumnSorted(columnDef.key);
        const isDesc = sorting.isDesc();

        return <div className="flex items-center cursor-pointer" onClick={() => {
            // if (isColumnSorted && !isDesc) {
            //     sorting.resetSortingColumn();

            //     return;
            // }

            sorting.setSortingColumn({
                id: columnDef.key,
                desc: !isDesc,
            });
        }}>
            {columnDef.header || columnDef.key}
            <Icon
                icon={isColumnSorted && !isDesc ? faArrowUp: faArrowDown}
                color={isColumnSorted ? 'neutral-600': 'neutral-300'}
                className="pl-2"
            />
        </div>;
    };
};
