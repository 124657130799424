
import {useUserContext} from '../Provider';

export function useSignUserOut() {
    const {setUserAuth}= useUserContext();

    return () => {
        ls.signOut();
        setUserAuth({});
    };
}
