
import {Cell, Row} from '@tanstack/react-table';
import _isFunction from 'lodash/isFunction';
import {TContextMenuRef} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/ContextMenu/Types/TContextMenuRef.t';

import {TActionCell, TCellRowProps, TOnRowAction} from '../Types/ActionButton.t';
import {TActionsNames} from '../Types/ActionsNames.t';
import {TColumn} from '../Types/Table.t';
import ActionButton from './ActionButton';

export const CellActions = (columnDef, state, onRowAction: TOnRowAction, contextMenuRef?: TContextMenuRef) => {
    const renderActionButton = (
        actionName: string,
        action: boolean | TActionCell,
        index: number,
        row: Row<unknown>,
        cell: Cell<unknown, unknown>,
        onActionClick: TColumn['onActionClick'],
    ) => {
        const name = actionName as TActionsNames;
        const actionProps = typeof action === 'boolean' ? {} : action;
        const isVisible = actionProps?.isVisible;
        if (isVisible === false) return null;

        if (_isFunction(isVisible) && isVisible({row, cell}) === false) return null;

        const dynamicProps = actionProps.get?.({row, cell}) || {};

        return (
            <ActionButton
                contextMenuRef={contextMenuRef}
                row={row}
                cell={cell}
                key={`${actionName}-${index}-${row.id}`}
                name={name}
                variant={actionProps?.variant || 'neutral'}
                isGhost={actionProps?.isGhost ?? true}
                isOutlined={actionProps?.isOutlined}
                icon={actionProps?.icon}
                tooltip={actionProps?.tooltip}
                options={actionProps?.options}
                contextMenuItems={actionProps?.contextMenuItems}
                className="mr-1"
                size="sm"
                onClick={(event, props) => {
                    actionProps?.onClick?.(row, name, event, state);
                    onActionClick?.(row, name, event, state);
                    onRowAction?.(row, {name, option: props?.option}, event, state);
                }}
                {...dynamicProps}

            />
        );
    };

    return {
        cell: ({cell, row}: TCellRowProps) => {
            const columnDef = cell?.column.columnDef as TColumn;
            const {actions = {}, onActionClick} = columnDef || {};

            return (
                <div className="flex items-center justify-end">
                    {Object.keys(actions).map((actionName, index) => {
                        let action = actions[actionName];

                        if (typeof action == 'function') {
                            action = action({row, cell});
                        }

                        return renderActionButton(actionName, action, index, row, cell, onActionClick);
                    },
                    )}
                </div>
            );
        },
    };
};
