import {useContext, useEffect, useState} from 'react';

import {UserAuth} from '@/types/UserAuth';

export type TUserContextProvider = {
    children: React.ReactNode
}

import useFilesAndFoldersState from '@/hooks/useFilesAndFoldersState';
import usePermissionsState from '@/hooks/usePermissionsState';

import {UserContext} from './Context';

export default function UserContextProvider({children}:TUserContextProvider) {
    const [userAuth, setUserAuth] = useState<UserAuth>(ls.getUserData());

    const filesAndFoldersState = useFilesAndFoldersState();
    const permissionsState = usePermissionsState();

    useEffect(() => {
        if (!userAuth.auth_token) return;

        filesAndFoldersState.refresh();
        permissionsState.clear();
        permissionsState.fetch();
    }, [userAuth.auth_token]);

    return (
        <UserContext.Provider value={{userAuth, setUserAuth, filesAndFoldersState, permissionsState}}>
            {children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    const userContext = useContext(UserContext);

    if (!userContext) {
        throw new Error('useUserContext must be used within a UserContextProvider');
    }

    return userContext;
}

