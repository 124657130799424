import {TColumn} from '@/components/Table/Types/Table.t';

export const DATA_COLUMNS = (): Array<TColumn> => [
    {key: 'name', header: 'Name', type: 'string'},
    {key: 'folder', header: 'Folder', type: 'string'},
    // {key: 'features', header: 'Features', type: 'string'},
    {key: 'fieldsCount', header: 'Fields Count', type: 'string'},
    {key: 'fieldTypes', header: 'Fields Types', type: 'string'},
    {
        key: 'actions',
        header: '',
        type: 'actions',
        actions: {
            edit: true,
            delete: true,
        },
    },
];
