import {TBaseTableRef, useContextMenuRef, usePagination} from '@aktek/f4kit';
import React, {useRef} from 'react';

import useColumns from './Hooks/useColumns';
import useCount from './Hooks/useCount';
import useRows from './Hooks/useRows';
import useSearch from './Hooks/useSearch';
import useSort from './Hooks/useSort';
import StatelessTable from './StatelessTable';
import {TTableProps} from './Types/Table.t';
import {TState} from './Types/Table.t';

const Table = (
    {noPagination,
        ribbonActions: headerActions,
        onRowAction, rows, getRows,
        columns, getColumns,
        getRowsCount, rowsCount,
        emptyProps,
        events,
        defaults = {},
        name,
        tableRef,
        className,
        baseTableClasses,
        getRightClickRowActions,
    }:TTableProps) => {
    const countState = useCount({getRowsCount, rowsCount});
    const sortState = useSort(defaults.sortingState);
    const searchState = useSearch();

    const paginationState = usePagination({defaultPageSize: defaults.pageSize || 20, itemsCount: countState.count});

    const rowsState = useRows({rows, paginationState, getRows, sortingState: sortState.sortingState, countState, searchState});

    const columnState = useColumns({columns, getColumns, paginationState});

    const state = React.useMemo<TState>(() => ({
        countState,
        paginationState,
        rowsState,
        columnState,
        sortState,
        searchState,
    }), [countState, paginationState, rowsState.rows, columnState, sortState, searchState]);

    const baseTableRef = useRef<TBaseTableRef>();
    const modalColumnsVisibilityRef = {open: null};
    const refresh = () => state.rowsState.fetchRows();
    const editColumnsVisibility = () => modalColumnsVisibilityRef?.open?.();

    const contextMenuRef = tableRef?.contextMenuRef || useContextMenuRef();
    const tableReferences = {contextMenuRef, state, baseTableRef, editColumnsVisibility, refresh};

    tableRef && Object.assign(tableRef, tableReferences);

    return (
        <StatelessTable
            baseTableClasses={baseTableClasses}
            className={className}
            name={name}
            modalColumnsVisibilityRef={modalColumnsVisibilityRef}
            contextMenuRef={contextMenuRef}
            baseTableRef={baseTableRef}
            events={events}
            emptyProps={emptyProps}
            onRowAction={onRowAction}
            state={state}
            searchState={searchState}
            ribbonActions={headerActions}
            isRowsLoading={rowsState.isRowLoading}
            isColumnsLoading ={columnState.isColumnsLoading}
            rows={rowsState.rows}
            count={countState.count}
            columns={columnState.columns}
            pagination={!noPagination && paginationState}
            sorting={sortState}
            getRightClickRowActions={getRightClickRowActions}
        />
    );
};

export default Table;
