
import {TTab} from '@aktek/f4kit';
import {faEye, faTag, faUser, faUsers} from '@fortawesome/pro-regular-svg-icons';

import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

export const GetAccessControlTabs : () => TTab [] = () => {
    const adminPermissions = useAdminPermissions();
    const {roles, users, groups, tagBasedAccessControl} = adminPermissions;

    return [
        {key: 'rolesAndPermissions', label: strings('rolesAndPermissions'), icon: faEye, isVisible: roles?.viewOrEdit},
        {key: 'users', label: strings('users'), icon: faUser, isVisible: users?.viewOrEdit},
        {key: 'groups', label: strings('groups'), icon: faUsers, isVisible: groups?.viewOrEdit},
        {key: 'tac', label: strings('tac'), icon: faTag, isVisible: tagBasedAccessControl?.viewOrCreateOrEdit},
    ];
};
