import {Navigate, Outlet} from 'react-router-dom';

import {useUserToken} from '@/context/UserContext/Hooks/useUserToken';

import Path from './Path';

const PrivateRoutes =()=>{
    const isUserSignIn = useUserToken();

    return (
        isUserSignIn ? <Outlet /> : <Navigate to={Path.Login} replace/>
    );
};

export default PrivateRoutes;
