
// import {TAvatarProps, TVariant} from '@aktek/f4kit';
// import {IconDefinition} from '@fortawesome/free-solid-svg-icons';

// import type {TEmptyFn} from '@/types/CommonTypes';

// import AskForConfirmation from './AskForConfirmation';

// export default function(
//     // title: string,
//     // message?: string,
//     // onConfirm?,
//     // confirmButtonVariant?: TVariant,
//     // confirmButtonLabel?: string,
//     // icon?: IconDefinition,
//     // avatar?: TAvatarProps,
//     // onCancel?: TEmptyFn,
//     // id?: string,
//     // successMessage?: string,
//     // errorMessage?: string,
// ) {
//     return AskForConfirmation(
//         'Are you sure you want to close this window?',
//         'All the data you entered is not saved yet so everything will be discarded.',
//         'error',
//         'Discard',
//         null,
//         null,
//         null,
//         null,
//         null,
//     );
//     // return new Promise((resolve) => {
//     // return AskForModal<TConfirmationModalProps>(ConfirmationModal, {
//     //     openOnMount: true,
//     //     title,
//     //     message,
//     //     confirmButtonVariant,
//     //     confirmButtonLabel,
//     //     icon,
//     //     avatar,
//     //     onConfirm: async () => {
//     //         const res = await onConfirm?.(id);
//     //         if (res.isSuccessful()) toast.success(successMessage);
//     //         else toast.error(errorMessage);

//     //         resolve(true);
//     //     },
//     //     onCancel: () => {
//     //         onCancel?.();
//     //         resolve(false);
//     //     },
//     // });
//     // });
// }

import AskForModal from '@/components/Modals/AskForModal';
import type {TEmptyFn} from '@/types/CommonTypes';

import CloseWindowModal, {TCloseWindowModalProps} from './CloseWindowModal';

export default function AskForCloseWindowModal(
    onConfirm?:TEmptyFn,

) {
    return new Promise((resolve) => {
        return AskForModal<TCloseWindowModalProps>(CloseWindowModal, {
            openOnMount: true,
            onConfirm: () => {
                onConfirm?.();

                resolve(true);
            },
            onCancel: () => {
                resolve(false);
            },
        });
    });
}

