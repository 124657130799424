import {DrawerState} from '@/types/DrawerState';

import ls from './storage/ls';

const defaultValues :DrawerState = {
    accessControl: true,
    advanced: true,
    companySettings: true,
    dataStructure: true,
    explorer: true,
};

export function openCloseDrawer(key, value) {
    const currentValue = ls.drawer || defaultValues;

    currentValue[key] = value;

    ls.drawer = currentValue;
}

