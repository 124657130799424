import {Tag} from '@aktek/f4kit';

import {TColumn} from '@/components/Table/Types/Table.t';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import {GetTagTextColor} from '../helpers/TagTextColors.fn';

export const TAG_COLUMNS = ():Array<TColumn> => {
    const {tags} = useAdminPermissions();

    return [
        {
            key: 'Tag',
            type: 'tags',
            renderCell({row}) {
                const color = row?.original?.Color[0].color;
                const label = row?.original?.Tag[0].label;

                const backgroundColor = color.length > 0 ? color : '#9BD8B8';

                return (
                    <Tag
                        backgroundColor={backgroundColor}
                        textColor={GetTagTextColor(color)}
                        additionalClassName={` !rounded-3xl px-2 py-1 `}
                        label={label}
                    />
                );
            },
        },
        {
            key: 'Color',
            type: 'color',
        },
        {
            key: 'actions',
            type: 'actions',
            header: '',
            actions: {
                delete: {
                    tooltip: strings('delete'),
                    isVisible: tags?.can_delete,
                },
                edit: {
                    tooltip: strings('edit'),
                    isVisible: tags?.can_edit,
                },
            },
        },
    ];
};
