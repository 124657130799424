import {Button, Label, Tooltip} from '@aktek/f4kit';
import {faArrowUpFromBracket, faCloudArrowDown, faSave} from '@fortawesome/pro-regular-svg-icons';

import F4FileReader from '@/components/F4Elements/F4FileReader';
import AskForModal from '@/components/Modals/AskForModal';
import {strings} from '@/localization/i18n';

import ExportModal from '../pages/Appearance/components/ExportModal';

export type TButtonSectionProps = {
    onSave?: () => void;
    onFileUpload?: (T: unknown) => void;
    onFileDownload?: (T:unknown) => void;
    onCancel?: () => void;
    onReset?: () => void;
    appearanceFormRef?: unknown;
    isInitialValue?: boolean;
    isDefault?: boolean;
}

export default function ButtonSection(
    {
        onSave,
        onFileUpload,
        onCancel,
        onReset,
        appearanceFormRef,
        isDefault,
        isInitialValue,
    } : TButtonSectionProps) {
    return (
        <div className="flex justify-evenly items-center w-fit min-w-52 gap-1">
            <Button
                isGhost
                size="sm"
                className="capitalize ml-1"
                textColor="neutral-600"
                onClick={onCancel}
                label={strings('discard')}
                isDisabled={isInitialValue}
            />
            <Button
                isGhost
                size="sm"
                className="capitalize ml-1"
                onClick={onReset}
                label={strings('reset_to_default')}
                isDisabled={isDefault}
            />
            <>
                {onFileUpload && <Tooltip showDelay={1000} placement="top" content={
                    <div className="flex justify-center items-center px-1 py-0.5">
                        <Label text={strings('import_theme')} size="xs"/>
                    </div>
                }>
                    <Button
                        size="sm"
                        className="ml-1"
                        onClick={() => AskForModal(F4FileReader, {
                            openOnMount: true,
                            description: '.iotheme files accepted',
                            onChange: onFileUpload,
                            title: strings('import_theme'),
                            extensions: ['iotheme'],
                        })}
                        variant="white"
                        iconSize="lg"
                        icon={faArrowUpFromBracket}
                    />
                </Tooltip>}
                {appearanceFormRef && <Tooltip showDelay={1000} placement="top" content={
                    <div className="flex justify-center items-center px-1 py-0.5">
                        <Label text={strings('export_theme')} size="xs"/>
                    </div>
                }>
                    <Button
                        size="sm"
                        className="ml-1"
                        onClick={() => AskForModal(ExportModal, {
                            openOnMount: true,
                            appearanceRef: appearanceFormRef,
                        })}
                        variant="white"
                        iconSize="lg"
                        icon={faCloudArrowDown}
                    />
                </Tooltip>}
            </>
            <Button
                size="sm"
                className="capitalize ml-1"
                onClick={onSave}
                isDisabled={isInitialValue}
                iconSize="lg"
                icon={faSave}
                label={strings('save')}
            />
        </div>
    );
}
