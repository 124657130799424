import {Icon, toast} from '@aktek/f4kit';
import {faCopy, faMapLocation} from '@fortawesome/pro-regular-svg-icons';
import {Cell, Row} from '@tanstack/react-table';

import Env from '@/config/env';
import {strings} from '@/localization/i18n';

export const CellGPS = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue?.() || '';
            const [lat, lng] = value.split(',');

            const hasValues = !(isNaN(lat) || isNaN(lng));

            return <div className="flex flex-col items-center gap-1">
                <div> {value} </div>
                { hasValues && <div className="flex items-center gap-1">
                    <Icon
                        tooltip="Click to copy"
                        onClick={() => copyToClipboard(lat, lng)} icon={faCopy}/>
                    <Icon
                        tooltip="Open in Google Maps"
                        onClick={() => openInGoogleMaps(lat, lng)} icon={faMapLocation}/>
                </div>}
            </div>;
        },
    };
};

const copyToClipboard = (lat, lng) => {
    const text = `${lat}, ${lng}`;
    navigator.clipboard.writeText(text);
    toast.neutral(strings('copied_to_clipboard'));
};

const openInGoogleMaps = (lat, lng) => {
    const googleMapsUrl = Env.GoogleMapLatLngAPI + `${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
};
