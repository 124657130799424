import {TTab} from '@aktek/f4kit';
import {faDatabase, faFill, faLanguage} from '@fortawesome/pro-regular-svg-icons';

import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

export const GetDataStructureTabs : () => TTab [] = () => {
    const adminPermissions = useAdminPermissions();
    const {reportStructure, conditionalColoring, translation} = adminPermissions;

    return [
        {key: 'dataStructure', label: strings('data_structures'), icon: faDatabase, isVisible: reportStructure?.viewOrCreate},
        {key: 'conditional_coloring', label: strings('conditional_coloring'), icon: faFill, isVisible: conditionalColoring?.viewOrCreate},
        {key: 'translations', label: strings('translations'), icon: faLanguage, isVisible: translation?.viewOrCreate},
    ];
};

