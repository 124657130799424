import {GroupsService, GroupsV4Service} from '@aktek/helios-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {TF4ViewableComponentPropsReferences} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import useTableRef from '@/components/Table/Hooks/useTable';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import Permissions from '@/helpers/Permissions.fn';
import {strings} from '@/localization/i18n';

import CreateGroupModal from './components/CreateGroupModal';
import GROUP_COLUMNS, {GetRightClickRowGroupsActions} from './Groups.columns';

export default function Groups() {
    const tableRef = useTableRef();
    const {groups} = useAdminPermissions();

    const refreshTable = ()=> tableRef?.refresh();

    const handleRowAction = (references: TF4ViewableComponentPropsReferences) => (row, action ) => {
        if (action.name == 'edit') {
            AskForCreationModal(CreateGroupModal, {state: {mode: 'edit', _id: row.original._id}, refreshTable});
        }

        if (action.name == 'delete') {
            references.deleteModalRef.current.open({id: row.original._id, name: row.original.name, message: strings('delete_group_message')});
        }

        if (!action.option) return;
    };

    return (
        <F4ViewableComponent
            name={strings('group')}
            deleteSuccessMessage={strings('group_deleted_successfully')}
            tableRef={tableRef}
            deleteIcon={false}
            hasSearch
            tableColumns={GROUP_COLUMNS}
            defaultSortedColumn="name"
            emptyProps={{addLabel: strings('new_group')}}
            onRowAction={handleRowAction}
            getRightClickRowActions={GetRightClickRowGroupsActions(groups)}
            modal={CreateGroupModal}
            canCreate={groups?.can_create}
            getAllData={GroupsV4Service.getAllGroups}
            deleteItem={GroupsService.deleteGroup}
        />
    );
}
