
import {F4FormElementDecorator} from '@aktek/f4form';
import {Icon, Image, toast, TSize} from '@aktek/f4kit';
import {faPen} from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import {useEffect, useRef, useState} from 'react';
import {v4 as uuid} from 'uuid';

import LoginSideImage from '@/assets/images/login_side_image.png';
import Env from '@/config/env';
import {strings} from '@/localization/i18n';

type TF4ImageSelectorProps = {
    isDisabled?: boolean;
    size?: TSize;
    title?:string;
    value: string;
    maxSizeMB: number;
    defaultImage?: TImage;
    allowedTypes: Array<string>;
    onChange: (e: string) => void;
    setIsLoading: (e: boolean) => void;
}

type TImage ={
    src: string
    alt?: string
}

function F4ImageSelector({onChange, value='', isDisabled, title, maxSizeMB=1, allowedTypes=['image/jpeg', 'image/png', 'image/gif'], defaultImage={src: LoginSideImage, alt: 'AKTEK'}}: TF4ImageSelectorProps) {
    const fileInputRef = useRef(null);

    const [image, setImage] = useState<TImage | null>(value ? {src: Env.getImageURL(value), alt: title||''} : null);

    useEffect(()=>{
        setImage(value ? {src: Env.getImageURL(value), alt: title||''} : null);
    }, [value]);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleOnChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!allowedTypes.includes(file.type)) {
                toast.error('Invalid image type!');

                return;
            }

            const fileSizeMB = file.size / 1024 / 1024; // Convert size from bytes to MB

            if (fileSizeMB > maxSizeMB) {
                toast.error(`The image size should not exceed  ${maxSizeMB}MB`);

                return;
            }

            const options = {
                headers: {
                    'Content-Type': file.type,
                },
            };
            const myUuid = uuid();

            const url = Env.getImageURL(myUuid);

            return new Promise((resolve, reject) => {
                axios.put(url, file, options)
                    .then((result) => {
                        setImage({src: myUuid});

                        onChange(myUuid);
                        toast.success(`Image uploaded successfully`);
                        resolve(result);
                    })
                    .catch((err) => {
                        toast.error(err);
                        reject(err);
                    });
            });
        }
    };

    return <>
        <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden"
            onChange={handleOnChange}
        />

        <div className="w-full flex flex-wrap justify-start items-center mt-2">
            <div className="border-2 border-neutral-200 w-fit md:!w-32 !h-44 rounded-lg relative">
                <Image
                    alt={image ? image?.alt: defaultImage?.alt}
                    src={image ? image?.src: defaultImage.src}
                    className="rounded-lg !w-full"/>
                <div className="absolute -right-3 -bottom-3">
                    <Icon
                        icon={faPen}
                        color="white"
                        onClick={handleImageClick}
                        isDisabled={isDisabled}
                        tooltip={strings('edit')}
                        className="bg-primary-600 p-[0.565rem] rounded-full transition-all duration-200 ease-in-out hover:opacity-85"
                    />
                </div>
            </div>
        </div>
    </>;
}

export default F4FormElementDecorator<TF4ImageSelectorProps>(F4ImageSelector);
