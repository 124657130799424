
import {Cell, Row} from '@tanstack/react-table';

import {TColumn} from '../Types/Table.t';

export const CellCustom = (columnDef: TColumn) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => (
            <div className='flex items-center gap-1'>
                {columnDef.render?.(row, cell)}
            </div>),
    };
};
