import {faCircleNodes} from '@fortawesome/pro-regular-svg-icons';


export const computeExplorerSections = (files) => {
    if (!(files && files?.length > 0)) {
        return [];
    }

    const result= [{
        id: 'folders__data',
        label: 'Folders & Data',
        type: 'folder',
        children: [],

        isNodeExpanded: ls.foldersAndData,
        labelProps: {className: '!font-semibold !text-neutral-900'},
    },
    {
        id: 'shortcuts',
        label: 'Shortcuts',
        type: 'folder',
        children: [],

        isNodeExpanded: ls.shortcuts,
        labelProps: {className: '!font-semibold !text-neutral-900'},
    },
    {
        id: 'repositories',
        label: 'Repository',
        type: 'folder',
        children: [{id: 'geo', label: 'Geo', icon: faCircleNodes, type: 'geo'}],

        isNodeExpanded: ls.repositories,
        labelProps: {className: '!font-semibold !text-neutral-900'},
    }];

    files.map((file)=>{
        if (['folder', 'report', 'shortcut'].includes(file.type)) {
            if (file.type === 'shortcut') {
                result[1].children.push(file);
            } else {
                result[0].children.push(file);
            }
        }
    });

    return result;
};
