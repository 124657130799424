import {TCompanySettings} from '@/types/CompanySettings';

import {useUserContext} from '../Provider';

export function useCompanySettings(key?: keyof TCompanySettings) {
    const {userAuth} = useUserContext();
    if (!key) return userAuth.companySettings;

    return userAuth.companySettings?.[key];
}

