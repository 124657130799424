import {faDashboard, faNetworkWired} from '@fortawesome/pro-regular-svg-icons';

import NavigationButton from '@/components/Navigation/NavigationButton';
import NavigationPageContainer from '@/components/Navigation/NavigationPageContainer';
import NavigationSection from '@/components/Navigation/NavigationSection';
import {strings} from '@/localization/i18n';

const Analysis = ({}): React.ReactElement => {
    return <NavigationPageContainer>

        <NavigationSection label={strings('visualize')}>
            <NavigationButton
                tabKey="dashboards"
                icon={faDashboard}
                name={strings('dashboards')}
                destinationKey="dashboards"
            />
            <NavigationButton
                tabKey="networks"
                icon={faNetworkWired}
                name={strings('networks')}
                destinationKey="networks"
            />
        </NavigationSection>

        <NavigationSection label="Data Manipulation">
            <NavigationButton
                tabKey="dashboards"
                icon={faDashboard}
                name="Data Toolbox"
                destinationKey="dashboards"
            />

        </NavigationSection>
    </NavigationPageContainer>;
};

export default Analysis;
