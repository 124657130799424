import {Button, Empty, LoadingIndicator} from '@aktek/f4kit';
import {faRefresh} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import {getRecentlyClasses} from '../styles/Recently.styles';
import CreateFolderModal from './CreateFolderModal';

const EmptyComponent = ({refresh, folders, isLoading}) => {
    const {folders: foldersPermissions} = useAdminPermissions();

    const emptyActionElements = [
        foldersPermissions?.can_create && <Button
            key={1}
            variant="neutral"
            isGhost={false}
            isOutlined={true}
            isCircle={false}
            label={strings('new_folder')}
            onClick={() => AskForCreationModal(CreateFolderModal, {state: {mode: 'create', _id: null}, data: folders, refresh})}
            size={'md'} />,
        <Button
            key={2}
            variant="primary"
            isGhost={false}
            isOutlined={false}
            isCircle={false}
            label={strings('refresh')}
            onClick={refresh}
            size={'md'}
            icon={faRefresh}
        />];
    const classes = getRecentlyClasses();

    return (
        <div className="flex justify-center bg-neutral-50 w-full h-full">
            <Empty
                label={strings('no_recent_files')}
                size="lg"
                className="max-w-lg mt-40"
                description={strings('no_recent_files_desc')}
                actionElements={_compact(emptyActionElements)}/>

            {isLoading && <div className={classes.loader}>
                <LoadingIndicator size="xl" />
            </div>}
        </div>

    );
};

export default EmptyComponent;

