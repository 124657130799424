
import {Checkbox, Modal, useModalRef} from '@aktek/f4kit';
import {Set} from 'immutable';
import React from 'react';

import {strings} from '@/localization/i18n';

import {TStatelessTableProps} from './Types/Table.t';

const ColumnVisibilityModal = ({baseTableRef}: Pick<TStatelessTableProps, 'baseTableRef'>) => {
    if (!baseTableRef.current) return null;

    const getVisibleColumns = () => Set(baseTableRef.current?.table?.getAllColumns?.()
        .filter((column) => column.getIsVisible())
        .map((column) => column.id)) as Set<string>;

    const _visibleColumns = getVisibleColumns();

    const [visibleColumns, setVisibleColumns] = React.useState<Set<string>>(_visibleColumns);

    const updateTable = () => {
        baseTableRef.current?.table?.getAllColumns?.()
            .forEach((column) => {
                const visible = visibleColumns.has(column.id);

                if (visible != column.getIsVisible()) {
                    column.toggleVisibility(visible);
                }
            });
    };

    const modalRef = useModalRef();
    React.useEffect(()=> {
        modalRef.current?.open();
    }, []);

    return <Modal closeOnBackdropClick title={strings('columns')}
        ref={modalRef}
        className="w-11 p-4"
        onClose={() => {
            updateTable();
        }}>
        { baseTableRef.current?.table?.getAllColumns?.().map((column) =>
            <div key={'column-visibility-modal-' + column.id} className="p-4">
                <Checkbox isCircle
                    size="lg"
                    key={column.id}
                    label={column.id}
                    onChange={(value) => {
                        setVisibleColumns((_visibleColumns) => {
                            return Set(value ? _visibleColumns.add(column.id) : _visibleColumns.remove(column.id));
                        });
                    }}
                    value={visibleColumns.has(column.id)}
                />
            </div>) }
    </Modal>;
};

export default ColumnVisibilityModal;
