
import {Breadcrumb, FitHeight, toast} from '@aktek/f4kit';
import {JobTrackingService} from '@aktek/helios-frontend-sdk';
import _get from 'lodash/get';

import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import {TResponse} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import Table from '@/components/Table/Table';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {strings} from '@/localization/i18n';

import IO_JOBS_COLUMNS from './iOJobs.columns';

const path = [
    {title: 'Admin', onClick: () => focusOrOpenNewTab('admin')},
    {title: 'iO Jobs', onClick: () => {}},
];

export default function iOJobs() {
    useDocumentTitle(strings('io_jobs'));

    return (
        <div className="bg-neutral-50 h-full">
            <FitHeight className="h-full w-full p-4">
                <Breadcrumb
                    path={path}
                    separator={'>'}
                    className="!bg-neutral-50 !p-0 !pb-6"
                    size="sm" />
                <div className="h-full w-full pb-3" >

                    <Table
                        getColumns={async ()=> IO_JOBS_COLUMNS}
                        getRows={async (paginationState, sortingState, countState, searchState) => {
                            const {pageSize, currentPage} = paginationState;
                            const search = searchState.query;
                            const sort = {id: sortingState[0]?.id, order: sortingState[0]?.desc ? '-1' : '1'};
                            const offset = pageSize * (currentPage -1);

                            const showFinished = false;
                            const showError = false;
                            const showCancelled = false;
                            const showAllCompanies = false;

                            const response
                            = await JobTrackingService.getAllJobs( showFinished, showError, showCancelled, showAllCompanies, search, sort, offset, pageSize);

                            if (!response.isSuccessful()) {
                                toast.error(strings('error_fetching_data'));

                                return [];
                            }

                            const data = response.getData() as TResponse;

                            if (!data) { // @TODO: double check this
                                toast.error(strings('error_fetching_data'));

                                return [];
                            }

                            const items = (Array.isArray(data) ? data : data?.data) || [];
                            const count = _get(data, ['metadata', 0, 'count']);

                            if (count !== undefined) countState.setCount(count);

                            return items;
                        }}
                        ribbonActions={
                            {
                                leftActions: {
                                    refresh: {
                                        onRibbonActionClick: (name, e, state) => {
                                            state.rowsState.fetchRows();
                                        },
                                    },
                                },
                                rightActions: {
                                    search: {
                                        searchProps: {
                                            onDebouncedChange(state, value) {
                                                state.searchState.setQuery(value);
                                            },
                                        },
                                    },
                                },
                            }
                        }
                    />
                </div>
            </FitHeight>
        </div>
    );
}
