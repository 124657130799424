import {toast} from '@aktek/f4kit';
import {GroupsService, UsersV4Service} from '@aktek/helios-frontend-sdk';
import {TACService, TagService} from '@aktek/morph-frontend-sdk';
import {useEffect, useState} from 'react';
import uuid from 'short-uuid';

import AskForModal from '@/components/Modals/AskForModal';
import {strings} from '@/localization/i18n';

import TagConditionModal, {TTagConditionModalProps} from '../components/TagConditionModal';
import {ReverseTransformStructure, TransformStructure} from '../helpers/TransformStructure';
interface Row {
    tagCondition: string | string[] | null;
    target: string | { users: string[]; groups: string[] } | null;
}

const useTAC = () => {
    const [rows, setRows] = useState<Record<string, Row>>({});
    const [savedRows, setSavedRows] = useState<Record<string, Row>>({});
    const [restrictVisibility, setRestrictVisibility] = useState<boolean>(false);
    const [savedVisibility, setSavedVisibility] = useState<boolean>();
    const [tags, setTags] = useState({});
    const [tagColors, setTagColors] = useState({});
    const [users, setUsers] = useState({});
    const [groups, setGroups] = useState({});
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const isEmpty = Object.keys(rows).length === 0;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsEdited(JSON.stringify(savedRows) !== JSON.stringify(rows) || savedVisibility != restrictVisibility);
    }, [rows, savedRows, restrictVisibility, savedVisibility]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchTags(),
                fetchTagColors(),
                fetchUsers(),
                fetchGroups(),
                fetchTAC(),
            ]);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const fetchUsers = async () => {
        const res = await UsersV4Service.getAllUsers();
        if (!res.isSuccessful()) return;
        const resultUsers = res.getData().dictionary;
        const transformedUsers = resultUsers.reduce((acc, user) => {
            acc[user._id] = user.name || user.email;

            return acc;
        }, {});
        setUsers(transformedUsers);
    };

    const fetchGroups = async () => {
        const res = await GroupsService.getAllGroups();
        if (!res.isSuccessful()) return;
        const resultGroups = res.getData();
        const transformedGroups = resultGroups.reduce((acc, group) => {
            acc[group._id] = group.name;

            return acc;
        }, {});
        setGroups(transformedGroups);
    };

    const fetchTags = async () => {
        const res = await TagService.getAllTags();
        if (!res.isSuccessful()) return;
        const resultTags = res.getData();
        const transformedTags = resultTags.reduce((acc, tag) => {
            acc[tag.value] = tag.label;

            return acc;
        }, {});
        setTags(transformedTags);
    };

    const fetchTagColors = async () => {
        const res = await TagService.getTagColors();
        if (!res.isSuccessful()) return;
        const resultTags = res.getData();
        setTagColors(resultTags);
    };

    const fetchTAC = async () => {
        const res = await TACService.getTAC();
        if (!res.isSuccessful()) return;
        const rules = res.getData().rules;
        const visibility = res.getData().shouldRestrictVisibility;
        const transformedRows = ReverseTransformStructure(rules ?? {});
        setRestrictVisibility(visibility);
        setSavedVisibility(visibility);
        setRows(transformedRows);
        // setSavedRows(transformedRows);
        setSavedRows(JSON.parse(JSON.stringify(transformedRows)));
    };

    const handleSave = async () => {
        const {transformedRows, invalidRows} = TransformStructure(rows);

        if (invalidRows.length > 0) {
            toast.error('Please fill out the missing fields in the rules then try again.');

            return;
        }

        const res = await TACService.storeTAC(transformedRows, restrictVisibility);
        if (!res.isSuccessful()) return;

        setSavedRows(JSON.parse(JSON.stringify(rows)));

        setSavedVisibility(restrictVisibility);
        toast.success(strings('success'));
    };

    const handleDiscard = () => {
        setRows(JSON.parse(JSON.stringify(savedRows)));

        setRestrictVisibility(savedVisibility);
    };

    const addNewCondition = () => {
        const id = uuid.generate();
        setRows((prevRows) => ({...prevRows, [id]: {tagCondition: null, target: null}}));
    };

    const handleTagConditionClick = (id, condition) => {
        const updatedRows = {...rows};

        if (condition === strings('select_tags')) {
            AskForModal<TTagConditionModalProps>(TagConditionModal, {
                isCondition: true,
                options: tags,
                openOnMount: true,
                selectedOptions: Array.isArray(updatedRows[id].tagCondition) ? updatedRows[id].tagCondition : [],
                title: strings('select_tags'),
                onConfirm: (condition) => {
                    updatedRows[id].tagCondition = condition;
                    setRows(updatedRows);
                },
            });

            return;
        }

        updatedRows[id].tagCondition = condition;
        setRows(updatedRows);
    };

    const handleTargetClick = (id, target) => {
        const updatedRows = {...rows};

        if (target === strings('select_audience')) {
            AskForModal<TTagConditionModalProps>(TagConditionModal, {
                options: users,
                groupOptions: groups,
                openOnMount: true,
                selectedOptions: Array.isArray(updatedRows[id].target?.users) ? updatedRows[id].target?.users : [],
                selectedGroupOptions: Array.isArray(updatedRows[id].target?.groups) ? updatedRows[id].target?.groups : [],
                title: strings('select_audience'),
                onConfirm: (users, groups) => {
                    updatedRows[id].target = {users, groups};
                    setRows(updatedRows);
                },
            });

            return;
        }

        updatedRows[id].target = target;
        setRows(updatedRows);
    };

    const handleDelete = (id) => {
        const updatedRows = {...rows};
        delete updatedRows[id];
        setRows(updatedRows);
    };

    const clearColumn = (id, isCondition) => {
        const updatedRows = {...rows};
        if (isCondition) updatedRows[id].tagCondition = null;
        else updatedRows[id].target = null;
        setRows(updatedRows);
    };

    const handleConditionTagRemove = (id, tagToRemove) => {
        const updatedRows = {...rows};
        const updatedTags = updatedRows[id].tagCondition.filter((tag) => tag !== tagToRemove);
        // updatedRows[id].tagCondition = updatedTags;
        updatedRows[id].tagCondition = updatedTags.length > 0 ? updatedTags : null;

        setRows(updatedRows);
    };

    const handleUserTagRemove = (id, userToRemove) => {
        const updatedRows = {...rows};
        const updatedUsers = updatedRows[id].target.users.filter((user) => user !== userToRemove);

        if (updatedUsers.length === 0 && updatedRows[id].target.groups.length === 0) {
            updatedRows[id].target = null; // Set to null if no users or groups remain
        } else {
            updatedRows[id].target.users = updatedUsers;
        }

        setRows(updatedRows);
    };

    const handleGroupTagRemove = (id, groupToRemove) => {
        const updatedRows = {...rows};
        const updatedGroups = updatedRows[id].target.groups.filter((group) => group !== groupToRemove);

        if (updatedGroups.length === 0 && updatedRows[id].target.users.length === 0) {
            updatedRows[id].target = null; // Set to null if no users or groups remain
        } else {
            updatedRows[id].target.groups = updatedGroups;
        }

        setRows(updatedRows);
    };

    return {
        rows,
        tags,
        tagColors,
        users,
        groups,
        restrictVisibility,
        isEmpty,
        isEdited,
        isLoading,
        handleSave,
        handleDiscard,
        addNewCondition,
        handleTagConditionClick,
        handleTargetClick,
        handleDelete,
        clearColumn,
        handleConditionTagRemove,
        handleUserTagRemove,
        handleGroupTagRemove,
        setRows,
        setRestrictVisibility,
    };
};

export default useTAC;
