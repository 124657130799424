import {UserTypes} from '@/constants/UserTypes.c';

import {useUserContext} from '../Provider';

export function useCurrentUser() {
    const {userAuth} = useUserContext();

    return userAuth.currentUser;
}

export function useCurrentUserID() {
    const currentUser = useCurrentUser();

    return currentUser?._id;
}

export function useIsCurrentUser(id) {
    const currentUserID = useCurrentUserID();

    return currentUserID == id;
}

export function useCurrectUserType() {
    const currentUser = useCurrentUser();

    return currentUser?.userType;
}

export function useIsRegularUser() {
    const currentUserType = useCurrectUserType();

    return currentUserType == UserTypes.Regular;
}

export function useIsAccountManager() {
    const currentUserType = useCurrectUserType();

    return currentUserType == UserTypes.AccountManager;
}
