
import Env from '@/config/env';

import {useUserContext} from '../Provider';

export function useSignUserIn() {
    const {setUserAuth}= useUserContext();

    return (userAuthData) => {
        if (userAuthData?.currentCompany?.companyLogo) {
            getImageAsBase64(Env.getImageURL(userAuthData?.currentCompany?.companyLogo))
                .then((base64Image) => {
                    ls.companyLogo = base64Image;
                })
                .catch((error) => {
                    console.error('Error converting image to Base64:', error);
                });
        }

        ls.signIn(userAuthData);
        setUserAuth(userAuthData);
    };
}

async function getImageAsBase64(url) {
    // Fetch the image as a blob
    const response = await fetch(url);
    const blob = await response.blob();

    // Create a FileReader to convert blob to Base64
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); // Base64 string
        reader.onerror = reject;
        reader.readAsDataURL(blob); // Read blob as Base64
    });
}
