import {Button, Card, ContextMenu, Icon, Label, LoadingIndicator, Tooltip} from '@aktek/f4kit';
import {faCircleNodes, faDatabase, faLink, faPlus} from '@fortawesome/pro-regular-svg-icons';

import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import {getRecentlyClasses} from '../styles/Recently.styles';
import CreateFolderModal from './CreateFolderModal';
import EmptyComponent from './EmptyComponent';

export default function RecentlyUsed({isLoading, refresh, recentlyUsed, items}) {
    const {folders: foldersPermissions} = useAdminPermissions();

    // todo: the whole page will be refactored after the new design and logic
    const handleReportDoubleClick = (item) => {
        const id = item._id;
        const label = item.component;
        focusOrOpenNewTab('dataTable', label, {label, sboId: id});
    };

    const classes = getRecentlyClasses();

    if (!recentlyUsed?.length) {
        return <EmptyComponent refresh={refresh} isLoading={isLoading} folders={items} />;
    }

    const IconByType = {
        report: faDatabase,
        shortcut: faLink,
        geo: faCircleNodes,
    };

    const getIcon = (type) => IconByType[type || faLink];

    return (

        <div className={classes.container}>
            <div className={classes.header}>
                <Label text={strings('recently_used')}
                    fontWeight="semibold" color="neutral-900"/>
                {foldersPermissions?.can_create && <Tooltip showDelay={1000} content={
                    <div className="flex justify-center items-center px-1 py-0.5">
                        <Label text={strings('add_folder')} size="xs"/>
                    </div>}>

                    <Button variant="primary" icon={faPlus} size="sm"
                        onClick={() => AskForCreationModal(CreateFolderModal, {state: {mode: 'create', _id: null}, data: items, refresh})}
                    />
                </Tooltip>}
            </div>

            <div className={classes.mapWrapper}>
                {/* // ! API_G will make it for max 30 items */}
                {recentlyUsed?.map((item, index) => {
                    return <ContextMenu key={index}
                        items={[
                            {label: 'Open', borderButton: true, onClick: () => handleReportDoubleClick(item)},
                            // ! API_G will create an api for this remove
                            {label: 'Remove'},
                        ]}
                    >
                        <Card className={classes.card}
                            onDoubleClick={() => handleReportDoubleClick(item)}>
                            <Icon
                                icon={getIcon(item?.props?.type)} className="mr-2" color="neutral-400" />

                            <Label text={item.name} size="xs"
                                className={classes.label} color="neutral-900" />
                        </Card>
                    </ContextMenu>;
                })}
            </div>
            {isLoading && <div className={classes.loader}>
                <LoadingIndicator size="xl" />
            </div>}
        </div>
    );
}

