import {F4FormElementDecorator, F4FormStep} from '@aktek/f4form';
import UserRolesService from '@aktek/helios-frontend-sdk/out/UserRolesService';
import {useEffect, useState} from 'react';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';
import F4RoleSelector from '@/components/F4Elements/F4RoleSelector/F4RoleSelector';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';

export function UserRoles() {
    const {extra} = useF4CreatableFormContext();
    const [initialState, setInitialState] = useState([]);

    const fetchSelectedRoles = async () => {
        const {roles} = useAdminPermissions();
        if (!roles?.can_view) return;
        const rolesResponse = await UserRolesService.getUserRoles(extra?.state?._id as string);
        if (!rolesResponse.isSuccessful()) return;
        !initialState && setInitialState(rolesResponse.getData());

        return rolesResponse;
    };

    useEffect(() => {
        if (extra?.state?._id) {
            fetchSelectedRoles();
        }
    }, [extra?.state?._id]);

    return (
        <F4FormStep step="userRoles">
            <F4RoleSelector name="roles"
                state={initialState}
                fetchSelectedRoles={extra?.state?.mode == 'edit' && fetchSelectedRoles}
                showOptional={false}
            />
        </F4FormStep>
    );
}

const F4UserRoles = F4FormElementDecorator(UserRoles);
export default F4UserRoles;
