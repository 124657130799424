import {Button, Label, TextBox} from '@aktek/f4kit';
import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';
import {useState} from 'react';

import {strings} from '@/localization/i18n';
import {useNavigateToRequestResetURL} from '@/routing/navigations';

import useHandleLogin from '../hooks/useHandleLogin';
import {getClasses} from '../styles/Login.styles';
import {TTwoFactorState} from '../types/twoFactorState.t';

type TTLoginCredentialsProps = {
    onLoginSuccess: (twoFactorAuthState: TTwoFactorState) => void;
}

export default function LoginCredentials({onLoginSuccess} : TTLoginCredentialsProps) {
    const navigateToURL = useNavigateToRequestResetURL();
    const [CanResetPassword, setCanResetPassword] = useState<boolean>(false);
    const handleLoginHook = useHandleLogin(setCanResetPassword);
    const {loginState} = handleLoginHook;

    const [showPassword, setShowPassword]= useState<boolean>(false);
    const {isValid, errors} = handleLoginHook.validationResult;
    const {emailTouched, passwordTouched} = loginState;

    const hasEmailError = errors?.email && emailTouched;
    const hasPasswordError = errors?.password && passwordTouched;
    const passwordIcons= {
        right: [{'icon': showPassword ? faEyeSlash : faEye, 'onClick': ()=> setShowPassword(!showPassword)}]};

    const onKeyDown = (event)=>handleLoginHook.handleLoginKeyDown(event, onLoginSuccess);

    const onEmailBlur = ()=>handleLoginHook.setLoginState({...loginState, emailTouched: true});

    const onPasswordBlur = ()=>handleLoginHook.setLoginState({...loginState, passwordTouched: true});

    const onLoginClick = ()=> handleLoginHook.login(onLoginSuccess);

    const classes = getClasses();

    return (
        <>
            <div className={classes.container}>
                <Label
                    text={strings('login')} size="xl"
                    fontWeight="semibold"
                    className="!mb-3 !text-4xl"/>
                <Label
                    text={strings('welcome_back_please_enter_your_details')}
                    color="neutral-600"
                    size="md" />
            </div>

            <TextBox
                className="mb-5"
                inputClassName="!rounded-lg"
                label={strings('email')}
                placeholder={strings('enter_your_email')}
                type="email"
                onChange={handleLoginHook.handleEmailChange}
                variant={hasEmailError ? 'error': 'neutral'}
                errorText={hasEmailError&& errors?.email}
                onKeyDown={onKeyDown}
                onBlur={onEmailBlur} />

            <TextBox
                label={strings('password')}
                placeholder="• • • • • • • •"
                inputClassName="!rounded-lg"
                type={showPassword ? 'text' : 'password'}
                onChange={handleLoginHook.handlePasswordChange}
                icons={passwordIcons}
                variant={hasPasswordError ? 'error': 'neutral'}
                errorText={hasPasswordError && errors?.password}
                onKeyDown={onKeyDown}
                onBlur={onPasswordBlur} />

            <div className={classes.button}>
                {CanResetPassword && <Button
                    label={strings('forgot_password')}
                    size="sm"
                    className="!px-0"
                    isGhost isOutlined
                    hasHoverBackground={false}
                    onClick={navigateToURL}/>}
            </div>

            <Button
                label={strings('login')}
                className="w-full"
                variant="primary"
                onClick={onLoginClick}
                isLoading={handleLoginHook.loading}
                isDisabled={!isValid} />
        </>
    );
}

