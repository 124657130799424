import {HttpStatusCode} from 'axios';
import axios from 'axios';

// import qs from 'qs';

import {TRequestConfig, TSDKRequestHandlers} from './types/SDKRequest';

const validateStatus = (status: number) => status === HttpStatusCode.Ok || status === HttpStatusCode.NotModified;

const getAuthorizedHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
});

export default async function CallRequest(options: TRequestConfig, sdkRequestHandlers: TSDKRequestHandlers) {
    const token = ls.token;
    const request = {
        ...options,
        headers: getAuthorizedHeaders(token),
        validateStatus,
    };

    try {
        const response = await axios(request);

        const {data, status} = response;

        sdkRequestHandlers.onResponse({data, status});

        return {...response.data, Valid: true};
    } catch (error) {
        const {code, message, response = {}} = error;
        const {data, status} = response;
        sdkRequestHandlers.onError({code, message, data, status});
        throw error;
    }
}
