// const adjustColor = (hexColor, darkenPercent, contrastPercent) => {
//     const hex = hexColor.replace(/^#/, '');
//     let red = parseInt(hex.substr(0, 2), 16);
//     let green = parseInt(hex.substr(2, 2), 16);
//     let blue = parseInt(hex.substr(4, 2), 16);

//     // Darken or lighten each color channel by the specified percentage
//     const adjustChannel = (channel, percent) => Math.floor(channel * (1 - percent / 100));

//     red = adjustChannel(red, darkenPercent);
//     green = adjustChannel(green, darkenPercent);
//     blue = adjustChannel(blue, darkenPercent);

//     // Adjust contrast by either bringing it closer to black or white
//     const adjustContrast = (channel) => {
//         if (channel < 128) {
//             return Math.floor(channel * (1 - contrastPercent / 100));
//         } else {
//             return Math.min(255, Math.floor(channel * (1 + contrastPercent / 100)));
//         }
//     };

//     red = adjustContrast(red);
//     green = adjustContrast(green);
//     blue = adjustContrast(blue);

//     // Ensure values stay within the valid range (0-255)
//     red = Math.max(0, Math.min(255, red));
//     green = Math.max(0, Math.min(255, green));
//     blue = Math.max(0, Math.min(255, blue));

//     // Convert back to hex and return
//     return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// };

// export const GetColors = (hexColor) => {
//     if (hexColor?.length <= 0 || isNil(hexColor)) {
//         return {
//             backgroundColor: '#70ca9d',
//             textColor: '#4a4a4a',
//         };
//     }

//     const hex = hexColor.replace(/^#/, '');
//     const red = parseInt(hex.substr(0, 2), 16);
//     const green = parseInt(hex.substr(2, 2), 16);
//     const blue = parseInt(hex.substr(4, 2), 16);

//     let backgroundColor = hexColor;
//     let textColor = 'black';

//     if (red >= 120 && green <= 75 && blue <= 75) { // Upper right
//         backgroundColor = hexColor;
//         textColor = 'white';
//     } else if (red >= 200 && green >= 150 && blue >= 150) { // Upper Left
//         backgroundColor = hexColor;
//         textColor = adjustColor(hexColor, 45, 35);
//     } else if (red <= 100 && green <= 100 && blue <= 100) { // Bottom
//         backgroundColor = hexColor;
//         textColor = 'white';
//     } else if (red <= 250 && green >= 75 && blue >= 50) {
//         backgroundColor = adjustColor(hexColor, -25, 25);
//         textColor = adjustColor(hexColor, 50, 30);
//     } else { // Everything in between
//         backgroundColor = adjustColor(hexColor, -25, 25);
//         textColor = adjustColor(hexColor, 37, 25);
//     }

//     return {
//         backgroundColor,
//         textColor,
//     };
// };

export const GetRBGfromHex = (hexColor) => {
    const hex = hexColor.replace(/^#/, '');
    const red = parseInt(hex.substr(0, 2), 16);
    const green = parseInt(hex.substr(2, 2), 16);
    const blue = parseInt(hex.substr(4, 2), 16);

    return {
        red,
        green,
        blue,
    };
};

export const GetColorBrightness = (red : number, green : number, blue : number) => {
    return (red * 299 + green * 587 + blue * 114) / 1000;
};
