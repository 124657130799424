import {faBarChart, faBrush, faBuilding, faBuildings, faClockRotateLeft, faDatabase, faEye,
    faFileLines, faFill, faGear, faLanguage, faList, faLock, faPlus, faTag, faTrashCan, faTriangleExclamation, faUser, faUsers} from '@fortawesome/pro-regular-svg-icons';

import CreateCompanyModal from '@/app/Admin/Agency/components/CreateCompanyModal';
import CreateLicenseModal from '@/app/Admin/Licensing/components/CreateLicenseModal';
import {openTab} from '@/app/Home/Tabs';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {TProps as NavigationButtonProps} from '@/components/Navigation/NavigationButton';
import Permissions from '@/helpers/Permissions.fn';
import {strings, TStringValue} from '@/localization/i18n';

import {
    CanAccessAccessControl,
    CanAccessAgency,
    CanAccessAgencySection,
    CanAccessCompanySettings,
    CanAccessData,
    CanAccessDataStructure,
    CanAccessLicense,
    CanAccessSecurity,
    CanAccessSettings,
    CanAccessTags,
    CanCreateCompanies,
    CanViewCompanies} from './Permissions.c';
interface NavigationItem {
    name: NavigationButtonProps['name'];
    tabKey: NavigationButtonProps['destinationKey'];
    variant?: NavigationButtonProps['variant'];
    badges?: NavigationButtonProps['badges'];
    icon: NavigationButtonProps['icon'];
    isVisible?: NavigationButtonProps['isVisible'];
}

interface NavigationSection {
    sectionTitle: TStringValue;
    children: NavigationItem[];
    isVisible?: boolean | (() => boolean);
}

export const AdminNavigation : ()=> NavigationSection[] = () => [
    {
        sectionTitle: strings('security'),
        children: [
            {
                tabKey: 'accessControl',
                name: strings('access_control'),
                icon: faLock,
                variant: '#039855',
                badges: [
                    {label: strings('rolesAndPermissions'), tabPropsKey: 'rolesAndPermissions',
                        icon: faEye, isVisible: (Permissions.Admin().VIEW('Roles') || Permissions.Admin().EDIT('Roles'))},

                    {label: strings('users'), tabPropsKey: 'users',
                        icon: faUser, isVisible: (Permissions.Admin().VIEW('Users') || Permissions.Admin().EDIT('Users'))},

                    {label: strings('groups'), tabPropsKey: 'groups',
                        icon: faUsers, isVisible: (Permissions.Admin().VIEW('Groups') || Permissions.Admin().EDIT('Groups'))},

                    {label: strings('tac'), tabPropsKey: 'tac',
                        icon: faTag, isVisible: (Permissions.Admin().VIEW_OR_CREATE('Tag-Based Access Control') || Permissions.Admin().EDIT('Tag-Based Access Control'))},
                ],
                isVisible: CanAccessAccessControl(),
            },
        ],
        isVisible: CanAccessSecurity(),
    },
    {
        sectionTitle: strings('data'),
        children: [
            {
                tabKey: 'dataStructure',
                name: strings('data_structure'),
                variant: '#1570EF',
                icon: faDatabase,
                badges: [
                    {label: strings('data_structure'), tabPropsKey: 'dataStructure', icon: faDatabase},
                    {label: strings('conditional_coloring'), tabPropsKey: 'conditional_coloring', icon: faFill},
                    {label: strings('translations'), tabPropsKey: 'translations', icon: faLanguage},
                    {label: strings('new_data_structure'), icon: faPlus,
                        onClick: () => openTab('dataStructureEditor'),
                        isVisible: CanAccessDataStructure(),
                    },
                ],
                isVisible: CanAccessDataStructure(),
            },
        ],
        isVisible: CanAccessData(),
    },
    {
        sectionTitle: strings('my_company'),
        children: [
            {
                tabKey: 'companySettings',
                name: strings('company_settings'),
                variant: '#6938EF',
                badges: [
                    {label: strings('settings'), tabPropsKey: 'settings', icon: faGear, isVisible: CanAccessSettings()},
                    {label: strings('appearance'), tabPropsKey: 'appearance', icon: faBrush, isVisible: CanAccessSettings()},
                    {label: strings('tags'), tabPropsKey: 'tags', icon: faTag, isVisible: CanAccessTags()},
                ],
                icon: faGear,
                isVisible: CanAccessCompanySettings(),
            },
            {
                tabKey: 'usageMetrics',
                name: strings('usage_metrics'),
                variant: '#6938EF',
                badges: [
                    {label: strings('monthly_usage')},
                    {label: strings('overall_usage')},
                ],
                icon: faBarChart,
            },
        ],
    },
    {
        sectionTitle: 'Agency',
        children: [
            {
                tabKey: 'agency',
                name: strings('agency'),
                icon: faBuilding,
                variant: '#BA24D5',
                badges: [
                    {label: strings('companies_list'), icon: faBuildings, isVisible: CanViewCompanies()},
                    {label: strings('new_company'), icon: faPlus,
                        onClick: () => AskForCreationModal(CreateCompanyModal),
                        // onClick: () => openTab('agency', undefined, {openOnMount: true}),
                        isVisible: CanCreateCompanies(),
                    },
                ],
                isVisible: CanAccessAgency(),
            },
            {
                tabKey: 'licensing',
                name: strings('licensing'),
                variant: '#BA24D5',
                icon: faFileLines,
                badges: [
                    {label: strings('licenses_list'), icon: faFileLines},
                    {label: strings('new_license'), icon: faPlus,
                        // onClick: () => focusOrOpenNewTab('licensing', undefined, {openOnMount: true})
                        onClick: () => AskForCreationModal(CreateLicenseModal),
                    },
                ],
                isVisible: CanAccessLicense(),
            },
        ],
        isVisible: CanAccessAgencySection(),
    },
    {
        sectionTitle: 'Advanced',
        children: [
            {
                tabKey: 'advanced',
                name: strings('advanced'),
                variant: '#088AB2',
                badges: [
                    {label: strings('restore_fields'), tabPropsKey: 'restoreFields', icon: faClockRotateLeft},
                    {label: strings('delete_all_data'), tabPropsKey: 'deleteAllData', icon: faTrashCan},
                ],
                icon: faTriangleExclamation,
            },
            {
                tabKey: 'iOJobs',
                variant: '#088AB2',
                name: strings('io_jobs'),
                badges: [{label: strings('view_all_jobs'), icon: faList}],
                icon: faList,
            },
        ],
    },
];
