import Permissions from '@/helpers/Permissions.fn';

// Comapny Settings
export const CanEditSettings = () => Permissions.Admin().EDIT('Company Settings');
export const CanAccessSettings = () => Permissions.Admin().VIEW('Company Settings') || Permissions.Admin().CREATE('Company Settings') || CanEditSettings();
export const CanCreateTags = () => Permissions.Admin().CREATE('Tags');
export const CanDeleteTags = () => Permissions.Admin().DELETE('Tags');
export const CanEditTags = () => Permissions.Admin().EDIT('Tags');
export const CanAccessTags = () => CanDeleteTags() || CanCreateTags() || CanEditTags();
export const CanAccessCompanySettings = () => CanAccessSettings() || CanAccessTags();

// Access Control Permissions
export const CanAccessRoles = () => Permissions.Admin().VIEW('Roles') || Permissions.Admin().CREATE('Roles') || Permissions.Admin().EDIT('Roles');
export const CanAccessUsers = () => Permissions.Admin().VIEW('Users') || Permissions.Admin().CREATE('Users') || Permissions.Admin().EDIT('Users');
export const CanAccessGroups = () => Permissions.Admin().VIEW('Groups') || Permissions.Admin().CREATE('Groups') || Permissions.Admin().EDIT('Groups');
export const CanAccessTAC = () => Permissions.Admin().VIEW('Tag-Based Access Control') ||Permissions.Admin().CREATE('Tag-Based Access Control') || Permissions.Admin().EDIT('Tag-Based Access Control');
export const CanAccessAccessControl = () => CanAccessGroups() || CanAccessUsers() || CanAccessRoles() || CanAccessTAC();
export const CanAccessSecurity = () => CanAccessAccessControl();

export const CanAccessCompanySwitcher = () => (((ls?.currentCompany?.companyType === 'OPS' || ls?.currentCompany?.companyType === 'AGENCY')
&& (ls?.user.userType === 'SUPERADMIN' || ls?.user.userType === 'AGENCYMANAGER' || ls?.user.userType === 'ACCOUNTMANAGER'))
|| (ls?.currentCompany?._id !== ls?.primaryCompany?._id
    && ls?.userCompanies && ls?.userCompanies.length > 0));

// Companies
export const CanCreateCompanies = () => Permissions.Admin().CREATE('Companies');
export const CanEditCompanies = () => Permissions.Admin().EDIT('Companies');
export const CanViewCompanies = () => Permissions.Admin().VIEW('Companies');
export const CanAccessCompanies = () => CanCreateCompanies() || CanEditCompanies() || CanViewCompanies();

// Data Permissions
export const CanAccessDataStructure = () => Permissions.Admin().VIEW_OR_CREATE('Report Structure');
export const CanAccessConditionalColoring = () => Permissions.Admin().VIEW('Conditional Coloring') || Permissions.Admin().EDIT('Conditional Coloring');
export const CanAccessTranslation = () => Permissions.Admin().VIEW_OR_CREATE('Report Structure') && Permissions.Admin().VIEW_OR_CREATE('Translation');
export const CanAccessData = () => CanAccessDataStructure() || CanAccessConditionalColoring() || CanAccessTranslation();

// this condition is new to f4: (ls?.user.userType !== 'REGULAR')
export const CanAccessAgency = () => (ls?.user?.userType !== 'REGULAR')
&& Permissions.Admin().VIEW_OR_CREATE('Companies')
&& (ls?.currentCompany?.companyType === 'OPS' || ls?.currentCompany?.companyType === 'AGENCY');

export const CanAccessLicense = () => (ls?.currentCompany?.companyType === 'OPS' && ls?.user?.userType === 'SUPERADMIN');

export const CanAccessAgencySection = () => CanAccessLicense() || CanAccessAgency();

// Hadi: This is an old condition I got from F3
// export const CanAccessAgency = () => Permissions.Admin().VIEW_OR_CREATE('Companies')
// || Permissions.App().ACCESS('Manage Account Managers')
// && (ls?.currentCompany?.companyType === 'OPS'
//      || ls?.currentCompany?.companyType === 'AGENCY');
