export const ACLPositions = {
    'can_create': 0,
    'can_view': 1,
    'can_edit': 2,
    'can_delete': 3,
    'can_access': 4,
};

export const ACLStatus = {

    'disabled': -2,
};
