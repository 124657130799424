import {useEffect, useState} from 'react';

import {SearchFunction} from '../utils/SearchFunction';

const useFilteredItems = (
    data,
    searchValue,
    includeMainObject: boolean,
    filterType?: string,
    id?: string | null,
    parentId?: string | null,
) => {
    const [items, setItems] = useState([]);

    const addCheckedToMatchingItems = (items) => {
        return items.map((item) => {
            const children = item.children?.length
                ? addCheckedToMatchingItems(item.children)
                : [];

            const hasMatchingChild = children.some((child) => child.parentId === parentId);

            return {
                ...item,
                children,
                checked: hasMatchingChild || item.parentId === parentId ? true : item.checked,
            };
        });
    };

    const removeMatchingItems = (items) => {
        return items
            .filter((item) => item.id !== id)
            .map((item) => ({
                ...item,
                children: item.children?.length ? removeMatchingItems(item.children) : [],
            }));
    };

    useEffect(() => {
        const filterAndSetItems = () => {
            let filteredData = data || [];

            if (includeMainObject) {
                filteredData = [
                    {
                        id: '1',
                        label: 'Folders & Data',
                        type: 'folder',
                        isNodeExpanded: true,
                        checked: !parentId,
                        labelProps: {color: 'neutral-900', fontWeight: 'semibold'},
                        children: filteredData,
                    },
                ];
            }

            if (parentId) {
                filteredData = addCheckedToMatchingItems(filteredData);
            }

            if (id) {
                filteredData = removeMatchingItems(filteredData);
            }

            if (filterType) {
                const filterItems = (item) => item.type === filterType;
                filteredData = includeMainObject
                    ? [{...filteredData[0], children: filteredData[0].children.filter(filterItems)}]
                    : filteredData.filter(filterItems);
            }

            if (searchValue) {
                filteredData = SearchFunction(filteredData, searchValue);
            }

            setItems(filteredData);
        };

        filterAndSetItems();
    }, [searchValue, data, includeMainObject, filterType, id, parentId]);

    return items;
};

export default useFilteredItems;
