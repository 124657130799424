import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

export const PASSWORD_STRENGTH: Record<string, string> = {
    'Low Security': strings('low_security'),

    'Low-Medium Security': strings('low_medium_security'),

    'Medium Security': strings('medium_security'),

    'Medium-High Security': strings('medium_high_security'),

    'High Security': strings('high_security'),

};

export const DATE_FORMAT: Record<TDropdownValue, TDropdownOption>= {
    '25/02/2020': '25/02/2020',
    '02/25/2020': '02/25/2020',
    '2020/02/25': '2020/02/25',
    '2020/25/02': '2020/25/02',
    '25-02-2020': '25-02-2020',
    '02-25-2020': '02-25-2020',
    '2020-02-25': '2020-02-25',
    '2020-25-02': '2020-25-02',
};

export const DEFAULT_SETTINGS = {
    account_freezing: {
        settings_freeze_account_admin_freeze: false,
        settings_freeze_account_inactive_time: false,
    },
    geo: {
        geo_center: {
            zoom: 6,
            center: {
                lat: 33.88473866115525,
                lng: 35.49528121948242,
            },
        },
        default_base_layer: 'Satellite',
        geo_zoom: 6,
        geo_has_kmz: 0,
        geo_kmz_uri: 0,
    },
    login: {
        settings_password_strength: 'High Security',
        settings_login_token_validity: 12,
    },
    password: {
        settings_password_time_validity: 30,
        settings_notify_stale_password: 5,
    },
    password_reset: {
        settings_user_reset_pass: false,
        settings_admin_reset_user_pass: false,
    },
    security: {
        settings_disable_2fa: false,
    },
    timezone: {
        settings_timezone: 'Europe/London',
        settings_date_format: '25/02/2020',
        settings_is_date_input_timezone: true,
    },
};

// export const DEFAULT_CENTER = {lat: 33.792843773631844, lng: 35.607401874248815};
export const DEFAULT_CENTER = {lat: 0, lng: 0, zoom: 6};
export const DEFAULT_ZOOM = 6;
