
import {Cell, Row} from '@tanstack/react-table';

import ReferenceButton from '@/components/ReferenceButton/ReferenceButton';

export const CellTags = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => (
            <div className="flex items-center gap-1">
                {row.getValue(cell.column.id)
                    .map(({label, color}, index) => <ReferenceButton
                        key={label +index}
                        value={label || ''}
                    />,
                    )
                }
            </div>),
    };
};
