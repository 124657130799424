
import {ToggleSwitch} from '@aktek/f4kit';
import {Cell, Row} from '@tanstack/react-table';

export const CellSwitch = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => (
            <div className="flex items-center">
                <ToggleSwitch
                    value={row.getValue(cell.column.id)}
                    size="xs"
                />
            </div>),
    };
};
