import React from 'react';

import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import InjectAsyncElement from '@/utils/InjectAsyncElement';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function AskForCreationModal(Element: React.ComponentType<F4CreatableModalProps>, props?: Partial<F4CreatableModalProps>) {
    InjectAsyncElement((resolve, reject, remove) => {
        return <Element {...props} openOnMount={true} onClose={remove}/>;
    });
}
