import i18next from 'i18next';

import enFallback from './locales/en/fallback.json';
import enNs1 from './locales/en/ns1.json';
import enNs2 from './locales/en/ns2.json';

export const defaultNS = 'ns1';
export const fallbackNS = 'fallback';

i18next.init({
    debug: false,
    fallbackLng: 'en',
    defaultNS,
    fallbackNS,
    resources: {
        en: {
            ns1: enNs1,
            ns2: enNs2,
            fallback: enFallback,
        },
    },
});

export const strings = i18next.t;
export type TStringKey = keyof typeof enFallback; // @TODO: handle nested keys
export type TStringValue = typeof enFallback[TStringKey];

export default i18next;
