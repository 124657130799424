import {Icon, TContextMenuEntry} from '@aktek/f4kit';
import {faBuilding, faCodeMerge, faEye, faPen, faPieChart} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import AskForModal from '@/components/Modals/AskForModal';
import {TColumn, TGetRightClickRowActions} from '@/components/Table/Types/Table.t';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import CreateCompanyModal from './components/CreateCompanyModal';
import UsageMetricsModal from './components/UsageMetricsModal';

export const convertToAccountManagers = (array) => array.reduce((acc, item) => {
    acc[item._id] = item.name;

    return acc;
}, {});

export const AGENCY_COLUMNS = () :Array<TColumn> => {
    const {companies} = useAdminPermissions();

    return [
        {key: 'companyLoginId', header: 'Company ID', type: 'string', isSortable: true},
        {key: 'companyName', header: 'Company Name', type: 'string', isSortable: true},
        {key: 'parentCompany', header: 'Parent company', type: 'string', isSortable: true},
        {key: 'jobPriority', header: 'Job Priority', type: 'string', isSortable: true},
        {key: 'companyType', header: 'Type', type: 'string', isSortable: true,
            renderCell: (row, cell) => {
                const isAgency = row?.getValue(cell?.column?.id) === 'AGENCY';

                return <div className="flex flex-row gap-2 items-center">
                    <Icon icon={isAgency ? faBuilding : faCodeMerge} fontWeight={600} />
                    {isAgency ? 'Parent' : 'Child'} Company
                </div>;
            },
        },
        {key: 'companySubtype', header: 'Company Sub Type', type: 'string', isSortable: true,
            renderCell: (row, cell) => {
                const value = row?.getValue(cell?.column?.id);
                if (value === 'CLIENT') return 'Client';
                if (value === 'DEMO') return 'Demo';

                return null;
            },
        },
        {
            key: 'actions',
            header: '',
            type: 'actions',
            actions: {history: {icon: faPieChart, tooltip: strings('usage_metrics')},
                edit: {
                    tooltip: strings('edit'),
                    isVisible: companies?.can_edit,
                },
                add: {
                    tooltip: strings('view'),
                    isVisible: companies?.can_edit,
                    icon: faEye,
                },
            },
        },

    ];
};

export const GetRightClickRowAgencyActions: TGetRightClickRowActions = (permission) => (refreshTable) => (row) => {
    return _compact([
        {
            label: strings('usage_metrics'),
            borderButton: true,
            icon: faPieChart,
            onClick: () => AskForModal(UsageMetricsModal, {companyId: row.original._id, openOnMount: true,
                header: row.original.companyName}),
        },
        permission?.can_edit && {
            label: strings('edit'),
            icon: faPen,
            borderButton: true,
            onClick: () => AskForCreationModal(
                CreateCompanyModal,
                {
                    state: {mode: 'edit', _id: row.original._id},
                    refreshTable,
                },
            ),
        },
        permission?.can_edit && {
            label: strings('view'),
            icon: faEye,
            onClick: () => AskForCreationModal(CreateCompanyModal, {
                state: {mode: 'view', _id: row.original._id},
            }),
        },
    ] as TContextMenuEntry[]);
};

export const USAGE_METRICS_COLUMNS : TColumn[] = [
    {key: 'name', header: 'Name', type: 'string', isSortable: true},
    {key: 'value', header: 'Value', type: 'string', isSortable: true},
];

