import {F4FormDiv, F4FormStep} from '@aktek/f4form';
import {TreeView} from '@aktek/f4kit';
import {FoldersService} from '@aktek/morph-frontend-sdk';
import _debounce from 'lodash/debounce';
import {TreeViewProps} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/TreeView/Types/TreeViewProps';
import {useCallback, useEffect, useRef, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import useFilteredItems from '../hooks/useFilteredItems';

const CreateFolderModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, data, refresh, ...rest}= props;
    const [searchValue, setSearchValue] = useState('');
    const [defaultExpanded, setDefaultExpanded] = useState(false);
    const [isCaretExpanded, setIsCaretExpanded] = useState(true);

    const items = useFilteredItems(data, searchValue, true, 'folder');

    const [info, setInfo] = useState({
        folderName: '',
        parentId: undefined,
        icon: null,
        color: '',
    });

    const handleTextBoxChange = useCallback(_debounce((value: string) => {
        setInfo((prevInfo) => ({
            ...prevInfo,
            folderName: value.trim(),
        }));
    }, 250), []);

    useEffect(() => {
        if (searchValue !== '') {
            setIsCaretExpanded(false);
            setDefaultExpanded(true);
            setTimeout(() => {
                setIsCaretExpanded(true);
            }, 1);
        } else {
            setDefaultExpanded(false);
        }
    }, [searchValue]);

    const ref = useRef();

    const storeFunction = async (value) => {
        const response = await FoldersService.storeFolder(value.folderName, value.parentId, value.iconUrl || '', '');
        const success = response.isSuccessful?.();

        if (success) {
            await refresh?.();
        }

        return {
            isSuccessful: () => success,
            getData: () => response.getData(),
        };
    };

    const treeViewConditionalProps = {
        size: 'xs',
        node: null,
        defaultExpanded: defaultExpanded,
        treeState: null,
        onNodeMove: () => {},

        onTreeStateChange: () => {},
        labelProps: {className: 'block w-full break-words break-all hyphens-auto leading-tight cursor-pointer'},

        onCheckChange: (e) => {
            const folders = Object.values(e).filter((item) => item.type === 'folder');
            const itemChecked = folders[0];

            if (!ref?.current || ref.current.getData() === null) return;

            if (itemChecked?.label === 'Folders & Data') {
                setInfo({...info, parentId: null});
                ref.current.setValue('parentId', null);
            } else {
                const parentId = itemChecked?.id;
                setInfo({...info, parentId});

                if (parentId) {
                    ref.current.setValue('parentId', parentId);
                }
            }
        },
        data: items,
        checkboxFilter: (node)=> node.type == 'folder',
        checkMode: 'single',

    } as Partial<TreeViewProps>;

    return (
        <F4CreatableModal
            isEditedOnSecondChange
            state={state}
            title={strings('folder')}
            formKey="folder"
            ref={ref}
            hasSidebar={false}
            onSave={refresh}
            openOnMount={openOnMount}
            isDisabled={info.folderName === '' || info.parentId === undefined}
            storeFunction={storeFunction}
            createSuccessMessage={strings('folder_created_successfully')}
            errorMessage={strings('folder_created_failed')}
            {...rest}
        >

            <F4FormStep autoNest>

                <F4AvatarImageSelector
                    name="iconUrl"
                    size="2xl"
                    colSpan={4}
                    title={strings('upload_folder_logo')}
                    showOptional={false}
                    f4FormDivClassName="flex justify-start items-center -mt-4"
                />
                <F4Textbox
                    name="folderName"
                    label={strings('name')}
                    placeholder=""
                    required
                    colSpan={8}
                    onChange={handleTextBoxChange}
                    type="text"
                    f4FormDivClassName="flex flex-col justify-center items-start"
                />

                <F4FormDiv colSpan={12} className="-mt-4">
                    <SearchBox onSearch={setSearchValue} className="sticky elevation-5 !shadow-none -top-4 bg-white" />

                    {items && items.length > 0 ? isCaretExpanded && <TreeView {...treeViewConditionalProps} />
                        : <p className="flex mt-3 ml-2" >{strings('no_results_found')}</p> }
                </F4FormDiv>

            </F4FormStep>

        </F4CreatableModal>

    );
};

export default CreateFolderModal;
