
import {Carousel} from '@aktek/f4kit';
import {Cell, Row} from '@tanstack/react-table';

export const CellMedia = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => (
            <div className="flex items-center  h-32 w-52">
                <Carousel slides={row.getValue(cell.column.id)} size="xs"/>
            </div>),
    };
};
