import Table from '@/components/Table/Table';

import getColumns from './helpers/getColumns';
import getCount from './helpers/getCount';
import getRows from './helpers/getRows';

const DataTableView = (props) => {
    const {sboId} = props;

    return <div className="m-4">
        <Table
            getColumns={getColumns(sboId)}
            getRows={getRows(sboId)}
            getCount={getCount(sboId)}
            headerActions={{
                leftActions: {
                    add: {
                        tooltip: 'Add new record',
                        variant: 'primary',
                        onHeaderClick: () => {
                        },

                    },

                },
                rightActions: {},
            }}
        />
    </div>;
};

export default DataTableView;
