import {Permissions} from '@/types/Permissions/AppPermissions.t';
export const defaultPermissions: Permissions = {
    app: {
        generateDashboardSnapshot: {},
        iqAreasLargeQueries: {},
        iqIncidents: {},
        iqAreasAreaQuery: {},
        iqAreasDeviceQuery: {},
        downloadIqAreaData: {},
        iqAreasKmzUploader: {},
        manageAccountManagers: {},
        admin: {},
        resetOwnPassword: {},
        resetOtherUserPassword: {},
        accountFreezingFreezeUserAccounts: {},
        accountFreezingUnfreezeUserAccounts: {},
        immunityAgainstAccountFreezing: {},
        viewEmptyFolders: {},
        media: {},
        chat: {},
        auditTrail: {},
        downloadData: {},
        whiteLabel: {},
        ampDevice: {},
        ampArea: {},
        ampInvestigate: {},
        downloadAmpData: {},
        ampMonitor: {},
        generatePublicLink: {},
        fireData: {},
        onlineTrafficAnalysis: {},
        automation: {},
        ampDeviceInfo: {},
        lockSupportOut: {},
        advancedDataActions: {},
        ampDeviceIdsToAids: {},
        ampAidsToDeviceIds: {},
        ampIpSearchSignals: {},
        ampIpSearchDevices: {},
        ampKmzUploader: {},
        dataToolbox: {},
    },
    admin: {
        reportStructure: {},
        users: {},
        companies: {},
        dashboards: {},
        roles: {},
        groups: {},
        businessProcesses: {},
        permissions: {},
        companySettings: {},
        translation: {},
        folders: {},
        conditionalAccessControl: {},
        network: {},
        conditionalColoring: {},
        viewJobs: {},
        tags: {},
        tagBasedAccessControl: {},
    },

    canEditSettings: false,
    canAccessSettings: false,

    canCreateTags: false,
    canDeleteTags: false,
    canEditTags: false,
    canAccessTags: false,

    canAccessCompanySettings: false,
    canAccessRoles: false,
    canAccessUsers: false,
    canAccessGroups: false,

    canAccessTAC: false,
    canAccessAccessControl: false,
    canAccessSecurity: false,

    canAccessCompanySwitcher: false,

    canCreateCompanies: false,
    canEditCompanies: false,
    canViewCompanies: false,
    canAccessCompanies: false,

    canAccessDataStructure: false,
    canAccessConditionalColoring: false,
    canAccessTranslation: false,
    canAccessData: false,

    canAccessAgency: false,
    canAccessLicense: false,
    canAccessAgencySection: false,
};
