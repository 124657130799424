// import {UsersV4Service} from '@aktek/helios-frontend-sdk';
import {CompaniesService} from '@aktek/helios-frontend-sdk';
import {forwardRef} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';

const UsersCreatorModal = ({}, ref) => {
    return <F4CreatableModal
        title="Company"
        formKey="users"
        ref={ref}
        getFunction={CompaniesService.getCompanyById}
        storeFunction={CompaniesService.store}
        steps={[
            {key: 'userInfo', label: 'User Info'},
            {key: 'companyRelatedInfo', label: 'Company Related Info'},
            {key: 'userRoles', label: 'User Roles'},
        ]}
    >
        Create dashboard
    </F4CreatableModal>;
};

export default forwardRef(UsersCreatorModal);
