
import {F4FormElementDecorator} from '@aktek/f4form';
import {CardCheckboxGroup, TCardCheckboxGroupProps} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const F4CardCheckboxGroup = F4FormElementDecorator<TCardCheckboxGroupProps>(CardCheckboxGroup, (value, props)=>{
    const messages = [];
    if (props.required && value.length == 0) messages.push(strings('field_is_mandatory'));

    return {isValid: messages.length === 0, messages};
});

export default F4CardCheckboxGroup;

