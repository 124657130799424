import {Badge, Card, Icon, Label} from '@aktek/f4kit';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';

import {ETabs, focusOrOpenNewTab} from '../../app/Home/Tabs';
import {NavigationButtonClasses} from './styles/NavigationButton.styles';

interface TAdminBadge {
    label: string;
    icon?: IconDefinition;
    isVisible?: boolean
    tabPropsKey?:string
    onClick?: () => void
}
export interface TProps {
    isVisible: boolean
    name: string;
    icon: IconDefinition;
    variant?: string;
    badges?: TAdminBadge[];
    destinationKey: keyof typeof ETabs
}

function NavigationButton({isVisible, destinationKey, variant, name, badges, icon}: TProps) {
    if (isVisible == false) return;

    const onBadgeClick = async (selectedTab, e, onClick) => {
        e.stopPropagation();
        e.preventDefault();

        if (!onClick) {
            focusOrOpenNewTab(destinationKey, undefined, {selectedTab});

            return;
        }

        onClick?.();
    };

    const classes = NavigationButtonClasses();

    return (
        <Card
            key={destinationKey}
            borderWidth={1}
            borderColor="neutral-100"
            roundingSize="2xl"
            backgroundColor="white"
            className={classes.card}
            // elevation={1}
            onClick={()=> focusOrOpenNewTab(destinationKey)}
        >

            <div className={classes.cardHeader}>
                <Icon icon={icon} className="!text-center" style={{color: variant}} size="xl" />
                <Label text={name} size="sm"
                    className="cursor-pointer" color="neutral-900" fontWeight="semibold" />
            </div>
            <div className={classes.badgeContainer}>

                {badges?.map((badge, index) => {
                    if (badge.isVisible == false) return;

                    return <Badge
                        onClick={(event) => onBadgeClick(badge.tabPropsKey, event, badge?.onClick)}
                        variant="neutral-100" label={badge.label}
                        iconProps={{color: 'neutral-500'}}
                        labelProps={{color: 'neutral-700', fontWeight: 'medium'}}
                        icon={badge.icon || null} iconPlacement="left" size="xs" key={index}
                        additionalClassName={classes.badgeClassName}/>;
                })}
            </div>
        </Card>
    );
}

export default NavigationButton;
