
import {TextBox} from '@aktek/f4kit';
import {useNonInitialEffect} from '@aktek/f4kit';
import {faClose, faSearch} from '@fortawesome/pro-regular-svg-icons';
import debounce from 'lodash/debounce';
import React, {useRef} from 'react';

import {strings} from '@/localization/i18n';

import {TAction} from '../Types/ActionButton.t';
import {TActionsNames} from '../Types/ActionsNames.t';
import {TState, TUseSearchReturnee} from '../Types/Table.t';
import ActionButton from './ActionButton';

type SearchActionProps = {
    state: TState
    searchState: TUseSearchReturnee
    name: TActionsNames
    side: 'left' | 'right',
    actionProps: TAction
}

const SearchAction = ({state, searchState, name, side, actionProps}: SearchActionProps) => {
    const {onDebouncedChange, debounceDuration = 300, onChange} = actionProps.searchProps;

    const _onDebouncedChange:TAction['searchProps']['onDebouncedChange'] = (tableState, value) => {
        onDebouncedChange(tableState, value);
        searchState.setQuery(value);
    };

    const _onChange:TAction['searchProps']['onChange'] = (tableState, value) => {
        onChange?.(tableState, value);
    };

    const [_value, _setValue] = React.useState<string>(searchState?.query);
    const textBoxRef = useRef(null);

    const debouncedOnChange = React.useMemo(() =>
        debounce(_onDebouncedChange, debounceDuration || 300)
    , [onChange]);

    useNonInitialEffect(() => {
        _onChange(state, _value);
        debouncedOnChange(state, _value);
    }, [_value] );

    const hasSearch = !!_value?.length;

    React.useEffect(() => {
        const handleKeydown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'f') {
                event.preventDefault();
                textBoxRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeydown);

        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    React.useEffect(() => {
        _setValue(searchState?.query);
        textBoxRef.current?.focus();
    }, [searchState?.query]);

    return <div className="flex"
        onKeyDown={(event) => {
            if (event?.key == 'Enter') {
                debouncedOnChange.flush();
            }

            event.stopPropagation();
        }}
        onClick={(event) => event.stopPropagation()}>

        <TextBox
            inputClassName="h-9 !border-neutral-200"
            ref={textBoxRef}
            value={_value}
            onChange={(value) => _setValue(value)}
            placeholder={strings('search')}
            icons={{
                right: [
                    {
                        icon: faClose,
                        onClick: (event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            debouncedOnChange.cancel();
                            _setValue('');
                        },
                        visibility: hasSearch ? '' : 'hidden',
                    },
                ],
                left: [
                    {
                        icon: faSearch,
                        color: 'neutral-600',
                        tooltip: actionProps.tooltip,
                        onClick: () => {
                            debouncedOnChange.flush();
                            textBoxRef.current?.focus();
                        },
                    }],
            }}

        />
    </div>;
};

export default SearchAction;
