import {LoadingIndicator} from '@aktek/f4kit';

const PermissionLoading = () => {
    return (
        <div className="justify-center items-center w-full h-full flex"
            style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0,
                background: '#eeeeee', opacity: 0.7, textAlign: 'center', zIndex: 9999}}>
            <LoadingIndicator size="xl" />
        </div>
    );
};

export default PermissionLoading;
