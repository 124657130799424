
// import Color from '@/components/TierTwo/Color/Color';
import {Color} from '@aktek/f4kit';
import {Cell, Row} from '@tanstack/react-table';
import _isString from 'lodash/isString';

export const CellColor = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const columnDef = cell.column.columnDef;
            const value =row.getValue(cell.column.id);
            if (!value) return null;
            const isArray = Array.isArray(value);
            const isString = _isString(value);

            return <div className="flex items-center gap-1">
                {isArray && value.map?.(({isSquare, color}, index) => <Color
                    key={color + index}
                    value={color}
                    isSquare={columnDef.isSquare || isSquare}
                />)}
                {!isArray && <Color
                    value={isString ? value : value?.color}
                    isSquare={columnDef.isSquare || value.isSquare}
                />}
            </div>;
        },
    };
};
