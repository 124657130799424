import {FitHeight} from '@aktek/f4kit';
import {DashboardService} from '@aktek/theia-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';

import DashboardCreatorModal from '../components/DashboardCreatorModal';
import {DASHBOARD_COLUMNS, DashboardOnRowAction} from '../constants/DashboardsConstants.c';

const Analysis = ({}): React.ReactElement => {
    return <div className="flex items-start bg-neutral-50 px-4 py-3 h-full">
        <FitHeight className="h-full w-full pb-1">
            <F4ViewableComponent
                tableColumns={DASHBOARD_COLUMNS}
                onRowAction={DashboardOnRowAction}
                modal={DashboardCreatorModal}
                getAllData={DashboardService.getUsableDashboards}
                getDetailData={DashboardService.getById}
            />
        </FitHeight>

    </div>;
};

export default Analysis;
