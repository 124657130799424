
import {FileUpload, Modal, TFileUploadProps, useModalRef} from '@aktek/f4kit';

export type TF4FileReaderProps = Omit<TFileUploadProps, 'label'> & {
    title?: string;
}

export default function F4FileReader({onChange, title, description, extensions, maxFileSize, maxTotalFilesSize}: TF4FileReaderProps) {
    const modalRef = useModalRef();

    return (
        <Modal
            defaultIsOpened
            size="md"
            ref={modalRef}
            className="w-fit h-fit"
            title={title}
            headerClassName="px-4 ml-4 pb-0 font-semibold text-neutral-900 text-lg rounded-xl"
        >
            <FileUpload
                uploadButtonSize={'xl'}
                label=""
                onChange={onChange}
                description={description}
                className="w-[26rem]"
                extensions={extensions}
                maxFileSize={maxFileSize}
                maxTotalFilesSize={maxTotalFilesSize}
            />
        </Modal>
    );
}

