
import {Cell, Row} from '@tanstack/react-table';

import ReferenceButton from '@/components/ReferenceButton/ReferenceButton';

export const CellRef = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue();

            return <div className="flex items-center gap-1">
                <ReferenceButton value={value || ''} />
            </div>;
        },
    };
};
