
export const COLORS = {
    black: '#000000',
    white: '#ffffff',
    error: '#F04438',
    warning: '#F79009',
    success: '#12B76A',
    primary: '#0A3F45',
    neutral: '#667085',
};
