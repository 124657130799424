
import {ContentDivider, Label, ProgressBar} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

function UsageMetricViewer({metrics, ownMetrics, license, showOnlyRolling}) {
    return (
        <>
            {(metrics && ownMetrics) ? metrics.map((metric, index) => {
                const ownMetric = ownMetrics[metric.label] ? Number(ownMetrics[metric.label]) : 0;

                if (metric.isTracked != false && (metric.isRolling || showOnlyRolling !== true)) {
                    return <div key={index} >
                        <div className="flex w-full items-center justify-between py-1">
                            <Label text={strings(['usage_metric_' + metric.label])} size="sm" fontWeight="medium" className="pr-6"/>
                            <div className="py-1 px-6">
                                {license?.limits?.[metric.label]
                            && (showOnlyRolling === true && metric.isRolling)
                            && metric.type !== 'dropdown'
                                    ? <ProgressBar hasLabel
                                        value={100*ownMetric / license.limits[metric.label]} />
                                // value=={`${ownMetric}/${license.limits[metric.label]}`} />
                                    : ownMetric ? ownMetric.toLocaleString() : 0
                                }
                            </div>
                        </div>
                        <ContentDivider size="xs"/>
                    </div>;

                    return null;
                }
            })
                :null}

        </>
    );
}

export default UsageMetricViewer;
