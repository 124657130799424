import {ApplyTheme, GenerateTheme} from '@aktek/f3theme';

import {COLORS} from '@/constants/colors.c';

export default function XApplyTheme(_theme) {
    if (_theme?.font) {
        const html = document.documentElement;
        const defaultSize = 16;
        const fontSizeVariable = 4;
        let fontSize;

        switch (_theme?.font.fontSize) {
            case 'small':
                fontSize = `${defaultSize-fontSizeVariable}px`;
                break;
            case 'medium':
                fontSize = `${defaultSize}px`;
                break;
            case 'large':
                fontSize = `${defaultSize+fontSizeVariable}px`;
                break;
        }

        html.style.fontSize = fontSize;
        html.style.setProperty('--app-font-family', _theme?.font.fontStyle);
    } else {
        const html = document.documentElement;
        html.style.fontSize = '16px';
        html.style.setProperty('--app-font-family', '\"Inter\", sans-serif');
    }

    if (_theme?.colors) {
        const theme = GenerateTheme(_theme?.colors, false, 80);
        ApplyTheme(theme);
    } else {
        const theme = GenerateTheme(COLORS, false, 80);
        ApplyTheme(theme);
    }
}
