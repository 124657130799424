
import {Icon, Label} from '@aktek/f4kit';
import {faCrown} from '@fortawesome/free-solid-svg-icons';
import {faAdd, faPen, faTrash} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import F4DeleteModal from '@/components/F4DeleteModal/F4DeleteModal';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import AskForModal from '@/components/Modals/AskForModal';
import {TColumn, TGetRightClickRowActions} from '@/components/Table/Types/Table.t';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import CreateUserModal from '../Users/components/CreateUserModal';
import {CreateRoleAndPermissionModal} from './components/CreateRoleAndPermissionModal';

export const ROLES_COLUMNS = (): Array<TColumn> => {
    const {roles} = useAdminPermissions();

    return [
        {key: 'name', header: 'Role', type: 'string', isSortable: true, renderCell(row) {
            const cell = row?.row?.original;

            return <div className="flex flex-row items-center  gap-1">
                {cell.overridePermissions && <Icon icon={faCrown} color="warning-300" />}
                <Label text={cell.name} />
            </div>;
        }},
        {key: 'description', type: 'string', header: 'Description', isSortable: true},
        {key: 'users', type: 'string', header: 'Users', renderCell(row, cell) {
            return (
                <div>
                    {roles?.can_edit && <Icon
                        icon={faAdd}
                        className="border border-neutral-300 p-1.5 rounded-full border-dashed bg-white"
                        tooltip={strings('add_user')}
                        size="md"
                        color="neutral-900"
                        onClick={() => AskForCreationModal(CreateUserModal, {state: {mode: 'create', _id: null}})}
                    />}
                </div>
            );
        }},
        {
            key: 'actions',
            header: '',
            type: 'actions',
            actions: {
                delete: {
                    tooltip: strings('delete'),
                    isVisible: roles?.can_delete,
                },
                edit: {
                    tooltip: strings('edit'),
                    isVisible: roles?.can_edit,
                },
            },
        },
    ];
};

export const GetRightClickRowRolesActions: TGetRightClickRowActions = (permission) => (refreshTable, deleteModalRef) => (row) => {
    return _compact([
        permission?.can_delete && {
            label: strings('delete'),
            borderButton: true,
            icon: faTrash,
            onClick: () => deleteModalRef?.current.open({
                id: row.original.id,
                name: row.original.name,
                message: strings('role_message_delete'),
            }),
        },
        permission?.can_edit && {
            label: strings('edit'),
            icon: faPen,
            onClick: () => AskForCreationModal(
                CreateRoleAndPermissionModal,
                {
                    state: {
                        mode: 'edit',
                        _id: row.original.id,
                    },
                    refreshTable,
                }),
        },
    ]);
};
