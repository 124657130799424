import {TagService} from '@aktek/morph-frontend-sdk';

const useTags = () => {
    const storeTag = async (formData, mode) => {
        if (mode != 'edit') {
            const tagRes = await TagService.storeTag(formData.tag);

            if (!tagRes.isSuccessful?.()) {
                return {
                    isSuccessful: () => false,
                };
            }
        }

        if (formData.color) {
            const colorRes = await TagService.setTagColor(formData.tag, formData.color);

            if (colorRes.isSuccessful()) {
                return {
                    isSuccessful: () => true,
                };
            }

            return {
                isSuccessful: () => false,
            };
        }

        return {
            isSuccessful: () => true,
        };
    };

    const getTagByID = async (id) => {
        const tagColor = await TagService.getTagColors();
        if (!tagColor.isSuccessful()) return;
        const color = tagColor.getData();

        const data = {
            tag: id,
            color: color[id],
        };

        return {
            isSuccessful: () => true,
            getData: () => data,
        };
    };

    const getTableData = async (search, sort, offset, pageSize) => {
        let tagsData;
        let isSuccess = true;

        if (search) {
            const res = await TagService.getOptions(search);

            if (!res.isSuccessful()) {
                isSuccess = false;

                return {isSuccessful: () => false, getData: () => null};
            }

            tagsData = res.getData();
        } else {
            const res = await TagService.getAllTags();

            if (!res.isSuccessful()) {
                isSuccess = false;

                return {isSuccessful: () => false, getData: () => null};
            }

            tagsData = res.getData();
        }

        const res = await TagService.getTagColors();

        if (!res.isSuccessful()) {
            isSuccess = false;

            return {isSuccessful: () => false, getData: () => null};
        }

        const tagColorsData = res.getData();
        const combinedData = tagsData?.map((tag) => {
            return ({
                Tag: [{label: tag.label, color: tagColorsData[tag.value] || ''}],
                Color: [{color: tagColorsData[tag.value] || '', isSquare: false}],
            });
        });

        return {
            isSuccessful: () => isSuccess,
            getData: () => combinedData,
        };
    };

    return {
        storeTag,
        getTableData,
        getTagByID,
    };
};

export default useTags;
