
import 'react-virtualized/styles.css';

import {Label} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

import {ACLNames, PERMISSION_FIELD} from '../constants/ACLNames';
import {checkBoxStyle, divStyle, expandDivStyle, Sizes} from '../constants/PermissionConstants';
import ScopesACL from '../constants/ScopesACL';
import QuickSetToggle from './QuickSetToggle';

const PermissionHeaderRow = ({
    selectedSection,
    style,
    permissions,
    onToggleHelperClick,
    onSelectAll,
    setIsSuperAdmin,
    isAllSelected,
    isSuperAdmin,
    disabled,
}) => {
    const acls = ScopesACL[selectedSection].acls;

    return (
        <div
            style={{
                borderBottomColor: '#d9d9d9',
                marginTop: '0px',
                display: 'block',
                ...style,
            }}
        >
            <div
                className={'ReactVirtualized__Table__row'}
                style={{height: Sizes.rowHeight + 'px'}}
                role="row">
                <div className="ReactVirtualized__Table__rowColumn" role="gridcell"
                    style={{width: '12px', ...expandDivStyle, textAlign: 'left'}}>

                </div>
                <>
                    <div className="ReactVirtualized__Table__rowColumn !flex !flex-row !gap-1 !items-center" role="gridcell"
                        style={{...divStyle, textAlign: 'left', width: '40%'}}>
                        {!disabled && selectedSection !== 'app' && <QuickSetToggle
                            idqa={`toggleAll_${selectedSection}`}
                            active={!disabled}
                            state={isAllSelected}
                            selectedSection={selectedSection}
                            onClick={onSelectAll}

                            setIsSuperAdmin={setIsSuperAdmin}
                            isSuperAdmin={isSuperAdmin}
                            category="all"/>}
                        {!disabled && selectedSection !== 'app' && <Label text={strings('select all')}
                            color="neutral-700" className="normal-case" size="sm" fontWeight="normal" />}
                    </div>
                </>
                {acls.map((acl, index) => {
                    const columnState = getColumnState(permissions, selectedSection, acl);

                    return <td key={`PermissionRow_Perm_${index}`}
                        className="ReactVirtualized__Table__rowColumn flex items-center justify-end flex-row-reverse  gap-1"
                        role="gridcell"
                        style={checkBoxStyle}
                        align="center">
                        <Label text={ACLNames[acl]} color="neutral-700" className="normal-case" size="sm" fontWeight="normal" />
                        <QuickSetToggle
                            idqa={`toggleAll_${selectedSection}_${acl}`}
                            active={!disabled}
                            state={columnState}
                            aclType={acl}
                            selectedSection={selectedSection}
                            onClick={onToggleHelperClick}
                            category="vertical_all"
                            setIsSuperAdmin={setIsSuperAdmin}
                            isSuperAdmin={isSuperAdmin}
                        />
                    </td>;
                })}
            </div>
        </div>
    );
};

export default PermissionHeaderRow;

const getColumnState = (permissions, selectedSection, acl) => {
    let columnState;

    const filteredPermissions = permissions[selectedSection]?.filter((val) => {
        const aclIndexes = val.aclType ? String(val.aclType).split('').map(Number) : [];
        const indexOfAcl = PERMISSION_FIELD.indexOf(acl);

        return aclIndexes.length > 0 ? aclIndexes[indexOfAcl] == 1 : true;
    });

    if (filteredPermissions?.every((val) => val[acl] === 1)) {
        columnState = true;
    }

    if (!columnState && permissions[selectedSection]?.every((val) => val[acl] === 0)) {
        columnState = false;
    }

    if (columnState === undefined) {
        columnState = null;
    }

    return columnState;
};
