import countries from '@/constants/countries.c';

export default [
    {label: 'users'},
    {label: 'amp', feature: 'amp', unit: 'per_calendar_month', isRolling: true},
    {label: 'amp_wlc', feature: 'amp', isMulti: true, type: 'dropdown', options: countries, isTracked: false},
    {label: 'amp_time_range_limit', feature: 'amp', unit: 'days', isTracked: false},
    {label: 'amp_history_limit', feature: 'amp', unit: 'months', isTracked: false},
    {label: 'total_records'},
    {label: 'active_records'},
    {label: 'deleted_records'},
    {label: 'fields'},
    {label: 'reports'},
    {label: 'folders'},
    {label: 'dashboard', feature: 'analysis'},
    {label: 'network', feature: 'network'},
    {label: 'bpmn', feature: 'bpmn'},
    {label: 'cac', feature: 'cac'},
    {label: 'ccol', feature: 'ccol'},
    {label: 'report_history'},
    {label: 'record_field_history'},
    {label: 'notifications'},
    {label: 'user_groups'},
];
