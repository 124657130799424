import {LoadingIndicator, TActionsModalController, TreeView} from '@aktek/f4kit';
import {RecentlyUsedService} from '@aktek/morph-frontend-sdk';
import {TreeViewProps} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/TreeView/Types/TreeViewProps';
import {useEffect, useRef, useState} from 'react';

import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {strings} from '@/localization/i18n';

import {getTreeItems} from '../utils/getTreeItems.fn';
import DeleteModal from './DeleteModal';
import EditMoveFolderModal from './EditMoveFolderModal';

export default function ExplorerTree({searchValue, items, isLoading, refresh, deleteFolder}) {
    const deleteActionRef = useRef<TActionsModalController>();

    const [rightClickedCard, setRightClickedCard] = useState({});
    const [defaultExpanded, setDefaultExpanded] = useState(true);

    // This function is to make the tree nodes open on search
    // todo: refactor this
    useEffect(() => {
        if (searchValue !== '') {
            setDefaultExpanded(false);
            setTimeout(() => {
                setDefaultExpanded(true);
                ls.foldersAndData = true;
                ls.shortcuts = true;
                ls.repositories = true;
            }, 1);
        }
    }, [searchValue]);

    const data = getTreeItems(items, searchValue);

    const foldersRightClickItems = [
        {label: 'Edit folder',
            onClick: () => AskForCreationModal(EditMoveFolderModal, {state: {mode: 'edit', _id: rightClickedCard.id}, data: items, item: rightClickedCard, refresh}),
            borderButton: true,
        },
        {label: 'Delete folder',
            onClick: deleteActionRef.current?.open,
        },
    ];

    const reportsRightClickItems = [
        {label: 'Open',
            onClick: async () => {
                const label = rightClickedCard.label;
                const id = rightClickedCard.id;
                const type = rightClickedCard.type;

                focusOrOpenNewTab('dataStructure', label, {label, id});
                await RecentlyUsedService.updateRecentTabs(label, label, {open: 'dataStructure', sboId: id, type: type});
                await refresh?.();
            },
            borderButton: true,
        },
        {label: 'Edit'},
        {label: 'Move', borderButton: true},
    ];

    const treeNodeSections = ['folders__data', 'shortcuts', 'repositories'];

    const treeViewConditionalProps = {
        size: 'xs',
        treeState: null,
        contextMenu: {folders: foldersRightClickItems, reports: reportsRightClickItems},
        checkMode: 'none',
        node: null,
        defaultExpanded: searchValue !== '',
        nodeMoveTargetFilter: (e) => e.type == 'folder',
        onNodeMove: () => {},
        onTreeStateChange: () => {},
        setRightClickedItem: setRightClickedCard,
        labelProps: {
            color: 'neutral-900',
            fontWeight: 'normal',
        },

        onCheckChange: () => {},
        onNodeClick: async ({type, label, id}) => {
            if (treeNodeSections.includes(id)) {
                ls[id] = !ls[id];
            }

            if (type == 'report') {
                focusOrOpenNewTab('dataTable', label, {label, sboId: id});
                await RecentlyUsedService.updateRecentTabs(label, label, {open: 'dataTable', type: type, sboId: id});
                await refresh?.();

                return;
            }

            if (type == 'geo') {
                focusOrOpenNewTab('geoRepository');
                await RecentlyUsedService.updateRecentTabs(label, label, {open: 'geoRepository', sboId: id, type: type});
                await refresh?.();

                return;
            }
        },
    } as Partial<TreeViewProps>;

    return <div className="py-2 pl-5 h-full w-full overflow-y-auto">
        {data && data.length > 0
            ? defaultExpanded && <TreeView data={data} {...treeViewConditionalProps} />

            :!isLoading && <p className="flex items-center mt-3 ml-2 overflow-hidden text-neutral-500">{strings('no_results_found')}</p> }
        <DeleteModal deleteFolder={deleteFolder} ref={deleteActionRef} item={rightClickedCard}/>

        {(!data || isLoading) && <div className="absolute inset-0 h-screen !shadow-none bg-neutral-200 opacity-50 elevation-5 flex items-center justify-center">
            <LoadingIndicator size="xl" />
        </div>}
    </div>;
}
