import {GetColorBrightness, GetRBGfromHex} from '@/helpers/Colors.fn';

export const GetTagTextColor = (hexColor) => {
    if (!hexColor || !(hexColor.length > 0)) return 'black';

    const {red, green, blue} = GetRBGfromHex(hexColor);

    const brightness = GetColorBrightness(red, green, blue);

    return brightness > 125 ? 'black' : 'white';
};
