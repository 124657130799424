
import {faPen} from '@fortawesome/pro-regular-svg-icons';

import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {TColumn, TGetRightClickRowActions} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

import CreateLicenseModal from './components/CreateLicenseModal';

export const LICENSING_COLUMNS = ():Array<TColumn> => [
    {
        key: 'plan',
        header: strings('plan_name'),
        type: 'string',
        isSortable: true,
    },
    {
        key: 'creator',
        header: strings('creator'),
        type: 'string',
        isSortable: true,
    },
    {
        key: 'lastEditor',
        header: strings('last_editor'),
        type: 'string',
        isSortable: true,
    },
    {
        key: 'actions',
        header: '',
        type: 'actions',
        actions: {
            edit: {tooltip: strings('edit')},
        },
    },
];

export const GetRightClickRowLicensingActions: TGetRightClickRowActions = (permission) => (refreshTable, deleteModalRef) => (row) => {
    return [
        {
            label: strings('edit'),
            icon: faPen,
            onClick: () => AskForCreationModal(CreateLicenseModal, {state: {mode: 'edit', _id: row.original._id}, refreshTable}),
        },
    ];
};

