import axios from 'axios';
import _isString from 'lodash/isString';

// @TODO: all this function needs to be reviewed and refactored

const downloadPlainFile = async (url, title, extension) => {
    let finalExtension = extension;

    if (!finalExtension) {
        finalExtension = await getFinalExtension(url, title);
    }

    const nameSuffix = getNameSuffix(title);
    const fileExt = nameSuffix.split('.').pop();
    let qualifiedName = nameSuffix;

    if (!possibleExtensions[fileExt] && finalExtension) {
        qualifiedName = `${nameSuffix}.${finalExtension}`;
    }

    try {
        const response = await axios({
            url,
            method: 'GET',
            responseType: 'blob',
            params: {
                mode: 'no-cors',
            },
            withCredentials: false,
        });
        const _url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = _url;
        link.setAttribute('download', `${qualifiedName}`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.log(error);
    }
};

const getFinalExtension = async (url, title) => {
    if (title?.extension) return title.extension;

    if (url) {
        const extension = await getContentTypeFromUrl(url) || getUrlExtension(url);

        if (extension) return extension;
    }

    if (title?.name) return getUrlExtension(title.name);

    return 'txt';
};

const getContentTypeFromUrl = async (url) => {
    try {
        const response = await axios({url, method: 'HEAD', params: {mode: 'no-cors'}});
        const contentType = response?.headers?.['content-type'];
        const extension = mimeToExtension(contentType);

        return extension;
    } catch (error) {
        return null;
    }
};

const mimeToExtension = (mime)=>{
    if (!mime) {
        return;
    }

    const mp = {
        'image/jpg': 'jpg',
        'image/png': 'png',
        'image/bmp': 'bmp',
        'image/jpeg': 'jpeg',

        'video/webm': 'webm',
        'video/ogg': 'ogg',
        'video/mp4': 'mp4',

    };

    return mp[mime];
};

const getUrlExtension = (url) => url.
    split(/[#?]/)[0].split('.').pop().trim();

const getNameSuffix = (title) => title?.name || title?.key || (_isString(title) ? title : 'download');

export default downloadPlainFile;

const possibleExtensions = {
    'jpg': 1,
    'png': 1,
    'bmp': 1,
    'jpeg': 1,

    'webm': 1,
    'ogg': 1,
    'mp4': 1,

    'pdf': 1,
    'ppt': 1,
    'pptx': 1,

    'kmz': 1,
    'xlsx': 1,
    'xls': 1,

};
