
import Helios from '@aktek/helios-frontend-sdk';
import Morph from '@aktek/morph-frontend-sdk';
import Plutus from '@aktek/plutus-frontend-sdk';
import Theia from '@aktek/theia-frontend-sdk';

import env from '@/config/env';

import RequestBuilder from './RequestBuilder';

export default ({onResponse, onError}) => {
    const request = RequestBuilder({onResponse, onError});

    Helios.init(request, env.baseURL);
    Morph.init(request, env.baseURL);
    Theia.init(request, env.baseURL);
    Plutus.init(request, env.baseURL);
};
