import {Drawer, TabNav, useTab} from '@aktek/f4kit';
import {faClockRotateLeft, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import React, {useEffect} from 'react';

import F4Sidebar from '@/components/F4Sidebar/F4Sidebar';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import AdvancedHeader from './components/AdvancedHeader';
import DeleteAllData from './pages/DeleteAllData/DeleteAllData';
import RestoreFields from './pages/RestoreFields/RestoreFields';

export default function Advanced(props) {
    const tab = useTab();
    useEffect(() => {
        props.selectedTab && tab.setState({selectedTab: props.selectedTab});
    }, [tab.config?.tabSelectedAt]);

    const selectedTab = tab.state?.selectedTab || 'restoreFields';

    const [SBODictionary, setSBODictionary] = React.useState({});
    const [selectedDataStructure, setSelectedDataStructure] = React.useState(null);

    useDocumentTitle(strings('advanced'));

    const onTabChange = (selectedTab) => {
        tab.setState({selectedTab});
        setSelectedDataStructure(null);
    };

    return (
        <div className="flex h-full">
            <Drawer
                defaultOpen={ls.drawer?.advanced ?? true}
                onOpenChange={(e) => openCloseDrawer('advanced', !!e)}
                tooltipDelay={1000}

                sideWidth={96}
                buttonSize="sm"
                className="sm:!w-2/4 md:!w-1/4  !w-11/12"
            >

                <F4Sidebar>
                    <TabNav
                        value={selectedTab}
                        onChange={onTabChange}
                        orientation="vertical"
                        defaultValue={selectedTab || 'restoreFields'}
                        tabs={[
                            {key: 'restoreFields', label: 'Restore Fields', icon: faClockRotateLeft},
                            {key: 'deleteAllData', label: 'Delete All Data', icon: faTrashCan},
                        ]}
                        className="overflow-x-visible"
                        buttonClassName="[&>:first-child]:[&>:first-child]:[&>:first-child]:!w-8"
                    />
                </F4Sidebar>

                <div className="bg-neutral-50 h-full w-full flex flex-col p-4">
                    <AdvancedHeader
                        selectedTab={selectedTab}
                        SBODictionary={SBODictionary}
                        setSBODictionary={setSBODictionary}
                        selectedDataStructure={selectedDataStructure}
                        setSelectedDataStructure={setSelectedDataStructure}
                    />

                    {selectedTab === 'restoreFields' && <RestoreFields selectedDS={selectedDataStructure}/>}

                    {selectedTab === 'deleteAllData' && <DeleteAllData/> }
                </div>
            </Drawer>
        </div>
    );
}

