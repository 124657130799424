import {useF4FormRef} from '@aktek/f4form';
import {toast} from '@aktek/f4kit';
import {CompanySettingsService} from '@aktek/helios-frontend-sdk';
import {isEmpty} from 'lodash';
import {useEffect, useState} from 'react';

import XApplyTheme from '@/helpers/ApplyTheme.fn';
import {strings} from '@/localization/i18n';

import {DEFAULT_APPEARANCE} from '../constants/Appearance.c';

const useAppearance = () => {
    const appearanceFormRef = useF4FormRef();
    const [initialValue, setInitialValue] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getDesignSettings();
    }, []);

    function isEmptyOrAllNull(obj) {
        return (
            !obj // null or undefined
            || (Object.keys(obj).length === 0) // empty object
            || Object.values(obj).every((value) => value === null) // all values are null
        );
    }

    const getDesignSettings = async () => {
        setIsLoading(true);
        const design = await CompanySettingsService.getDesignSettings();

        if (design.isSuccessful?.()) {
            if (isEmptyOrAllNull(design.getData().appearance)) setInitialValue({appearance: DEFAULT_APPEARANCE});
            else setInitialValue(design.getData());
        }

        setIsLoading(false);
    };

    const handleSave = async () => {
        setIsLoading(true);

        if (appearanceFormRef.current.validate().isFormValid) {
            const settings = appearanceFormRef.current.getData();
            const res = await CompanySettingsService.storeDesignSettings({appearance: settings});
            if (!res.isSuccessful()) return;
            setInitialValue({appearance: settings});

            const theme = {
                colors: settings.colors,
                font: settings.font,
                images: settings.images,
            };
            ls.theme = theme;
            XApplyTheme(theme);
        }

        setIsLoading(false);
        toast.success(strings('settings_saved_successfully'));
    };

    const handleReset = () => {
        const setData = appearanceFormRef.current.setData;
        setData(DEFAULT_APPEARANCE);
        XApplyTheme(ls.theme);
    };

    const handleCancel = () => {
        const setData = appearanceFormRef.current.setData;
        setData(initialValue?.appearance);
        XApplyTheme(ls.theme);
    };

    return {
        isLoading,
        setIsLoading,
        appearanceFormRef,
        initialValue,
        getDesignSettings,
        handleSave,
        handleCancel,
        handleReset,
    };
};

export default useAppearance;
