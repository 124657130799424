import React from 'react';

import InjectAsyncElement from '@/utils/InjectAsyncElement';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function AskForModal<T = Record<string, unknown>>(Element: React.ComponentType<any>, props?: T) {
    InjectAsyncElement((resolve, reject, remove) => {
        return <Element {...props} openOnMount={true} onClose={remove}/>;
    });
}
