
export const LICENSE_FEATURES: {
    name: string;
    value: boolean | number;
    key: string;
}[]= [
    {name: 'iQ Areas - Simple query', value: false, key: 'amp'},
    {name: 'iQ Areas - Monitors', value: false, key: 'amp_monitor'},
    {name: 'iQ Areas KMZ Uploader', value: false, key: 'amp_kmz_uploader'},
    {name: 'Download iQ Areas Data', value: false, key: 'download_amp'},
    {name: 'iQ Areas - Country Query', value: false, key: 'amp_country_search'},
    {name: 'iQ Areas - Device Info', value: false, key: 'amp_device_info'},
    {name: 'iQ Areas - IP query', value: false, key: 'amp_ip_devices'},
    {name: 'iQ Areas AIDs To Device IDs', value: false, key: 'amp_aid_to_id'},
    {name: 'iQ Areas Device IDs To AIDs', value: false, key: 'amp_id_to_aid'},
    {name: 'iQ Areas Light Area Query', value: false, key: 'amp_light_area_query'},
    {name: 'iQ Incidents', value: false, key: 'iq_incidents'},
    {name: 'iQ Hazards', value: false, key: 'fire_data'},
    {name: 'iQ Social', value: true, key: 'media'},
    {name: 'iQ Web', value: false, key: 'ota'},
    {name: 'Companies', value: false, key: 'agency'},
    {name: 'Advanced Data Actions', value: false, key: 'advanced_data_actions'},
    {name: 'Analysis - Dashboards', value: true, key: 'analysis'},
    {name: 'Generate Public Link', value: false, key: 'public_link'},
    {name: 'Analysis - Data Toolbox', value: false, key: 'data_toolbox'},
    {name: 'Audit Trail', value: true, key: 'audit'},
    {name: 'Automation', value: false, key: 'automation'},
    {name: 'Conditional Coloring', value: true, key: 'ccol'},
    {name: 'Download Data', value: true, key: 'download'},
    {name: 'Tag-Based Access Control', value: false, key: 'tac'},
    {name: 'Multi-lingual Support', value: true, key: 'translation'},
    {name: 'Whitelabel', value: true, key: 'whitelabel'},
    // {name: 'Advanced Data Actions', value: false, key: 'advanced_data_actions'},
    // {name: 'Companies', value: false, key: 'agency'},
    // {name: 'iQ Areas', value: false, key: 'amp'},
    // {name: 'iQ Areas AIDs To Device IDs', value: false, key: 'amp_aid_to_id'},
    // {name: 'iQ Areas Country Query', value: false, key: 'amp_country_search'},
    // {name: 'iQ Areas Device Info', value: false, key: 'amp_device_info'},
    // {name: 'iQ Areas Device IDs To AIDs', value: false, key: 'amp_id_to_aid'},
    // {name: 'iQ Areas IP Search Devices', value: false, key: 'amp_ip_devices'},
    // {name: 'iQ Areas IP Search Signals', value: false, key: 'amp_ip_signals'},
    // {name: 'iQ Areas KMZ Uploader', value: false, key: 'amp_kmz_uploader'},
    // {name: 'iQ Areas Light Area Query', value: false, key: 'amp_light_area_query'},
    // {name: 'iQ Areas Monitor', value: false, key: 'amp_monitor'},
    // {name: 'Analysis', value: true, key: 'analysis'},
    // {name: 'Audit Trail', value: true, key: 'audit'},
    // {name: 'Automation', value: false, key: 'automation'},
    // {name: 'Business Process Management', value: true, key: 'bpmn'},
    // {name: 'Conditional Coloring', value: true, key: 'ccol'},
    // // {name: 'Chat', value: true, key: 'chat'},
    // {name: 'Data Toolbox', value: false, key: 'data_toolbox'},
    // {name: 'Download Data', value: true, key: 'download'},
    // {name: 'Download iQ Areas Data', value: false, key: 'download_amp'},
    // {name: 'Fire Data', value: false, key: 'fire_data'},
    // {name: 'iQ Incidents', value: false, key: 'iq_incidents'},
    // {name: 'Lock Support Out', value: false, key: 'lock_support'},
    // {name: 'iQ Social', value: true, key: 'media'},
    // {name: 'Network', value: true, key: 'network'},
    // {name: 'iQ Web', value: false, key: 'ota'},
    // {name: 'Generate Public Link', value: false, key: 'public_link'},
    // {name: 'Tag-Based Access Control', value: false, key: 'tac'},
    // {name: 'Multi-lingual Support', value: true, key: 'translation'},
    // {name: 'Whitelabel', value: true, key: 'whitelabel'},
];

export const LIMITS_FOR_METRICS: {
    name: string;
    value: boolean | number | string[];
    key: string;
}[] = [
    // {name: 'The number of users', value: 50, key: 'users'},
    // {name: 'The total number of records', value: 0, key: 'total_records'},
    // {name: 'The number of active records', value: 3000000, key: 'active_records'},
    // {name: 'The number of deleted records', value: 0, key: 'deleted_records'},
    // {name: 'The number of fields', value: 0, key: 'fields'},
    // {name: 'The number of report structures', value: 0, key: 'reports'},
    // {name: 'The number of folders', value: 0, key: 'folders'},
    // {name: 'The number of dashboards', value: 0, key: 'dashboard'},
    // {name: 'The number of networks', value: 0, key: 'network'},
    // {name: 'The number of business processes', value: 0, key: 'bpmn'},
    // {name: 'The number of reports with conditional access control', value: 0, key: 'cac'},
    // {name: 'The number of reports with conditional coloring', value: 0, key: 'ccol'},
    // {name: 'The number of report structure history records', value: 0, key: 'report_history'},
    // {name: 'The number of field history records', value: 0, key: 'record_field_history'},
    // {name: 'The number of notifications', value: 0, key: 'notifications'},
    // {name: 'The number of user groups', value: 0, key: 'user_groups'},
    {name: '[iQ Areas] The number of queries (per calendar month)', value: 0, key: 'amp'},
    {name: '[iQ Areas] The countries white list', value: [], key: 'amp_wlc'},
    {name: '[iQ Areas] The historical time limit (months)', value: 0, key: 'amp_history_limit'},
    {name: '[iQ Areas] The time range limit (days)', value: 0, key: 'amp_time_range_limit'},
];
