import {FitHeight} from '@aktek/f4kit';
import {NetworkService} from '@aktek/theia-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';

import DashboardCreatorModal from '../components/DashboardCreatorModal';
import {DashboardOnRowAction, NETWORK_COLUMNS} from '../constants/NetworksConstants.c';

const Analysis = ({}): React.ReactElement => {
    return <div className="flex items-start bg-neutral-50 px-4 py-3 h-full">

        <FitHeight className="h-full w-full pb-1">
            <F4ViewableComponent noPagination
                hasSearch={false}
                tableColumns={NETWORK_COLUMNS}
                onRowAction={DashboardOnRowAction}
                modal={DashboardCreatorModal}
                getAllData={NetworkService.getNetworks}
                getDetailData={NetworkService.getNetworkById}
            />
        </FitHeight>
    </div>;
};

export default Analysis;
