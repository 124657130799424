import {Authentication_TokensService as AuthenticationTokensService} from '@aktek/helios-frontend-sdk';
import {useEffect} from 'react';

import {useSignUserOut} from '@/context/UserContext/Hooks/useSignUserOut';

export default function VerifyToken() {
    const signOut = useSignUserOut();

    const verify = async () => {
        const response = await AuthenticationTokensService.verifyToken(ls.token);
        const verified = response.isSuccessful?.();

        // ▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥
        if (!verified) signOut();
        // ▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥▥
    };

    useEffect(() => {
        ls.token && verify();
    }, []);
}
