// TODO: remove this
const DEV_MODE_API = 'dev.aktek.io';
const BASE_URL = localStorage.devMode === 'true' ? DEV_MODE_API : process.env.BASE_URL;

const SCHEMA = 'https://';

const getImageURL = (fileName) => {
    return getURL(process.env.UPLOAD_FILE_HOST || 'sandbox.aktek.io/media') + '/' + fileName;
};

const getURL = (url) => SCHEMA + url;

const baseURL = getURL(BASE_URL || '');
const GoogleMapLatLngAPI = getURL(process.env.GOOGLE_MAP_LAT_LNG_API);

const Env = {
    getURL,
    baseURL,
    getImageURL,
    GoogleMapLatLngAPI,
};

export default Env;
