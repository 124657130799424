import {useEffect, useState} from 'react';
import {object, ref, string} from 'yup';

import {strings} from '@/localization/i18n';

import {TLoginValidationResult, TSetPasswordValidationResult} from '../types/loginTypes.t';

export const LoginSchema = object().shape({
    email: string().email(strings('invalid_email')).required(strings('email_required')).test(
        'is-valid-email',
        strings('invalid_email'),
        (value) => {
            if (!value) return true;
            const atIndex = value.indexOf('@');
            const dotIndex = value.lastIndexOf('.');

            return atIndex !== -1 && dotIndex !== -1 && dotIndex > atIndex && dotIndex < value.length - 2;
        },
    ),
    password: string().required(strings('password_required')),
});

export const useLoginValidation = (loginState)=>{
    const errors = {email: '', password: ''};
    const [validationResult, setValidationResult] = useState<TLoginValidationResult>({isValid: false, errors});

    useEffect(() => {
        const validateData = async () => {
            const isValid = await LoginSchema.isValid(loginState);

            try {
                await LoginSchema.validate(loginState, {abortEarly: false});

                setValidationResult({isValid, errors});
            } catch (error) {
                error.inner.forEach((err) => errors[err.path]= err.message );
                setValidationResult({isValid, errors});
            }
        };

        validateData();
    }, [loginState]);

    return {validationResult};
};

export const SetPasswordSchema = object().shape({
    password: string().required(strings('password_required')).matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
    confirmPassword: string().required(strings('password_required')).oneOf([ref('password')], 'Passwords do not match'),
});

export const useSetPasswordValidation = (newPassword)=>{
    const errors = {password: '', confirmPassword: ''};

    const [validationResult, setValidationResult] = useState<TSetPasswordValidationResult>({isValid: false, errors});

    useEffect(() => {
        const validateData = async () => {
            const isValid = await SetPasswordSchema.isValid(newPassword);

            try {
                await SetPasswordSchema.validate(newPassword, {abortEarly: false});

                setValidationResult({isValid, errors});
            } catch (error) {
                error.inner.forEach((err) => errors[err.path]= err.message );
                setValidationResult({isValid, errors});
            }
        };

        validateData();
    }, [newPassword]);

    return {validationResult};
};
