import {ActionsModal, Icon, Label, TActionsModal, toast} from '@aktek/f4kit';
import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

import {strings} from '@/localization/i18n';

import {getDeleteClasses} from './F4Delete.styles';

export type TF4DeleteModalProps = {
    actionModalProps?: Partial<TActionsModal>;
    className?: string;
    name: string;
    message?: string;
    deleteFunction: (T) => void;
    deleteIcon?: boolean;
    onDelete?: (T?) => void;
    deleteSuccessMessage?: string;
    openOnMount?: boolean;
}

const F4DeleteModal = ({
    deleteFunction,
    actionModalProps,
    deleteIcon,
    openOnMount,
    name,
    message = 'delete_sentence',
    onDelete,
    deleteSuccessMessage,
}: TF4DeleteModalProps, ref:React.ForwardedRef<unknown>) => {
    const classes = getDeleteClasses();
    const [id, setId] = useState(null);
    const [_name, setName] = useState(name);
    const [_message, setMessage] = useState(message);
    const modalRef = useRef();

    useEffect(()=>{
        if (openOnMount) modalRef.current.open();
    }, []);

    useImperativeHandle(ref, () => ({
        open: ({id = null, name = 'Name', message = 'delete_sentence'} = {}) => {
            setId(id);
            setName(name);
            setMessage(message);

            modalRef.current?.open?.();
        },
        close: () => modalRef.current?.close?.(),
    }), []);

    const _deleteItem = async function() {
        if (!deleteFunction) return null;
        const res = await deleteFunction(id);

        if (res.isSuccessful()) {
            toast.success(deleteSuccessMessage);
            ref.current.close();
            // setIsLoading(false);
            onDelete?.();
        }
    };

    return (
        <ActionsModal
            hasXIcon
            ref={modalRef}
            className={classes.actionsModal}
            confirmLabel={strings('delete')}
            confirmVariant="error"
            onConfirm={_deleteItem}
            {...actionModalProps}
        >
            <div className={classes.formDiv}>
                {deleteIcon && <div className={classes.iconDiv}>
                    <Icon icon={faTrashAlt} color="error-600" className={classes.icon} />
                </div>}

                <div className={classes.labelDiv}>
                    <Label text={`${strings('delete')} ${_name}`} fontWeight="semibold" size="lg" className={classes.deleteDiv} />
                    <Label className="text-center" size="sm" text={strings(_message)} />
                </div>
            </div>
        </ActionsModal>
    );
};

export default forwardRef(F4DeleteModal);
