import {F4FormStep} from '@aktek/f4form';

import F4TextBox from '@/components/F4Elements/F4TextBox';
import {strings} from '@/localization/i18n';

export function RoleInfo() {
    return (
        <F4FormStep step={'roleInfo'}>

            <F4TextBox
                colSpan={12}
                required
                name="name"
                size="sm"
                className="!font-medium"
                label={strings('role_name')}
            />

            <F4TextBox
                colSpan={12}
                name="description"
                size="sm"
                className="!font-medium"
                label={strings('description')}
                showOptional
            />
        </F4FormStep>
    );
}

export default RoleInfo;
