import {MorphService} from '@aktek/morph-frontend-sdk';

import getOrderFromDescending from '@/helpers/getOrderFromDescending';

const getRows = (sboId) => async (paginationState, sortingState, countState, searchState) => {
    const sort = {id: 'designation', order: getOrderFromDescending(sortingState[0]?.desc)};

    const offset = paginationState.pageSize * (paginationState.currentPage -1);

    const response = await MorphService.retrieveMorphsBySBOId(sboId, searchState.query, sort, offset, paginationState.pageSize, null);
    const data = response.getData();

    return data as unknown[];
};

export default getRows;
