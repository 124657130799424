import {FitHeight, LoadingIndicator, Modal, useModalRef} from '@aktek/f4kit';
import {PermissionsService} from '@aktek/helios-frontend-sdk';
import _ from 'lodash';
import {useEffect, useState} from 'react';

import {strings} from '@/localization/i18n';

import PermissionEditor from './PermissionEditor';

const ViewPermission = ({id, openOnMount = true}) => {
    const [permissions, setPermissions] = useState();
    const [loading, setLoading] = useState(true);

    const getUserPermission = async () => {
        setLoading(true);
        const res = await PermissionsService.getUserPermissions(id);
        const success = res.isSuccessful();
        success && setPermissions(res?.getData());
        setLoading(false);
    };

    useEffect(()=> {
        getUserPermission();
    }, []);

    const modalRef = useModalRef();

    if (_.isEmpty(permissions)) {
        return <Modal ref={modalRef} size="xl" title={strings('view_permissions')}
            defaultIsOpened={openOnMount}
            className={'overflow-hidden !rounded-3xl h-2/6 !w-3/5'}
            headerClassName="border-b border-b-neutral-100 px-5 pt-2 pb-1 text-xl " >
            {loading ? (
                <div className="justify-center items-center w-full h-full flex" style={{background: '#eeeeee', opacity: 0.7, textAlign: 'center'}}>
                    <LoadingIndicator size="xl" />
                </div>
            )
                : <div className="px-2 py-4 text-center text-neutral-500 justify-center items-center w-full h-full flex">
                    {strings('no_permissions_for_user')}</div>}
        </Modal>;
    }

    return (
        <Modal ref={modalRef} size="xl" title={strings('view_permissions')}
            defaultIsOpened={openOnMount}
            className={'overflow-hidden !rounded-3xl h-5/6 !w-3/5'}
            headerClassName="border-b border-b-neutral-100 px-5 pt-2 pb-1 text-xl " >

            {loading ? (
                <div className="justify-center items-center w-full h-full flex" style={{background: '#eeeeee', opacity: 0.7, textAlign: 'center'}}>
                    <LoadingIndicator size="xl" />
                </div>
            )
                : <div className="px-5 py-2 h-full overflow-y-auto">
                    <FitHeight className="h-full -mt-3">

                        <PermissionEditor
                            permissions={permissions}
                            isLoading={loading}
                            disabled={true}
                        />
                    </FitHeight>
                </div>
            }

        </Modal>
    );
};

export default ViewPermission;
