import {Breadcrumb, useTab} from '@aktek/f4kit';
import {isFunction} from 'lodash';

import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import {CanEditSettings} from '@/constants/Permissions.c';
import {strings} from '@/localization/i18n';

import {GetCompanySettingsTabs} from '../constants/companySettings.c';
import ButtonSection from './ButtonSection';

type TSettingsHeaderProps = {
    onSave?: () => void;
    onFileUpload?: (T: unknown) => void;
    onFileDownload?: (T: unknown) => void;
    handleCancel?: () => void;
    handleReset?: () => void;
    appearanceFormRef?: unknown;
    isInitialValue?: boolean;
    isDefault?: boolean;
}

export default function SettingsHeader(
    {
        onSave,
        onFileUpload,
        onFileDownload,
        handleCancel,
        handleReset,
        appearanceFormRef,
        isDefault,
        isInitialValue,
    }: TSettingsHeaderProps) {
    const tab = useTab();

    const companySettingsTabs = GetCompanySettingsTabs();
    const firstVisibleTabKey = companySettingsTabs.find((tab)=>tab.isVisible)?.key;
    const _selectedTab = tab.state?.selectedTab || firstVisibleTabKey;

    const showButtons = isFunction(onSave) && isFunction(handleReset) && isFunction(handleCancel);

    return (
        <div className="flex flex-wrap justify-between items-center w-full gap-6 sticky top-0 pb-2 pr-4 bg-neutral-50 elevation-1 !shadow-none">
            <Breadcrumb
                className="!bg-neutral-50"
                size="sm"
                path={[
                    {
                        onClick: () => focusOrOpenNewTab('admin'),
                        title: strings('admin'),
                    },
                    {
                        onClick: () => tab.setState({selectedTab: firstVisibleTabKey}),
                        title: strings('company_settings'),
                    },
                    {
                        onClick: () => {},
                        title: strings(_selectedTab),
                        isDisabled: true,
                    },
                ]}
            />
            {(showButtons && CanEditSettings())
            && <ButtonSection
                onSave={onSave}
                onFileUpload={onFileUpload}
                onFileDownload={onFileDownload}
                onCancel={handleCancel}
                onReset={handleReset}
                appearanceFormRef={appearanceFormRef}
                isInitialValue={isInitialValue}
                isDefault={isDefault}
            />}
        </div>

    );
}
