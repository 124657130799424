import {Button, Icon, Label} from '@aktek/f4kit';
import {faPlus, faTag} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

type TEmptyRulesProps = {
    addNewCondition: ()=>void;
}

export default function EmptyRules({addNewCondition} : TEmptyRulesProps) {
    return (
        <div className="flex flex-col w-[32rem] mx-auto text-center justify-center items-center gap-4">
            <Icon
                icon={faTag}
                size="2xl"
                color="primary-600"
                className="bg-primary-25 rounded-full p-3"
            />

            <div className="flex flex-col gap-2">
                <Label
                    color="neutral-900"
                    fontWeight="semibold"
                    text={strings('no_rules_applied_to_company')}
                />
                <Label
                    size="sm"
                    color="neutral-600"
                    fontWeight="normal"
                    text={strings('create_new_tac_condition_instructions')}
                />
            </div>

            <Button
                label={strings('new_rule')}
                icon={faPlus}
                onClick={addNewCondition}
            />
        </div>
    );
}
