import {PermissionsService} from '@aktek/helios-frontend-sdk';
import _merge from 'lodash/merge';
import {useState} from 'react';

import {toCamelCase} from '@/helpers/strings.fn';
import {Permissions} from '@/types/Permissions/AppPermissions.t';

import {defaultPermissions} from './defaultPermissions';

export type TUsePermissionsState = {
    permissions: Permissions
    fetch: () => Promise<void>
    clear: () => Promise<void>
}

const usePermissionsState: () => TUsePermissionsState = () => {
    const [_permissions, setPermissions] = useState<Permissions>(ls.userPermission);

    const clear = () => {
        ls.userPermission = null;
        setPermissions(null);
    };

    const fetch = async () => {
        const response = await PermissionsService.getFusionPermissions();
        if (!response.isSuccessful()) return;

        const data = response.getData();
        const updatedPermissions: Permissions = data.reduce((acc, permission) => {
            const camelCaseName = toCamelCase(permission.name);
            permission.viewOrEdit = permission.can_view || permission.can_edit;
            permission.viewOrCreate = permission.can_view || permission.can_create;
            permission.viewOrCreateOrEdit = permission.can_view || permission.can_create || permission.can_edit;

            const category = permission.category;
            acc[category] = acc[category] || {};
            acc[category][camelCaseName] = permission;
            // TODO: to be removed to get rid of the old permission with spaces
            acc[category][permission.name] = permission;

            return acc;
        }, {});

        ls.userPermission = updatedPermissions;
        setPermissions(updatedPermissions);
    };

    // Destructure admin and app from permissions
    const {admin, app} = _merge(defaultPermissions, _permissions);

    const permissions = {
        app,
        admin,

        canEditSettings: admin.companySettings?.can_edit || false,

        canAccessSettings:
        admin.companySettings?.can_view
        || admin.companySettings?.can_create
        || admin.companySettings?.can_edit
        || false,

        canCreateTags: admin.tags?.can_create || false,
        canDeleteTags: admin.tags?.can_delete || false,
        canEditTags: admin.tags?.can_edit || false,

        canAccessTags: admin.tags?.can_delete || admin.tags?.can_create || admin.tags?.can_edit || false,

        canAccessCompanySettings:
        admin.companySettings?.can_view
        || admin.companySettings?.can_create
        || admin.companySettings?.can_edit
        || admin.tags?.can_delete
        || admin.tags?.can_create
        || admin.tags?.can_edit
        || false,

        canAccessRoles: admin.roles?.can_view || admin.roles?.can_create || admin.roles?.can_edit || false,
        canAccessUsers: admin.users?.can_view || admin.users?.can_create || admin.users?.can_edit || false,
        canAccessGroups: admin.groups?.can_view || admin.groups?.can_create || admin.groups?.can_edit || false,

        canAccessTAC: admin.tagBasedAccessControl?.can_view || admin.tagBasedAccessControl?.can_create || admin.tagBasedAccessControl?.can_edit || false,

        canAccessAccessControl: admin.groups?.can_view || admin.users?.can_view || admin.roles?.can_view || admin.tagBasedAccessControl?.can_view || false,

        canAccessSecurity: admin.groups?.can_view || admin.users?.can_view || admin.roles?.can_view || admin.tagBasedAccessControl?.can_view || false,

        canAccessCompanySwitcher:
        (ls?.currentCompany?.companyType === 'OPS'
            || ls?.currentCompany?.companyType === 'AGENCY')
        && (ls?.user?.userType === 'SUPERADMIN'
            || ls?.user?.userType === 'AGENCYMANAGER'
            || ls?.user?.userType === 'ACCOUNTMANAGER')
        || (ls?.currentCompany?._id !== ls?.primaryCompany?._id
            && ls?.userCompanies
            && ls?.userCompanies.length > 0)
        || false,

        canCreateCompanies: admin.companies?.can_create || false,
        canEditCompanies: admin.companies?.can_edit || false,
        canViewCompanies: admin.companies?.can_view || false,

        canAccessCompanies: admin.companies?.can_create || admin.companies?.can_edit || admin.companies?.can_view || false,

        canAccessDataStructure: admin.reportStructure?.can_view || admin.reportStructure?.can_create || false,

        canAccessConditionalColoring: admin.conditionalColoring?.can_view || admin.conditionalColoring?.can_edit || false,

        canAccessTranslation: (admin.reportStructure?.can_view && admin.translation?.can_view) || false,

        canAccessData: admin.reportStructure?.can_view || admin.conditionalColoring?.can_view || admin.translation?.can_view || false,

        canAccessAgency:
        (ls?.user?.userType !== 'REGULAR'
            && (admin.companies?.can_view || admin.companies?.can_create)
            && (ls?.currentCompany?.companyType === 'OPS'
                || ls?.currentCompany?.companyType === 'AGENCY'))
        || false,

        canAccessLicense: (ls?.currentCompany?.companyType === 'OPS' && ls?.user?.userType === 'SUPERADMIN') || false,

        canAccessAgencySection:
        (ls?.currentCompany?.companyType === 'OPS'
            && ls?.user?.userType === 'SUPERADMIN')
        || (ls?.user?.userType !== 'REGULAR'
            && (admin.companies?.can_view || admin.companies?.can_create)
            && (ls?.currentCompany?.companyType === 'OPS'
                || ls?.currentCompany?.companyType === 'AGENCY'))
        || false,
    };

    return {
        permissions,
        fetch,
        clear,
    };
};

export default usePermissionsState;
