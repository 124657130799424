import {Checkbox, Icon} from '@aktek/f4kit';
import {faCheck, faX} from '@fortawesome/pro-regular-svg-icons';
import {memo} from 'react';

const PERMISSION_VALUE = {
    '1': true, // YES
    '-1': false, // NO
    '0.5': true, // DefaultYes
    '0': false, // DefaultNo
    '-2': false, // Disabled
};

const CheckBox = (props) => {
    const {
        isSuperAdmin,
        setIsSuperAdmin,
        permission,
        path,
        readOnly,
        permissionId,
        aclType,
        permissionValue,
        selectedSection,
        targetId,
        onPermissionClick,
        rowIndex,
        sectionIndex,
        disabled,
    } = props;

    const hasStyle = permissionValue == 0 || permissionValue == 1 || permissionValue == -1;
    const _permissionValue = !disabled && (PERMISSION_VALUE[permissionValue + ''] || false);

    if (readOnly) {
        return (
            <div className="w-full flex items-start justify-start pl-3" >
                <Icon
                    icon={isSuperAdmin || _permissionValue ? faCheck : faX}
                    color={isSuperAdmin || _permissionValue ? 'success-600' : 'error-600'}
                    // the X icon and the Check icon has different sizes, so we need to adjust it like this:
                    size={isSuperAdmin || _permissionValue ? 'sm' : 'xs'} />
            </div>
        );
    }

    return (
        <Checkbox size="sm"
            // isDisabled={disabled || permissionValue == -2}
            className=" !rounded-md"
            isDisabled={isSuperAdmin ? false : (disabled || permissionValue == -2)}

            value={isSuperAdmin || _permissionValue}
            onChange={() => {
                if (!(disabled !== true && permissionValue !== 2 && readOnly !== true && hasStyle)) {
                    return;
                }

                onPermissionClick({
                    permission,
                    path,
                    selectedSection,
                    targetId,
                    permissionId,
                    aclType,
                    permissionValue,
                    rowIndex,
                    sectionIndex,
                });
                setIsSuperAdmin(false);
            }} />
    );
};

export default memo(CheckBox);
