import {F4FormDiv, F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';

import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {CanEditSettings} from '@/constants/Permissions.c';
import {strings} from '@/localization/i18n';

export default function SecuritySettings() {
    return (
        <F4Nestor name="security">

            <F4FormDiv colSpan={12} className="mt-8">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('security')}
                            className="text-neutral-600 font-medium"
                        />}/>
            </F4FormDiv>

            <F4ToggleSwitch
                name="settings_disable_2fa"
                colSpan={3}
                label=""
                size="md"
                isDisabled={!CanEditSettings()}
                descriptionClassName="!text-neutral-700 !font-medium"
                description={strings('disable_2FA')}
                showOptional={false}
            />

            <F4FormDiv colSpan={3}></F4FormDiv>
            <F4FormDiv colSpan={3}></F4FormDiv>

        </F4Nestor>
    );
}
