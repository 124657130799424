import {F4FormElementDecorator, F4FormStep} from '@aktek/f4form';
import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';

import F4AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import F4PhoneNumber from '@/components/F4Elements/F4PhoneNumber';
import F4TextBox from '@/components/F4Elements/F4TextBox';
import {LANGUAGES} from '@/constants/languages';
import {strings} from '@/localization/i18n';

type TUserInfoProps = {
    mode?: 'edit' | 'create';
}

export function UserInfo({mode} : TUserInfoProps) {
    // Filter LANGUAGES to show only the available ones
    const filteredLanguages: Record<TDropdownValue, TDropdownOption> = Object.keys(LANGUAGES).reduce(
        (filteredLanguages, key) => {
            if (ls.currentCompany.languages.includes(key)) {
                filteredLanguages[key as TDropdownValue] = LANGUAGES[key as TDropdownValue];
            }

            return filteredLanguages;
        },
        {} as Record<TDropdownValue, TDropdownOption>,
    );

    return (
        <F4FormStep step="userInfo">
            <F4AvatarImageSelector
                name="profilePicURL"
                title={strings('company_logo')}
                size="3xl"
                showUserIcon
                showOptional={false}
                colSpan={12}
                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                maxSizeMB={1}
            />
            <F4TextBox
                name="name"
                colSpan={12}
                required
                className="!font-medium mt-1"
                label={strings('name')}
            />

            <F4TextBox
                required
                name="email"
                type="email"
                isDisabled={mode === 'edit'}
                colSpan={12}
                className="!font-medium mt-1"
                label={strings('email_address')}
            />

            <F4PhoneNumber
                name="phone"
                label={strings('phone_number')}
                colSpan={12}
                className="mt-1"
                // showOptional
            />

            <F4Dropdown
                name="language"
                colSpan={12}
                options={filteredLanguages}
                isClearable
                className="mt-1"
                label={strings('platform_language')}
            />

        </F4FormStep>
    );
}

const F4UserInfo = F4FormElementDecorator(UserInfo);
export default F4UserInfo;
