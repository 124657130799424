import {FitHeight, TabsFlexLayout} from '@aktek/f4kit';
import {CompanySettingsService, PermissionsService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import XApplyTheme from '@/helpers/ApplyTheme.fn';

import AppHeader from './AppHeader';
import {TabFactory, TabsManager} from './Tabs';

export default function Home() {
    const getUpdatedDesign = async () => {
        const updatedDesign = await CompanySettingsService.getDesignSettings();

        const fetchedData = updatedDesign.getData()?.appearance;
        ls.theme = fetchedData;
        const theme = {
            colors: fetchedData?.colors,
            font: fetchedData?.font,
        };
        XApplyTheme(theme);
    };

    const {tabsSchema} = ls;
    useEffect(() => {
        !tabsSchema && focusOrOpenNewTab('explorer');
        getUpdatedDesign(); // @TODO: this call is buggy
    }, []);

    return (
        <FitHeight className="w-full h-full">
            <div className="px-2s">
                <AppHeader userPermission={ls.userPermission} />
            </div>
            <div className="relative overflow-hidden h-full">
                <TabsFlexLayout
                    tabFactory={TabFactory}
                    tabsManager={TabsManager}
                    onModelChange={(modelJSON, cleanModelJSON) => ls.tabsSchema = cleanModelJSON}
                    defaultSchema={tabsSchema}
                />
            </div>
        </FitHeight>
    );
}

