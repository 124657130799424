import {Navigate, Outlet} from 'react-router-dom';

import {useUserToken} from '@/context/UserContext/Hooks/useUserToken';

import Path from './Path';

const PublicRoutes =()=>{
    const isUserSignIn = useUserToken();

    return (
        !isUserSignIn ? <Outlet /> : <Navigate to={Path.Root} replace/>
    );
};

export default PublicRoutes;
