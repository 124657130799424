import {MorphService} from '@aktek/morph-frontend-sdk';

const getCount = (sboId) => async () => {
    const response = await MorphService.retrieveMorphsCountBySBOId(sboId);
    const count = response.getData();

    return count as number;
};

export default getCount;
