
import 'swiper/css/pagination';

import {PaginationOptions} from 'swiper/types';

export const pagination: PaginationOptions = {
    clickable: true,
    // renderBullet: function(index, className) {
    //     const _className = this.activeIndex === index ? 'bg-primary-900' : 'bg-primary-200';

    //     return `<span class="${_className + ' active:bg-primary-200 bg-primary-500 rounded-full w-8 h-8 text-xs text-white leading-8 text-center '}">${(index + 1)}</span>`;
    // },
};
