import {F4FormStep} from '@aktek/f4form';
import {UserRolesService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';
import F4UserSelector from '@/components/F4Elements/F4UserSelector';

export function UsersAssigned() {
    const {extra} = useF4CreatableFormContext();
    const [initialState, setInitialState] = useState();
    const mode = extra?.state?.mode;

    const fetchSelectedUsers = async () => {
        const usersResponse = await UserRolesService.getUsersByRole(extra.roleId as string);
        !initialState && setInitialState(usersResponse.getData());

        return usersResponse;
    };

    useEffect(() => {
        if (extra.roleId) {
            fetchSelectedUsers();
        }
    }, [extra.roleId]);

    return (
        <F4FormStep step={'assignedUsers'}>
            <F4UserSelector name="users"
                state={initialState}
                fetchSelectedUsers={extra?.state?.mode == 'edit' && fetchSelectedUsers}
                showOptional={false}/>
        </F4FormStep>
    );
}

export default UsersAssigned;
