import {ActionsModal, Label, TActionsModalController, toast} from '@aktek/f4kit';
import {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import {strings} from '@/localization/i18n';

import {getConfirmationModalClasses} from './styles/F4ConfirmationModal.styles';
import {TF4ConfirmationModalProps} from './types/F4ConfirmationModalProps.t';

const F4ConfirmationModal = (props: TF4ConfirmationModalProps, ref: React.ForwardedRef<unknown>) => {
    const [id, setId] = useState(null);
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmLabel, setConfirmLabel] = useState(strings('delete'));
    const [confirmVariant, setConfirmVariant] = useState('primary');
    const [onCancelCallback, setOnCancelCallback] = useState(null);
    const [onConfirmCallback, setOnConfirmCallback] = useState(null);
    const modalRef = useRef<TActionsModalController>();

    const _OnConfirm = async function() {
        if (!onConfirmCallback) return null;

        const res = await onConfirmCallback(id);

        if (res.isSuccessful()) {
            toast.success(successMessage);
            ref.current.close();
        } else {
            toast.error(errorMessage);
        }
    };

    const _OnCancel = () => {
        if (onCancelCallback) {
            onCancelCallback();
        }

        modalRef.current?.close?.();
    };

    useImperativeHandle(ref, () => ({
        open: ({id = null, description = '', title = '', successMessage = '', errorMessage = '', confirmLabel = strings('delete'), confirmVariant = 'primary', onCancel = null, onConfirm = null} = {}) => {
            setId(id);
            setDescription(description);
            setTitle(title);
            setConfirmLabel(confirmLabel);
            setErrorMessage(errorMessage);
            setSuccessMessage(successMessage);
            setConfirmVariant(confirmVariant);
            setOnCancelCallback(() => onCancel);
            setOnConfirmCallback(() => onConfirm);

            modalRef.current?.open?.();
        },
        close: () => modalRef.current?.close?.(),
    }), []);

    const classes = getConfirmationModalClasses();

    return (
        <ActionsModal
            ref={modalRef}
            className={classes.actionsModal}
            onCancel={_OnCancel}
            onConfirm={_OnConfirm}
            confirmVariant={confirmVariant}
            confirmLabel={confirmLabel}
            title={title}
        >
            <Label text={description} />
        </ActionsModal>
    );
};

export default forwardRef(F4ConfirmationModal);
