const container = 'h-screen flex sm:flex-row flex-col justify-center items-center overflow-hidden';

const content = 'w-full sm:h-screen sm:w-1/2 px-8 py-7 h-full flex justify-center items-center bg-white';

const imageContainer = 'w-96 flex flex-col';

const image = '!w-36 mb-20';

const animationContainer = 'w-screen h-1/2 sm:h-screen sm:w-1/2 bg-primary-25';

const animation = 'h-full animated-network';

export const getClasses = () => ({
    container,
    content,
    imageContainer,
    image,
    animationContainer,
    animation,
});
