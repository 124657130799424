import {Button, toast} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';

import AskForConfirmation from '@/components/Modals/AskForConfirmation';
import Table from '@/components/Table/Table';
import {strings} from '@/localization/i18n';

import {GetRestoreFieldsTableColumns} from './helpers/RestoreFields.fn';

const RestoreFields = ({selectedDS}) => {
    const getDeletedFields = async (sboId) => {
        const res = await SBOService.getDeletedFields(sboId);

        if (!res.isSuccessful()) return [];

        const data = res.getData();

        return Object.values(data);
    };

    const restoreDeletedField = async (fieldId) => {
        const res = await SBOService.restoreDeletedField(selectedDS, fieldId);

        if (res.isSuccessful()) {
            toast.success('Field restored successfully');
            getDeletedFields(selectedDS);
        }
    };

    const renderRestoreFieldButton = (row, cell) => {
        return (
            <Button
                variant="white"
                size="sm"
                label="Restore Field"
                onClick={() => AskForConfirmation(
                    strings('restore_field'),
                    strings('restore_field_notice'),
                    () => restoreDeletedField(row.original.id),
                )}
            />
        );
    };

    return (
        <div className="my-4">
            {selectedDS && (
                <Table
                    noPagination
                    key={selectedDS}
                    getColumns={() => GetRestoreFieldsTableColumns(renderRestoreFieldButton)}
                    getRows={async () => {
                        return getDeletedFields(selectedDS);
                    }}
                />
            ) }

        </div>
    );
};

export default RestoreFields;
