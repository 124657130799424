import {F4FormDiv} from '@aktek/f4form';
import {AccountManagementService, CompaniesService, Companiesv4Service, CompanyLicenseService} from '@aktek/helios-frontend-sdk';
import _pick from 'lodash/pick';
import _sortBy from 'lodash/sortBy';
import {useRef, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {useAppPermissions} from '@/context/UserContext/Hooks/useAppPermissions';
import {useCurrentCompany} from '@/context/UserContext/Hooks/useCurrentCompany';
import {useCurrentUser} from '@/context/UserContext/Hooks/useCurrentUser';
import {useUpdateUserCompanies} from '@/context/UserContext/Hooks/useUpdateUserCompanies';
import {useUserAuth} from '@/context/UserContext/Hooks/useUserAuth';
import {addDays, dateToUnix} from '@/helpers/globalDates.fn';
import {strings} from '@/localization/i18n';

import F4ActivationInfo from './ActivationInfo';
import F4CompanyInfo from './CompanyInfo';
import F4LicenseInfo from './LicenseInfo';

export type TCompanyProgram = {
    enabled?: boolean;
    features?: Record<string, boolean>;
    limits?: Record<string, boolean>;
    expiryDate?: number;
    isActive?: boolean;
    isValid?: boolean;
    issuedOn?: number;
    issuer?: string;
    licensePlan?: string;
    validity?: number;
    verificationHash?: string;
  }

const CreateCompanyModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, refreshTable, ...rest} = props;
    const userAuth = useUserAuth();
    const userCompanies = userAuth.userCompanies;
    const setUserCompanies = useUpdateUserCompanies();

    const {manageAccountManagers} = useAppPermissions();

    const [companyProgram, setCompanyProgram] = useState<TCompanyProgram>({});
    const [companyId, setCompanyId] = useState<string>();
    const [languages, setLanguages] = useState();

    const ref = useRef();
    const currentUser = useCurrentUser();
    const currentCompany = useCurrentCompany();
    // const permissions = useAdminPermissions();

    const canAccessLicense = (currentCompany?.companyType === 'OPS' || currentCompany?.companyType === 'AGENCY')
    && (currentUser?.userType === 'SUPERADMIN' || currentUser?.userType === 'AGENCYMANAGER');

    const storeCompany = async (company) => {
        const updatedCompany = {
            ...company,
            accountManagers: company.accountManagers == null ? [] : company.accountManagers,

        };

        const response = await Companiesv4Service.store(updatedCompany);

        if (!response.isSuccessful?.() ) {
            return {isSuccessful: () => false, getData: () => {}};
        }

        // Storing the new/edited company to the local storage/context so we can switch to it directly in the switch modal
        const targetCompanyData = response.getData();
        const idExists = userCompanies.some((company) => company?._id === targetCompanyData?._id);

        const newCompany = {
            ..._pick(targetCompanyData, ['_id', 'companyLogo', 'companyName', 'companyType']),
        };
        let updatedCompanies = [];

        if (idExists) {
            // replacing the existing company with the new info
            updatedCompanies = userCompanies.map((company) =>
                company._id === targetCompanyData._id
                    ? {...newCompany, ancestors: company.ancestors}
                    : company,
            );
        } else {
            updatedCompanies = [...userCompanies, {...newCompany, ancestors: [targetCompanyData?.parentCompanyId]}];
        }

        const sortedCompanies = _sortBy(updatedCompanies, 'companyName');

        setUserCompanies(sortedCompanies);

        if (!company.license || !company.license.licensePlan) {
            return {
                isSuccessful: () => true,
                getData: () => targetCompanyData,
            };
        }

        let licenseData = company.license;

        if (company?.activation?.validity) {
            const today = new Date();
            licenseData = {
                ...company.license,
                issuedOn: dateToUnix(today),
                expiryDate: dateToUnix(addDays(today, company?.activation?.validity)),
                validity: company?.activation?.validity,
                isNewLicense: true,
                isValid: !!company.license.verificationHash,
            };
        }

        const licenseRes = await CompanyLicenseService.setLicenseProgram(response.Body?._id, licenseData);

        return {
            isSuccessful: () => licenseRes.isSuccessful?.(),
            getData: () => licenseRes.getData() || licenseData,
        };
    };

    const getFunction = async (companyId) => {
        setCompanyId(companyId);

        try {
            const company = await CompaniesService.getCompanyById(companyId);

            if (!company.isSuccessful()) {
                return {
                    isSuccessful: () => false,
                    getData: () => null,
                };
            }

            let accountManagers = null;

            if (manageAccountManagers.can_access) {
                accountManagers = await AccountManagementService.getAccountManagers(companyId);

                if (!accountManagers.isSuccessful()) {
                    return {
                        isSuccessful: () => false,
                        getData: () => null,
                    };
                }
            }

            let license = null;

            if (canAccessLicense) {
                license = await CompanyLicenseService.getLicenseProgram(companyId);

                if (!license.isSuccessful()) {
                    return {
                        isSuccessful: () => false,
                        getData: () => null,
                    };
                }

                setCompanyProgram(license?.Body);
            }

            const availableLanguages = company.getData()?.languages;
            const defaultLanguage = company?.getData().defaultLanguage;

            const finalInitialValue = {
                ...company.getData() as object,
                accountManagers: manageAccountManagers.can_access ? accountManagers.getData() : [],
                languages: !availableLanguages.includes(defaultLanguage)
                && defaultLanguage
                    ? [...availableLanguages, defaultLanguage]
                    : availableLanguages,
                license: {
                    ...company.getData().license as object,
                    customizations: company.getData()?.license?.customizations ? {
                        ...company.getData()?.license?.customizations,
                        enabled: true,
                    } : {},
                },
            };
            setLanguages(finalInitialValue.languages);

            return {
                isSuccessful: () => true,
                getData: () => finalInitialValue,
            };
        } catch (error) {
            return {
                isSuccessful: () => false,
                getData: () => null,
            };
        }
    };

    return (
        <F4CreatableModal
            ref={ref}
            state={state}
            title={strings('company')}
            openOnMount={openOnMount}
            refreshTable={refreshTable}
            formKey="company"
            sideBarExtraElementPosition="top"
            extra={{companyProgram, companyId, ref, state, languages}}
            childrenClassName="p-4"
            getFunction={getFunction}
            storeFunction={storeCompany}
            createSuccessMessage={strings('create_company_success')}
            editSuccessMessage={strings('company_edit_success')}
            errorMessage={strings('company_failed')}
            steps={[
                {key: 'companyDetails', label: strings('company_details')},
                ...(canAccessLicense ? [
                    {key: 'license', label: strings('license')},
                    {key: 'activation', label: strings('activation')},
                ] : []),

            ]}
            {...rest}
        >

            <F4FormDiv>
                <F4CompanyInfo state={state}/>
                {canAccessLicense && <F4LicenseInfo />}
                {canAccessLicense && <F4ActivationInfo />}

            </F4FormDiv>

        </F4CreatableModal>
    );
};

export default CreateCompanyModal;
