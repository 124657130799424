import {TDropdownOption, TDropdownValue} from '@aktek/f4kit';

import {TColumn} from '@/components/Table/Types/Table.t';
import {useIsCompany, useIsCompanyOPS} from '@/context/UserContext/Hooks/useCurrentCompany';
import {useIsAccountManager, useIsRegularUser} from '@/context/UserContext/Hooks/useCurrentUser';
import {strings} from '@/localization/i18n';

export const USER_HISTORY_COLUMNS : TColumn[] = [
    {
        key: 'unixDate',
        header: strings('date_and_time'),
        type: 'datetime',
        isSortable: true,
    },
    {
        key: 'action',
        header: strings('action'),
        type: 'string',
        isSortable: true,
        renderCell({row}) {
            return strings(row?.original?.action);
        },
    },
    {
        key: 'initiator',
        header: strings('initiator'),
        type: 'string',
        isSortable: true,
    },
];

export const GetUserTypes: () => Record<TDropdownValue, TDropdownOption> = () => {
    const isCompany = useIsCompany();
    const isCompanyOPS = useIsCompanyOPS();
    const isRegularUser = useIsRegularUser();
    const isAccountManager = useIsAccountManager();

    if (isCompany || isRegularUser || isAccountManager) {
        return {
            'REGULAR': strings('regular'),
        };
    }

    if (isCompanyOPS) {
        return {
            'AGENCYMANAGER': strings('agencymanager'),
            'ACCOUNTMANAGER': strings('accountmanager'),
            'REGULAR': strings('regular'),
            'SUPERADMIN': strings('superadmin'),
        };
    }

    return {
        'AGENCYMANAGER': strings('agencymanager'),
        'ACCOUNTMANAGER': strings('accountmanager'),
        'REGULAR': strings('regular'),
    };
};

