
import {Cell, Row} from '@tanstack/react-table';
import _isObject from 'lodash/isObject';

import ImageVideoViewerSwiper from '@/components/Swipers/ImageVideoViewerSwiper';
import Env from '@/config/env';

export const CellMediaAlbum = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue();

            if (Array.isArray(value)) {
                const files = value.map((valueItem) => {
                    const url = _getURLFromValue(valueItem, valueItem.isExternal);
                    const type = _getTypeFromValue(valueItem.type);

                    return {
                        url,
                        name: valueItem.name,
                        mediaType: type,
                    };
                });

                return <div className="w-64">
                    <ImageVideoViewerSwiper
                        sideLength={256}
                        files={files}
                        mediaType="video"
                        containerClassName="w-64 h-64"
                    />
                </div>;
            }
        },

    };
};

const _getTypeFromValue = (value = '') => {
    const [fileType] = (value?.split?.('/') || []);

    return fileType;
};

const _getURLFromValue = (value, isExternal) => {
    if (!_isObject(value)) return Env.getImageURL(value);

    if (isExternal) return value.key;

    let url = Env.getImageURL(value.key);

    if (isExternal) url = value.key;

    return url;
};
