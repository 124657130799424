import {SBOService} from '@aktek/morph-frontend-sdk';

import {TColumn} from '@/components/Table/Types/Table.t';

type TGetColumns = (sboId: string) => () => Promise<Array<TColumn>>

const getColumns: TGetColumns = (sboId) => async () => {
    const [viewingResponse, headersResponse] = await Promise.all([
        SBOService.getViewingSBOById(sboId),
        // SBOService.getSBOHeaders(sboId),
    ]);
    const viewingData = viewingResponse.getData();
    // const headersData = headersResponse.getData();

    const columns = viewingData.sbo.tableFields
        .map((fieldId) => getColumnFromField(viewingData.sbo.fieldsMap[fieldId]));

    return columns;
};

export default getColumns;

type TGetColumnFromField = (field) => TColumn

const getColumnFromField: TGetColumnFromField = (field) => {
    if (!field) return null;

    return {
        key: field?.id,
        header: field.name,
        type: field.type,
        field,
    };
};
