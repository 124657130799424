export const Sizes = {
    rowHeight: 50,
    checkBoxColumnWidth: 30,
    permissionNameColumnWidth: 170,
};
export const checkBoxStyle = {overflow: 'hidden', flexGrow: 1, flexShrink: 1, flexBasis: `${Sizes.checkBoxColumnWidth}px`};

// Hassan: we need to be able to select texts
// export const noSelectionStyle = {
//     MozUserSelect: 'none',
//     WebkitUserSelect: 'none',
//     msUserSelect: 'none',
//     userSelect: 'none',
// };

export const divStyle = {overflow: 'hidden', flexGrow: 1, flexShrink: 1, flexBasis: Sizes.permissionNameColumnWidth + 'px'};
export const expandDivStyle = {overflow: 'hidden'};
