import cn from 'classnames';
// Original border color: border-neutral-200
const container = (hasBottomBorder?: boolean, className?:string) => cn(
    `flex flex-row items-center `,
    {
        'border-b border-[#EAECF0]': hasBottomBorder,
        'border-none': !hasBottomBorder,
    },
    className,
);

export const GetClasses = (hasBottomBorder?: boolean, className?: string) => ({
    container: container(hasBottomBorder, className),
});
