
import {PermissionsService} from '@aktek/helios-frontend-sdk';
import _ from 'lodash';
import {useEffect, useState} from 'react';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';

import {getSelectedCheckbox} from '../helpers/generateSaveablePermission';
import {storingToF4Form} from '../helpers/storingToF4Form';

const usePermissions = (roleId, ref) => {
    const {extra} = useF4CreatableFormContext();
    const [loading, setLoading] = useState(false);
    const [_permissions, setPermissions] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(extra.initialIsSuperAdmin);
    const [canResetPassword, setCanResetPassword] = useState(false);
    const [updatedPermissionsState, _setUpdatedPermissionsState] = useState({
        key: Date.now(),
        permissions: null,
    });

    useEffect(() => {
        if (_.isEqual(getSelectedCheckbox(_.cloneDeep(_permissions)), getSelectedCheckbox(updatedPermissionsState.permissions))
            && !!isSuperAdmin == !!extra.initialIsSuperAdmin) {
            setCanResetPassword(false);

            return;
        }

        setCanResetPassword(true);
    }, [updatedPermissionsState.permissions, isSuperAdmin, extra.initialIsSuperAdmin]);

    useEffect(() => {
        if (extra.initialIsSuperAdmin) {
            setIsSuperAdmin(true);
        }
    }, [extra.initialIsSuperAdmin]);

    const setUpdatedPermissionsState = (permissions, key = updatedPermissionsState.key) => {
        _setUpdatedPermissionsState({permissions, key});
    };

    const resetPermissions = () => {
        setLoading(true);
        setUpdatedPermissionsState(_.cloneDeep(_permissions), Date.now());
        storingToF4Form(_.cloneDeep(_permissions), ref);
        setIsSuperAdmin(extra.initialIsSuperAdmin);
        ref?.current?.setValue('overridePermissions', extra.initialIsSuperAdmin);
        setLoading(false);
    };

    const fetch = async () => {
        if (!roleId && extra?.state?.mode === 'edit') {
            return;
        }

        setLoading(true);
        const response = await PermissionsService.getRolePermissions(roleId || null);

        if (!response.isSuccessful()) return setLoading(false);

        const permissionsRes = response.getData();

        setPermissions(_.cloneDeep(permissionsRes));
        setUpdatedPermissionsState(permissionsRes, Date.now());
        setLoading(false);
    };

    useEffect(() => {
        fetch();
    }, [roleId]);

    return {
        permissions: _permissions, setPermissions,
        updatedPermissionsState, setUpdatedPermissionsState,
        resetPermissions,
        loading,
        isSuperAdmin, setIsSuperAdmin,
        canResetPassword,
    };
};

export default usePermissions;
