export const generateSaveablePermission = (permission) => {
    const result = [];
    const allACLs = ['can_create', 'can_edit', 'can_view', 'can_delete', 'can_access'];
    allACLs.forEach((acl) => {
        if (permission[acl] == 1) {
            result.push({accessType: acl, permissionId: permission.id});
        }
    });

    if (permission.children) {
        permission.children.forEach((childPermission) => {
            result.push(...generateSaveablePermission(childPermission));
        });
    }

    return result;
};

export const getSelectedCheckbox = (updatedPermissions) => {
    if (updatedPermissions === null) return;
    const result = [];

    Object.keys(updatedPermissions).forEach((section) => {
        const sectionPermissions = updatedPermissions[section];
        sectionPermissions.forEach((permission) => {
            result.push(...generateSaveablePermission(permission));
        });
    });

    return result;
};
