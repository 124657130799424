import {Dropdown, Label} from '@aktek/f4kit';
import {UsageService} from '@aktek/helios-frontend-sdk';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';

import {getMonthsOptions, getYearOptions} from '../helpers/GetMonthsAndYears';
import UsageMetricViewer from './UsageMetricViewer';

function MonthlyUsage({metrics, license}) {
    const [ownMetrics, setOwnMetrics] = useState([]);
    const [date, setDate] = useState({
        month: dayjs().month() + 1,
        year: dayjs().year(),
    });

    const getRollingUsageMetrics = async () =>{
        const res = await UsageService.getOwnRollingUsage(date.month - 1, date.year);

        if (res.isSuccessful()) {
            setOwnMetrics(res.getData());
        }
    };

    useEffect(()=>{
        getRollingUsageMetrics();
    }, [date]);

    return (
        <div className="bg-white mb-4 p-4 border border-neutral-200 rounded-xl">
            <div className="flex justify-between">
                <Label text="Monthly Usage" className="text-2xl" fontWeight="semibold"/>

                <div className="flex gap-4">
                    <Dropdown
                        placeholder="month"
                        value={date.month}
                        options={getMonthsOptions()}
                        onChange={(e) => {
                            setDate({
                                ...date,
                                month: e,
                            });
                        }}/>
                    <Dropdown
                        value={date.year}
                        placeholder="year"
                        onChange={(e)=>{
                            setDate({
                                ...date,
                                year: Number(e),
                            });
                        }}
                        options={getYearOptions()} />
                </div>
            </div>

            <UsageMetricViewer metrics={metrics} license={license} ownMetrics={ownMetrics} showOnlyRolling={true}/>

        </div>
    );
}

export default MonthlyUsage;
