
import {Button, Label, OverflowContainer, Popover} from '@aktek/f4kit';

import {GetActionsClasses} from '../Styles/GetClasses';
import {TActionsNames} from '../Types/ActionsNames.t';
import {TActionRibbon, TRibbonActionProps} from '../Types/Ribbon.t';
import ActionButton from './ActionButton';
import SearchAction from './SearchAction';

const Actions = ({state, title, leftActions = {}, rightActions = {}, onActionClick: onActionClick, searchState}: TRibbonActionProps) => {
    const classes = GetActionsClasses({});

    const renderActionButton = (actionName: string, action: boolean | TActionRibbon, side: 'left' | 'right', index: number) => {
        const name = actionName as TActionsNames;
        const actionProps = typeof action === 'boolean' ? {} : action;

        if (actionName == 'search' && actionProps.searchProps) {
            return <SearchAction
                searchState={searchState}
                state={state}
                name={name}
                side={side}
                actionProps={actionProps}
            />;
        }

        const button = (
            <ActionButton
                key={`${side}-${actionName}-${index}`}
                name={name}
                variant={actionProps.variant}
                icon={actionProps.icon}
                tooltip={actionProps.tooltip}
                isGhost={actionProps.isGhost}
                isOutlined={actionProps.isOutlined}
                options={actionProps.options}
                className={actionProps.className}
                contextMenuItems={actionProps.contextMenuItems}
                onClick={(event) => {
                    onActionClick?.(name, event, state);
                    actionProps.onRibbonActionClick?.(name, event, state);
                }}
            />
        );

        return button;
    };

    return (
        <OverflowContainer
            className={classes.overflow}
            isProtectedFn={() => false}
            items={[
                <Label text={title} key={title} size="xl" color="neutral-900" fontWeight="semibold"/>,
                ...Object.keys(leftActions)
                    .filter((name) =>leftActions[name])
                    .map((actionName, index) => renderActionButton(actionName, leftActions[actionName], 'left', index)),
                <span className={classes.middleSpan} key="right-split-div" />,
                ...Object.keys(rightActions)
                    .filter((name) =>rightActions[name])
                    .map((actionName, index) => renderActionButton(actionName, rightActions[actionName], 'right', index)),
            ]}
            renderOverflow={(view) => (
                <Popover className={classes.popover} content={<div className={classes.overflowContentContainer}>{view}</div>}>
                    <Button className={classes.overflowContentContainer} label="..." />
                </Popover>
            )}
        />
    );
};

export default Actions;
