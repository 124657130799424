import {F4FormDiv, F4Nestor} from '@aktek/f4form';
import {ContentDivider, Label} from '@aktek/f4kit';

import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4ToggleSwitch from '@/components/F4Elements/F4Toggle';
import {CanEditSettings} from '@/constants/Permissions.c';
import {strings} from '@/localization/i18n';

export default function PasswordSettings() {
    return (
        <>
            <F4Nestor name="password">

                <F4FormDiv colSpan={12} className="mt-8">
                    <ContentDivider
                        backgroundColor="neutral-50"
                        middleChild={<Label text={strings('password')}
                            className="text-neutral-600 font-medium" />} />
                </F4FormDiv>

                <F4Textbox
                    name="settings_password_time_validity"
                    colSpan={3}
                    required
                    type="number"
                    allowDecimal={false}
                    min={1}
                    placeholder="30"
                    isDisabled={!CanEditSettings()}
                    rightPlaceholder={strings('days')}
                    label={strings('passowrd_expiration_period')} />

                <F4Textbox
                    name="settings_notify_stale_password"
                    colSpan={3}
                    required
                    type="number"
                    allowDecimal={false}
                    min={1}
                    isDisabled={!CanEditSettings()}
                    validate={(value: number, props, getValue)=> {
                        const max = Number(getValue('password.settings_password_time_validity'));
                        if (value >= max) return {isValid: false, messages: [strings('password_notification_error_message')]};

                        return {isValid: true};
                    }}
                    placeholder="30"
                    rightPlaceholder={strings('days')}
                    label={strings('passowrd_epiration_notification')} />
                <F4FormDiv colSpan={3}></F4FormDiv>

            </F4Nestor>

            <F4Nestor name="password_reset">

                <F4FormDiv colSpan={12} className="mt-8">
                    <ContentDivider
                        backgroundColor="neutral-50"
                        middleChild={<Label text={strings('password_reset')}
                            className="text-neutral-600 font-medium" />} />
                </F4FormDiv>

                <F4ToggleSwitch
                    name="settings_user_reset_pass"
                    colSpan={3}
                    label=""
                    size="md"
                    isDisabled={!CanEditSettings()}
                    descriptionClassName="!text-neutral-700 !font-medium"
                    description={strings('allow_user_to_reset_password')}
                    showOptional={false}
                />

                <F4ToggleSwitch
                    name="settings_admin_reset_user_pass"
                    colSpan={3}
                    label=""
                    size="md"
                    isDisabled={!CanEditSettings()}
                    descriptionClassName="!text-neutral-700 !font-medium"
                    description={strings('allow_admin_to_obtain_reset_links')}
                    showOptional={false}
                />
                <F4FormDiv colSpan={3}></F4FormDiv>
            </F4Nestor>
        </>
    );
}
