
import {LocalStorageKeys} from '@/config/constants';
import {Company} from '@/types/Company';
import {TCompanySettings} from '@/types/CompanySettings';
import {DrawerState} from '@/types/DrawerState';
import {Permissions} from '@/types/Permissions/AppPermissions.t';
import {PrimaryCompany} from '@/types/PrimaryCompany';
import {User} from '@/types/User';

import {clearCloudFrontCookies} from './Cookies';
import LocalStorageHelper from './LocalStorage.helper';

class LS {

    getUserData() {
        return {
            auth_token: this.token,
            currentUser: this.user,
            primaryCompany: this.primaryCompany,
            currentCompany: this.currentCompany,
            userCompanies: this.userCompanies || [],
            companySettings: this.companySettings,
        };
    }

    get theme() {
        return LocalStorageHelper.get(LocalStorageKeys.THEME) as string;
    }

    set theme(value:string) {
        LocalStorageHelper.set(LocalStorageKeys.THEME, value);
    }

    get companyLogo() {
        return LocalStorageHelper.get(LocalStorageKeys.COMPANY_LOGO) as string;
    }

    set companyLogo(value:string) {
        LocalStorageHelper.set(LocalStorageKeys.COMPANY_LOGO, value);
    }

    get token() {
        return LocalStorageHelper.get(LocalStorageKeys.TOKEN) as string;
    }

    set token(value:string) {
        LocalStorageHelper.set(LocalStorageKeys.TOKEN, value);
    }

    get currentCompany() {
        return LocalStorageHelper.get(LocalStorageKeys.CURRENT_COMPANY) as Company;
    }

    set currentCompany(value: Company) {
        LocalStorageHelper.set(LocalStorageKeys.CURRENT_COMPANY, value);
    }

    get companySettings() {
        return LocalStorageHelper.get(LocalStorageKeys.COMPANY_SETTINGS) as TCompanySettings;
    }

    set companySettings(value: TCompanySettings) {
        LocalStorageHelper.set(LocalStorageKeys.COMPANY_SETTINGS, value);
    }

    get userCompanies() {
        return LocalStorageHelper.get(LocalStorageKeys.USER_COMPANIES) as object[];
    }

    set userCompanies(value:object[]) {
        LocalStorageHelper.set(LocalStorageKeys.USER_COMPANIES, value);
    }

    get userPermission() {
        return LocalStorageHelper.get(LocalStorageKeys.USER_PERMISSION) as Permissions;
    }

    set userPermission(value: Permissions) {
        LocalStorageHelper.set(LocalStorageKeys.USER_PERMISSION, value);
    }

    get primaryCompany() {
        return LocalStorageHelper.get(LocalStorageKeys.PRIMARY_COMPANY) as PrimaryCompany;
    }

    set primaryCompany(value:PrimaryCompany) {
        LocalStorageHelper.set(LocalStorageKeys.PRIMARY_COMPANY, value);
    }

    get user() {
        return LocalStorageHelper.get(LocalStorageKeys.CURRENT_USER) as User;
    }

    set user(user: User) {
        LocalStorageHelper.set(LocalStorageKeys.CURRENT_USER, user);
    }

    get isLoggedIn() {
        // @TODO: perhaps we need to adjust this
        return !!this.token;
    }

    get tabsSchema() {
        return LocalStorageHelper.get(LocalStorageKeys.TAB_SCHEMA);
    }

    set tabsSchema(tabsSchema) {
        LocalStorageHelper.set(LocalStorageKeys.TAB_SCHEMA, tabsSchema);
    }

    get permissionSection() {
        return LocalStorageHelper.get(LocalStorageKeys.PERMISSION_SECTION) || null;
    }

    set permissionSection(value) {
        LocalStorageHelper.set(LocalStorageKeys.PERMISSION_SECTION, value);
    }

    get foldersAndData() {
        return LocalStorageHelper.get(LocalStorageKeys.FOLDERS_DATA) || false;
    }

    set foldersAndData(value) {
        LocalStorageHelper.set(LocalStorageKeys.FOLDERS_DATA, value);
    }

    get shortcuts() {
        return LocalStorageHelper.get(LocalStorageKeys.SHORTCUTS) || false;
    }

    set shortcuts(value) {
        LocalStorageHelper.set(LocalStorageKeys.SHORTCUTS, value);
    }

    get repositories() {
        return LocalStorageHelper.get(LocalStorageKeys.REPOSITORIES) || false;
    }

    set repositories(value) {
        LocalStorageHelper.set(LocalStorageKeys.REPOSITORIES, value);
    }

    get drawer() {
        return LocalStorageHelper.get(LocalStorageKeys.DRAWER) as DrawerState;
    }

    set drawer(value) {
        LocalStorageHelper.set(LocalStorageKeys.DRAWER, value);
    }

    signIn(data) {
        this.token = data.auth_token;
        this.user = data.currentUser;
        this.primaryCompany = data.primaryCompany;
        this.currentCompany = data.currentCompany;
        this.userCompanies = data.userCompanies || [];
        this.companySettings = data.companySettings;
        this.userPermission = null;
    }

    signOut() {
        const keysToClear = [
            LocalStorageKeys.TOKEN,
            LocalStorageKeys.TAB_SCHEMA,
            LocalStorageKeys.TABS_PROPS,
            LocalStorageKeys.GROUP_BACKGROUND,
            LocalStorageKeys.LANGUAGE,
            LocalStorageKeys.CURRENT_USER,
            LocalStorageKeys.PRIMARY_COMPANY,
            LocalStorageKeys.CURRENT_COMPANY,
            LocalStorageKeys.COMPANY_SETTINGS,
            LocalStorageKeys.USER_COMPANIES,
            LocalStorageKeys.USER_PERMISSION,

        ];
        clearCloudFrontCookies();

        keysToClear.forEach((key) => LocalStorageHelper.remove(key));
    }

}

const ls = new LS();

export type lsType = typeof ls;

export default ls;
