import {toast} from '@aktek/f4kit';
import {VerificationService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {useSignUserOut} from '@/context/UserContext/Hooks/useSignUserOut';
import {strings} from '@/localization/i18n';
import {useNavigateToLogin} from '@/routing/navigations';

import {useSetPasswordValidation} from '../../Login/hooks/useAuthValidation';

export default function useSetPassword() {
    const navigateToLogin = useNavigateToLogin();
    const signOut = useSignUserOut();

    const [newPassword, setNewPassword]= useState({
        password: '',
        passwordTouched: false,
        confirmPassword: '',
        confirmPasswordTouched: false,
    });

    const [searchParams] = useSearchParams();
    const verificationToken = searchParams.get('verificationToken');

    const [loading, setLoading] = useState(false);

    const [forgetPassword, setForgetPassword]=useState({userName: '', minStrength: ''});
    const {validationResult} = useSetPasswordValidation(newPassword);

    const onValidateTokenSuccess =(userName, minStrength)=> {
        setForgetPassword({userName, minStrength});
    };

    useEffect(()=>{
        getVerificationToken(onValidateTokenSuccess);
    }, [searchParams]);

    const getVerificationToken = async (onValidateTokenSuccess )=>{
        if (!verificationToken) {
            navigateToLogin(true);

            return;
        }

        setLoading(true);
        const res = await VerificationService.verify(verificationToken);

        if (!res.isSuccessful?.()) {
            setLoading(false);

            return;
        }

        const {isValid, userName, minStrength} = res.getData();

        setLoading(false);

        if (isValid) {
            onValidateTokenSuccess(userName, minStrength);

            return;
        }

        navigateToLogin(true);
        toast.error(strings('invalid_token'));
    };

    const handlePasswordChange = (password)=> {
        setNewPassword({...newPassword, password: password.trim()});
    };

    const handleConfirmPasswordChange = (confirmPassword)=> {
        setNewPassword({...newPassword, confirmPassword: confirmPassword.trim()});
    };

    const handleKeyDown = (event, verificationToken, newPassword) => {
        if (event.key === 'Enter') {
            handleSetPasswordRequest(verificationToken, newPassword);
        }
    };

    const handleSetPasswordRequest = async (verificationToken, newPassword)=> {
        if (!validationResult.isValid) return;

        const requestSetPasswordResponse
        = await VerificationService.setPassword(verificationToken, newPassword.password);

        if (requestSetPasswordResponse.isSuccessful?.()) {
            toast.success?.(strings('reset_password_done'));
            signOut();
            navigateToLogin(true);
        } else {
            toast.error?.(strings('request_reset_fail'));
        }
    };

    return {newPassword, setNewPassword, handlePasswordChange, handleConfirmPasswordChange, verificationToken,
        validationResult, handleSetPasswordRequest, handleKeyDown, loading, forgetPasswordSuccessState: forgetPassword};
}
