export type TProps = {
    children: React.ReactNode | React.ReactNode[],
}

const NavigationPageContainer = ({children}: TProps): React.ReactElement => {
    return <div className="flex flex-col items-start min-h-full h-fit bg-neutral-50 py-6 px-4">
        {children}
    </div>;
};

export default NavigationPageContainer;
