import React from 'react';

import {TUseSearch} from '../Types/Table.t';

const useSearch: TUseSearch = () => {
    const [query, setQuery] = React.useState<string>('');
    const reset = () => setQuery('');

    return {
        query,
        reset,
        setQuery,
    };
};

export default useSearch;
