import NavigationButton from '@/components/Navigation/NavigationButton';
import NavigationPageContainer from '@/components/Navigation/NavigationPageContainer';
import NavigationSection from '@/components/Navigation/NavigationSection';
import {AdminNavigation} from '@/constants/AdminNavigation.c';

function Admin() {
    const adminSections = AdminNavigation();

    return (
        <NavigationPageContainer>
            {adminSections.map((section) => (section.isVisible !== false) && (
                <NavigationSection label={section.sectionTitle} key={section.sectionTitle}>
                    {section.children.map((tab, index) => (
                        <NavigationButton
                            key={tab.tabKey + index + section.sectionTitle}
                            destinationKey={tab.tabKey}
                            icon={tab.icon}
                            badges={tab.badges}
                            variant={tab.variant}
                            name={tab.name}
                            isVisible={tab.isVisible}
                        />
                    ))}
                </NavigationSection>
            ))}
        </NavigationPageContainer>
    );
}

export default Admin;
