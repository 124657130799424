import {Icon, TtabsManager} from '@aktek/f4kit';
import {faGears, faTurnRight} from '@fortawesome/pro-regular-svg-icons';
import {faBarChart, faDashboard, faDatabase, faDiagramProject, faDisplayChartUp, faGear,
    faHome, faLock, faNetworkWired, faSatellite, faTable, faUser} from '@fortawesome/pro-regular-svg-icons';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import Explorer from '@/app//Explorer/Explorer';
import AKTEKiQ from '@/app//iQ/iQ';
import Users from '@/app/Admin/AccessControl/pages/Users/Users';
import Admin from '@/app/Admin/Admin';
import Agency from '@/app/Admin/Agency/Agency';
import CompanySettings from '@/app/Admin/CompanySettings/CompanySettings';
import Data from '@/app/Admin/Data/Data';
import UsageMetrics from '@/app/Admin/UsageMetrics/UsageMetrics';
import Analysis from '@/app/Analysis/Analysis';
import Dashboards from '@/app/Analysis/pages/Dashboards';
import Networks from '@/app/Analysis/pages/Networks';
import DataTableView from '@/app/DataTable/DataTable.View';
import {strings} from '@/localization/i18n';

import AccessControl from '../Admin/AccessControl/AccessControl';
import Advanced from '../Admin/Advanced/Advanced';
import DataStructureEditor from '../Admin/Data/pages/DataStructure/components/DataStructureEditor';
import GeoRepository from '../Admin/GeoRepository/GeoRepository';
import iOJobs from '../Admin/iOJobs/iOJobs';
import Licensing from '../Admin/Licensing/Licensing';

export const TabsManager: TtabsManager = {};

export enum ETabs {analysis, explorer, admin, dashboards, networks, AKTEKiQ, dataStructure, dataStructureEditor,
     users, companySettings, accessControl, usageMetrics, agency, licensing, iOJobs, advanced, geoRepository, dataTable}

const Tabs: Record<keyof typeof ETabs, {component: unknown, title: string, icon: string | IconDefinition}> = {
    explorer: {
        component: Explorer,
        title: 'Explorer',
        icon: faHome,
    },
    admin: {
        component: Admin,
        title: 'Admin',
        icon: faUser,
    },
    accessControl: {
        component: AccessControl,
        title: 'Access Control',
        icon: faLock,
    },
    dataStructure: {
        component: Data,
        title: 'Data',
        icon: faDatabase,
    },
    analysis: {
        component: Analysis,
        title: 'Analysis',
        icon: faDisplayChartUp,
    },
    AKTEKiQ: {
        component: AKTEKiQ,
        title: 'AKTEK iQ',
        icon: faSatellite,
    },
    users: {
        component: Users,
        title: 'Users',
        icon: faUser,
    },
    companySettings: {
        component: CompanySettings,
        title: 'Company Settings',
        icon: faGear,
    },
    usageMetrics: {
        component: UsageMetrics,
        title: 'Usage Metrics',
        icon: faBarChart,
    },
    agency: {
        component: Agency,
        title: 'Agency',
        icon: faGears,
    },
    licensing: {
        component: Licensing,
        title: 'Licensing',
        icon: faGears,
    },
    dashboards: {
        component: Dashboards,
        title: 'dashboards',
        icon: faDashboard,
    },
    networks: {
        component: Networks,
        title: 'networks',
        icon: faNetworkWired,
    },
    iOJobs: {
        component: iOJobs,
        title: 'iOJobs',
        icon: faNetworkWired,
    },
    advanced: {
        component: Advanced,
        title: 'Advanced Sections',
        icon: faGears,
    },
    geoRepository: {
        component: GeoRepository,
        title: 'Geo Repository',
        icon: faDiagramProject,
    },
    dataStructureEditor: {
        component: DataStructureEditor,
        title: 'Data Structure Editor',
        icon: faTable,
    },
    dataTable: {
        component: DataTableView,
        title: 'Data Table',
        icon: faTable,
    },
};

type TopenTab = (tab: keyof typeof ETabs, title?: string, props?: object) => void

export const openTab : TopenTab = (type, title = type, props) => TabsManager.openNewTab?.(type, title, props);
export const focusOrOpenNewTab : TopenTab = (type, title = type, props) => TabsManager.focusOrOpenNewTab?.(type, title, props);

const getComponent= ({component, config = {}}) => {
    const {props = {}} = config;
    const Component = Tabs[component]?.component;
    if (!Component) return null;

    // return <Component {...props} />;
    return {
        Component,
        props,
    };
};

const getDropDownOptions = ({name}) => {
    const icon = {icon: faUser};

    return [
        {label: strings('create_a_new_shortcut'), option: 'Icon', icon: {icon: faTurnRight}},
    ];
};

const getTitle= ({name, title}) => {
    return title || Tabs?.[name]?.title || name || '';
};

const getIcon= ({name}) => {
    // return Tabs?.[name]?.icon || '';
    return <Icon icon={Tabs?.[name]?.icon} />;
};

const avatar ={
    alt: 'User Avatar',
    src: 'https://www.aktek.io/hubfs/Brand%20Assets/logo%20AKTEK%20black.png',
};

export const TabFactory = {getComponent, getTitle, getIcon, getDropDownOptions};

