
import {Drawer} from '@aktek/f4kit';
import {useEffect} from 'react';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import ExplorerSideBar from './components/ExplorerSideBar';
import RecentlyUsed from './components/RecentlyUsed';
import {getClasses} from './styles/Explorer.styles';

export default function Explorer({}): React.ReactElement {
    // const {folders: items, refresh, isLoading, deleteFolder, recentlyUsed} = useFilesAndFoldersState();
    const {folders: items, refresh, isLoading, deleteFolder, recentlyUsed} = useGlobalFilesAndFolders();

    useEffect(() => {
        // refresh();
    }, []);

    const classes = getClasses();

    return <div className={classes.full}>
        <Drawer
            defaultOpen={ls.drawer?.explorer ?? true}
            onOpenChange={(e) => openCloseDrawer('explorer', !!e)}
            sideWidth={96}
            tooltipDelay={1000}
            buttonSize="sm"
            className="md:!w-1/3 sm:!w-2/5 !w-11/12"
        >
            <ExplorerSideBar refresh={refresh} isLoading={isLoading} items={items} deleteFolder={deleteFolder} />
            <RecentlyUsed refresh={refresh} isLoading={isLoading} items={items} recentlyUsed={recentlyUsed} />
        </Drawer>
    </div>;
}
