import {useEffect, useState} from 'react';
import {object, string} from 'yup';

import {strings} from '@/localization/i18n';

import {TForgetPasswordValidationResult} from '../../Login/types/loginTypes.t';

export const ForgetPasswordSchema = object().shape({
    email: string().email(strings('invalid_email')).required(strings('email_required')).test(
        'is-valid-email',
        strings('invalid_email'),
        (value) => {
            if (!value) return true;
            const atIndex = value.indexOf('@');
            const dotIndex = value.lastIndexOf('.');

            return atIndex !== -1 && dotIndex !== -1 && dotIndex > atIndex && dotIndex < value.length - 2;
        },
    ),
});

export const useForgetPasswordValidation = (email)=>{
    const errors = {email: ''};
    const [validationResult, setValidationResult] = useState<TForgetPasswordValidationResult>({isValid: false, errors});

    useEffect(() => {
        const validateData = async () => {
            const isValid = await ForgetPasswordSchema.isValid(email);

            try {
                await ForgetPasswordSchema.validate(email, {abortEarly: false});

                setValidationResult({isValid, errors});
            } catch (error) {
                error.inner.forEach((err) => errors[err.path]= err.message );
                setValidationResult({isValid, errors});
            }
        };

        validateData();
    }, [email]);

    return {validationResult};
};
