import {Drawer, FitHeight, TabNav, useTab} from '@aktek/f4kit';
import {useEffect} from 'react';

import F4Sidebar from '@/components/F4Sidebar/F4Sidebar';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import {GetDataStructureTabs} from './constants/data.c';
import DataStructure from './pages/DataStructure/DataStructure.table';

export default function Data(props) {
    useDocumentTitle(strings('data_structure'));
    const tab = useTab();

    useEffect(() => {
        props.selectedTab && tab.setState({selectedTab: props.selectedTab});
    }, [tab.config?.tabSelectedAt]);

    const dataStructureTabs = GetDataStructureTabs();
    const firstVisibleTabKey = dataStructureTabs.find((tab) => tab.isVisible)?.key;
    const selectedTab = tab.state?.selectedTab || firstVisibleTabKey;

    return (
        <div className="flex  bg-neutral-50 h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={ls.drawer?.dataStructure ?? true}
                onOpenChange={(e) => openCloseDrawer('dataStructure', !!e)}
                buttonSize="sm"
                className="sm:!w-2/4 md:!w-1/4  !w-11/12"
            >

                <F4Sidebar>
                    <TabNav
                        orientation="vertical"
                        tabs={dataStructureTabs}
                        value={selectedTab}
                        defaultValue={selectedTab || 'dataStructure'}
                        onChange={(selectedTab) => tab.setState({selectedTab})}
                        className="overflow-x-visible"
                        buttonClassName="[&>:first-child]:[&>:first-child]:[&>:first-child]:!w-8"
                    />
                </F4Sidebar>

                <FitHeight className="h-full w-full p-4">
                    {(!selectedTab || selectedTab === 'dataStructure') && <DataStructure />}

                    {selectedTab === 'conditional_coloring' && <DataStructure />}

                    {selectedTab === 'translations' && <DataStructure />}
                </FitHeight>
            </Drawer>
        </div>
    );
}

